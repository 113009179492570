@charset "UTF-8";

@font-face {
  font-family: 'Simple Icons';
  src: url(./fonts/SimpleIcons.woff) format('woff'),
    url(./fonts/SimpleIcons.woff2) format('woff2'),
    url(./fonts/SimpleIcons.ttf) format('truetype'),
    url(./fonts/SimpleIcons.otf) format('opentype'),
    url(./fonts/SimpleIcons.eot) format('embedded-opentype');
}

.si {
  font-style: normal;
  font-family: 'Simple Icons', sans-serif;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  text-align:center;
  bottom: 8px;
  position: relative;
}

.si-42::before { content: "\ea01"; }
.si-42.si--color::before { color: #000000; }
.si-dotnet::before { content: "\ea02"; }
.si-dotnet.si--color::before { color: #512BD4; }
.si-e::before { content: "\ea03"; }
.si-e.si--color::before { color: #000000; }
.si-1001tracklists::before { content: "\ea04"; }
.si-1001tracklists.si--color::before { color: #40AEF0; }
.si-1password::before { content: "\ea05"; }
.si-1password.si--color::before { color: #0094F5; }
.si-3m::before { content: "\ea06"; }
.si-3m.si--color::before { color: #FF0000; }
.si-4chan::before { content: "\ea07"; }
.si-4chan.si--color::before { color: #006600; }
.si-4d::before { content: "\ea08"; }
.si-4d.si--color::before { color: #004088; }
.si-500px::before { content: "\ea09"; }
.si-500px.si--color::before { color: #0099E5; }
.si-aframe::before { content: "\ea0a"; }
.si-aframe.si--color::before { color: #EF2D5E; }
.si-abbrobotstudio::before { content: "\ea0b"; }
.si-abbrobotstudio.si--color::before { color: #FF9E0F; }
.si-abbott::before { content: "\ea0c"; }
.si-abbott.si--color::before { color: #008FC7; }
.si-abbvie::before { content: "\ea0d"; }
.si-abbvie.si--color::before { color: #071D49; }
.si-abletonlive::before { content: "\ea0e"; }
.si-abletonlive.si--color::before { color: #000000; }
.si-aboutdotme::before { content: "\ea0f"; }
.si-aboutdotme.si--color::before { color: #00A98F; }
.si-abstract::before { content: "\ea10"; }
.si-abstract.si--color::before { color: #191A1B; }
.si-academia::before { content: "\ea11"; }
.si-academia.si--color::before { color: #41454A; }
.si-accenture::before { content: "\ea12"; }
.si-accenture.si--color::before { color: #A100FF; }
.si-acclaim::before { content: "\ea13"; }
.si-acclaim.si--color::before { color: #26689A; }
.si-accusoft::before { content: "\ea14"; }
.si-accusoft.si--color::before { color: #A9225C; }
.si-acer::before { content: "\ea15"; }
.si-acer.si--color::before { color: #83B81A; }
.si-acm::before { content: "\ea16"; }
.si-acm.si--color::before { color: #0085CA; }
.si-actigraph::before { content: "\ea17"; }
.si-actigraph.si--color::before { color: #0B2C4A; }
.si-activision::before { content: "\ea18"; }
.si-activision.si--color::before { color: #000000; }
.si-adafruit::before { content: "\ea19"; }
.si-adafruit.si--color::before { color: #000000; }
.si-adblock::before { content: "\ea1a"; }
.si-adblock.si--color::before { color: #F40D12; }
.si-adblockplus::before { content: "\ea1b"; }
.si-adblockplus.si--color::before { color: #C70D2C; }
.si-addthis::before { content: "\ea1c"; }
.si-addthis.si--color::before { color: #FF6550; }
.si-adguard::before { content: "\ea1d"; }
.si-adguard.si--color::before { color: #68BC71; }
.si-adidas::before { content: "\ea1e"; }
.si-adidas.si--color::before { color: #000000; }
.si-adobe::before { content: "\ea1f"; }
.si-adobe.si--color::before { color: #FF0000; }
.si-adobeacrobatreader::before { content: "\ea20"; }
.si-adobeacrobatreader.si--color::before { color: #EC1C24; }
.si-adobeaftereffects::before { content: "\ea21"; }
.si-adobeaftereffects.si--color::before { color: #9999FF; }
.si-adobeaudition::before { content: "\ea22"; }
.si-adobeaudition.si--color::before { color: #9999FF; }
.si-adobecreativecloud::before { content: "\ea23"; }
.si-adobecreativecloud.si--color::before { color: #DA1F26; }
.si-adobedreamweaver::before { content: "\ea24"; }
.si-adobedreamweaver.si--color::before { color: #FF61F6; }
.si-adobefonts::before { content: "\ea25"; }
.si-adobefonts.si--color::before { color: #000B1D; }
.si-adobeillustrator::before { content: "\ea26"; }
.si-adobeillustrator.si--color::before { color: #FF9A00; }
.si-adobeindesign::before { content: "\ea27"; }
.si-adobeindesign.si--color::before { color: #FF3366; }
.si-adobelightroom::before { content: "\ea28"; }
.si-adobelightroom.si--color::before { color: #31A8FF; }
.si-adobelightroomclassic::before { content: "\ea29"; }
.si-adobelightroomclassic.si--color::before { color: #31A8FF; }
.si-adobephotoshop::before { content: "\ea2a"; }
.si-adobephotoshop.si--color::before { color: #31A8FF; }
.si-adobepremierepro::before { content: "\ea2b"; }
.si-adobepremierepro.si--color::before { color: #9999FF; }
.si-adobexd::before { content: "\ea2c"; }
.si-adobexd.si--color::before { color: #FF61F6; }
.si-adonisjs::before { content: "\ea2d"; }
.si-adonisjs.si--color::before { color: #220052; }
.si-adyen::before { content: "\ea2e"; }
.si-adyen.si--color::before { color: #0ABF53; }
.si-aerlingus::before { content: "\ea2f"; }
.si-aerlingus.si--color::before { color: #006272; }
.si-aeroflot::before { content: "\ea30"; }
.si-aeroflot.si--color::before { color: #02458D; }
.si-aeromexico::before { content: "\ea31"; }
.si-aeromexico.si--color::before { color: #0B2343; }
.si-aerospike::before { content: "\ea32"; }
.si-aerospike.si--color::before { color: #C41E25; }
.si-aew::before { content: "\ea33"; }
.si-aew.si--color::before { color: #000000; }
.si-affinity::before { content: "\ea34"; }
.si-affinity.si--color::before { color: #222324; }
.si-affinitydesigner::before { content: "\ea35"; }
.si-affinitydesigner.si--color::before { color: #1B72BE; }
.si-affinityphoto::before { content: "\ea36"; }
.si-affinityphoto.si--color::before { color: #7E4DD2; }
.si-affinitypublisher::before { content: "\ea37"; }
.si-affinitypublisher.si--color::before { color: #C9284D; }
.si-aidungeon::before { content: "\ea38"; }
.si-aidungeon.si--color::before { color: #000000; }
.si-aiohttp::before { content: "\ea39"; }
.si-aiohttp.si--color::before { color: #2C5BB4; }
.si-aiqfome::before { content: "\ea3a"; }
.si-aiqfome.si--color::before { color: #7A1FA2; }
.si-aircanada::before { content: "\ea3b"; }
.si-aircanada.si--color::before { color: #F01428; }
.si-airchina::before { content: "\ea3c"; }
.si-airchina.si--color::before { color: #E30E17; }
.si-airfrance::before { content: "\ea3d"; }
.si-airfrance.si--color::before { color: #002157; }
.si-airasia::before { content: "\ea3e"; }
.si-airasia.si--color::before { color: #FF0000; }
.si-airbnb::before { content: "\ea3f"; }
.si-airbnb.si--color::before { color: #FF5A5F; }
.si-airbus::before { content: "\ea40"; }
.si-airbus.si--color::before { color: #00205B; }
.si-aircall::before { content: "\ea41"; }
.si-aircall.si--color::before { color: #00B388; }
.si-airplayaudio::before { content: "\ea42"; }
.si-airplayaudio.si--color::before { color: #000000; }
.si-airplayvideo::before { content: "\ea43"; }
.si-airplayvideo.si--color::before { color: #000000; }
.si-airtable::before { content: "\ea44"; }
.si-airtable.si--color::before { color: #18BFFF; }
.si-alacritty::before { content: "\ea45"; }
.si-alacritty.si--color::before { color: #F46D01; }
.si-aldinord::before { content: "\ea46"; }
.si-aldinord.si--color::before { color: #2490D7; }
.si-aldisud::before { content: "\ea47"; }
.si-aldisud.si--color::before { color: #00005F; }
.si-alfaromeo::before { content: "\ea48"; }
.si-alfaromeo.si--color::before { color: #981E32; }
.si-alfred::before { content: "\ea49"; }
.si-alfred.si--color::before { color: #5C1F87; }
.si-algolia::before { content: "\ea4a"; }
.si-algolia.si--color::before { color: #5468FF; }
.si-alibabacloud::before { content: "\ea4b"; }
.si-alibabacloud.si--color::before { color: #FF6A00; }
.si-alibabadotcom::before { content: "\ea4c"; }
.si-alibabadotcom.si--color::before { color: #FF6A00; }
.si-aliexpress::before { content: "\ea4d"; }
.si-aliexpress.si--color::before { color: #FF4747; }
.si-alipay::before { content: "\ea4e"; }
.si-alipay.si--color::before { color: #00A1E9; }
.si-alitalia::before { content: "\ea4f"; }
.si-alitalia.si--color::before { color: #006643; }
.si-allegro::before { content: "\ea50"; }
.si-allegro.si--color::before { color: #FF5A00; }
.si-alliedmodders::before { content: "\ea51"; }
.si-alliedmodders.si--color::before { color: #1578D3; }
.si-allocine::before { content: "\ea52"; }
.si-allocine.si--color::before { color: #FECC00; }
.si-alltrails::before { content: "\ea53"; }
.si-alltrails.si--color::before { color: #428813; }
.si-alpinelinux::before { content: "\ea54"; }
.si-alpinelinux.si--color::before { color: #0D597F; }
.si-alpinedotjs::before { content: "\ea55"; }
.si-alpinedotjs.si--color::before { color: #8BC0D0; }
.si-altiumdesigner::before { content: "\ea56"; }
.si-altiumdesigner.si--color::before { color: #A5915F; }
.si-amazon::before { content: "\ea57"; }
.si-amazon.si--color::before { color: #FF9900; }
.si-amazonalexa::before { content: "\ea58"; }
.si-amazonalexa.si--color::before { color: #00CAFF; }
.si-amazonaws::before { content: "\ea59"; }
.si-amazonaws.si--color::before { color: #232F3E; }
.si-amazondynamodb::before { content: "\ea5a"; }
.si-amazondynamodb.si--color::before { color: #4053D6; }
.si-amazonfiretv::before { content: "\ea5b"; }
.si-amazonfiretv.si--color::before { color: #FC4C02; }
.si-amazonlumberyard::before { content: "\ea5c"; }
.si-amazonlumberyard.si--color::before { color: #66459B; }
.si-amazonpay::before { content: "\ea5d"; }
.si-amazonpay.si--color::before { color: #FF9900; }
.si-amazonprime::before { content: "\ea5e"; }
.si-amazonprime.si--color::before { color: #00A8E1; }
.si-amazons3::before { content: "\ea5f"; }
.si-amazons3.si--color::before { color: #569A31; }
.si-amd::before { content: "\ea60"; }
.si-amd.si--color::before { color: #ED1C24; }
.si-americanairlines::before { content: "\ea61"; }
.si-americanairlines.si--color::before { color: #0078D2; }
.si-americanexpress::before { content: "\ea62"; }
.si-americanexpress.si--color::before { color: #2E77BC; }
.si-amg::before { content: "\ea63"; }
.si-amg.si--color::before { color: #000000; }
.si-amp::before { content: "\ea64"; }
.si-amp.si--color::before { color: #005AF0; }
.si-amul::before { content: "\ea65"; }
.si-amul.si--color::before { color: #ED1D24; }
.si-ana::before { content: "\ea66"; }
.si-ana.si--color::before { color: #13448F; }
.si-anaconda::before { content: "\ea67"; }
.si-anaconda.si--color::before { color: #44A833; }
.si-analogue::before { content: "\ea68"; }
.si-analogue.si--color::before { color: #1A1A1A; }
.si-anchor::before { content: "\ea69"; }
.si-anchor.si--color::before { color: #5000B9; }
.si-andela::before { content: "\ea6a"; }
.si-andela.si--color::before { color: #3359DF; }
.si-android::before { content: "\ea6b"; }
.si-android.si--color::before { color: #3DDC84; }
.si-androidauto::before { content: "\ea6c"; }
.si-androidauto.si--color::before { color: #3DDC84; }
.si-androidstudio::before { content: "\ea6d"; }
.si-androidstudio.si--color::before { color: #3DDC84; }
.si-angellist::before { content: "\ea6e"; }
.si-angellist.si--color::before { color: #000000; }
.si-angular::before { content: "\ea6f"; }
.si-angular.si--color::before { color: #DD0031; }
.si-angularuniversal::before { content: "\ea70"; }
.si-angularuniversal.si--color::before { color: #00ACC1; }
.si-angularjs::before { content: "\ea71"; }
.si-angularjs.si--color::before { color: #E23237; }
.si-anilist::before { content: "\ea72"; }
.si-anilist.si--color::before { color: #02A9FF; }
.si-ansible::before { content: "\ea73"; }
.si-ansible.si--color::before { color: #EE0000; }
.si-ansys::before { content: "\ea74"; }
.si-ansys.si--color::before { color: #FFB71B; }
.si-antdesign::before { content: "\ea75"; }
.si-antdesign.si--color::before { color: #0170FE; }
.si-anta::before { content: "\ea76"; }
.si-anta.si--color::before { color: #D70010; }
.si-antena3::before { content: "\ea77"; }
.si-antena3.si--color::before { color: #FF7328; }
.si-anydesk::before { content: "\ea78"; }
.si-anydesk.si--color::before { color: #EF443B; }
.si-aol::before { content: "\ea79"; }
.si-aol.si--color::before { color: #3399FF; }
.si-apache::before { content: "\ea7a"; }
.si-apache.si--color::before { color: #D22128; }
.si-apacheairflow::before { content: "\ea7b"; }
.si-apacheairflow.si--color::before { color: #017CEE; }
.si-apacheant::before { content: "\ea7c"; }
.si-apacheant.si--color::before { color: #A81C7D; }
.si-apachecassandra::before { content: "\ea7d"; }
.si-apachecassandra.si--color::before { color: #1287B1; }
.si-apachecloudstack::before { content: "\ea7e"; }
.si-apachecloudstack.si--color::before { color: #2AA5DC; }
.si-apachecordova::before { content: "\ea7f"; }
.si-apachecordova.si--color::before { color: #E8E8E8; }
.si-apachedruid::before { content: "\ea80"; }
.si-apachedruid.si--color::before { color: #29F1FB; }
.si-apacheecharts::before { content: "\ea81"; }
.si-apacheecharts.si--color::before { color: #AA344D; }
.si-apacheflink::before { content: "\ea82"; }
.si-apacheflink.si--color::before { color: #E6526F; }
.si-apachegroovy::before { content: "\ea83"; }
.si-apachegroovy.si--color::before { color: #4298B8; }
.si-apachehive::before { content: "\ea84"; }
.si-apachehive.si--color::before { color: #FDEE21; }
.si-apachejmeter::before { content: "\ea85"; }
.si-apachejmeter.si--color::before { color: #D22128; }
.si-apachekafka::before { content: "\ea86"; }
.si-apachekafka.si--color::before { color: #231F20; }
.si-apachekylin::before { content: "\ea87"; }
.si-apachekylin.si--color::before { color: #F09D13; }
.si-apachemaven::before { content: "\ea88"; }
.si-apachemaven.si--color::before { color: #C71A36; }
.si-apachenetbeanside::before { content: "\ea89"; }
.si-apachenetbeanside.si--color::before { color: #1B6AC6; }
.si-apacheopenoffice::before { content: "\ea8a"; }
.si-apacheopenoffice.si--color::before { color: #0E85CD; }
.si-apachepulsar::before { content: "\ea8b"; }
.si-apachepulsar.si--color::before { color: #188FFF; }
.si-apacherocketmq::before { content: "\ea8c"; }
.si-apacherocketmq.si--color::before { color: #D77310; }
.si-apachesolr::before { content: "\ea8d"; }
.si-apachesolr.si--color::before { color: #D9411E; }
.si-apachespark::before { content: "\ea8e"; }
.si-apachespark.si--color::before { color: #E25A1C; }
.si-apachetomcat::before { content: "\ea8f"; }
.si-apachetomcat.si--color::before { color: #F8DC75; }
.si-aparat::before { content: "\ea90"; }
.si-aparat.si--color::before { color: #ED145B; }
.si-apollographql::before { content: "\ea91"; }
.si-apollographql.si--color::before { color: #311C87; }
.si-apostrophe::before { content: "\ea92"; }
.si-apostrophe.si--color::before { color: #6236FF; }
.si-appannie::before { content: "\ea93"; }
.si-appannie.si--color::before { color: #0F2346; }
.si-appstore::before { content: "\ea94"; }
.si-appstore.si--color::before { color: #0D96F6; }
.si-appian::before { content: "\ea95"; }
.si-appian.si--color::before { color: #2322F0; }
.si-apple::before { content: "\ea96"; }
.si-apple.si--color::before { color: #000000; }
.si-applearcade::before { content: "\ea97"; }
.si-applearcade.si--color::before { color: #000000; }
.si-applemusic::before { content: "\ea98"; }
.si-applemusic.si--color::before { color: #FA243C; }
.si-applepay::before { content: "\ea99"; }
.si-applepay.si--color::before { color: #000000; }
.si-applepodcasts::before { content: "\ea9a"; }
.si-applepodcasts.si--color::before { color: #9933CC; }
.si-appletv::before { content: "\ea9b"; }
.si-appletv.si--color::before { color: #000000; }
.si-appsignal::before { content: "\ea9c"; }
.si-appsignal.si--color::before { color: #21375A; }
.si-appveyor::before { content: "\ea9d"; }
.si-appveyor.si--color::before { color: #00B3E0; }
.si-appwrite::before { content: "\ea9e"; }
.si-appwrite.si--color::before { color: #F02E65; }
.si-aqua::before { content: "\ea9f"; }
.si-aqua.si--color::before { color: #1904DA; }
.si-aral::before { content: "\eaa0"; }
.si-aral.si--color::before { color: #0063CB; }
.si-arangodb::before { content: "\eaa1"; }
.si-arangodb.si--color::before { color: #DDE072; }
.si-archlinux::before { content: "\eaa2"; }
.si-archlinux.si--color::before { color: #1793D1; }
.si-archicad::before { content: "\eaa3"; }
.si-archicad.si--color::before { color: #2D50A5; }
.si-archiveofourown::before { content: "\eaa4"; }
.si-archiveofourown.si--color::before { color: #990000; }
.si-ardour::before { content: "\eaa5"; }
.si-ardour.si--color::before { color: #C61C3E; }
.si-arduino::before { content: "\eaa6"; }
.si-arduino.si--color::before { color: #00979D; }
.si-arkecosystem::before { content: "\eaa7"; }
.si-arkecosystem.si--color::before { color: #C9292C; }
.si-arlo::before { content: "\eaa8"; }
.si-arlo.si--color::before { color: #49B48A; }
.si-arm::before { content: "\eaa9"; }
.si-arm.si--color::before { color: #0091BD; }
.si-artifacthub::before { content: "\eaaa"; }
.si-artifacthub.si--color::before { color: #417598; }
.si-artixlinux::before { content: "\eaab"; }
.si-artixlinux.si--color::before { color: #10A0CC; }
.si-artstation::before { content: "\eaac"; }
.si-artstation.si--color::before { color: #13AFF0; }
.si-arxiv::before { content: "\eaad"; }
.si-arxiv.si--color::before { color: #B31B1B; }
.si-asana::before { content: "\eaae"; }
.si-asana.si--color::before { color: #273347; }
.si-asciidoctor::before { content: "\eaaf"; }
.si-asciidoctor.si--color::before { color: #E40046; }
.si-asciinema::before { content: "\eab0"; }
.si-asciinema.si--color::before { color: #D40000; }
.si-aseprite::before { content: "\eab1"; }
.si-aseprite.si--color::before { color: #7D929E; }
.si-askubuntu::before { content: "\eab2"; }
.si-askubuntu.si--color::before { color: #DC461D; }
.si-askfm::before { content: "\eab3"; }
.si-askfm.si--color::before { color: #DB3552; }
.si-assemblyscript::before { content: "\eab4"; }
.si-assemblyscript.si--color::before { color: #007AAC; }
.si-astonmartin::before { content: "\eab5"; }
.si-astonmartin.si--color::before { color: #000000; }
.si-asus::before { content: "\eab6"; }
.si-asus.si--color::before { color: #000000; }
.si-atandt::before { content: "\eab7"; }
.si-atandt.si--color::before { color: #009FDB; }
.si-atari::before { content: "\eab8"; }
.si-atari.si--color::before { color: #E4202E; }
.si-atlassian::before { content: "\eab9"; }
.si-atlassian.si--color::before { color: #0052CC; }
.si-atom::before { content: "\eaba"; }
.si-atom.si--color::before { color: #66595C; }
.si-audacity::before { content: "\eabb"; }
.si-audacity.si--color::before { color: #0000CC; }
.si-audi::before { content: "\eabc"; }
.si-audi.si--color::before { color: #BB0A30; }
.si-audible::before { content: "\eabd"; }
.si-audible.si--color::before { color: #F8991C; }
.si-audiotechnica::before { content: "\eabe"; }
.si-audiotechnica.si--color::before { color: #000000; }
.si-audioboom::before { content: "\eabf"; }
.si-audioboom.si--color::before { color: #007CE2; }
.si-audiomack::before { content: "\eac0"; }
.si-audiomack.si--color::before { color: #FFA200; }
.si-aurelia::before { content: "\eac1"; }
.si-aurelia.si--color::before { color: #ED2B88; }
.si-auth0::before { content: "\eac2"; }
.si-auth0.si--color::before { color: #EB5424; }
.si-authy::before { content: "\eac3"; }
.si-authy.si--color::before { color: #EC1C24; }
.si-autodesk::before { content: "\eac4"; }
.si-autodesk.si--color::before { color: #0696D7; }
.si-autohotkey::before { content: "\eac5"; }
.si-autohotkey.si--color::before { color: #334455; }
.si-automattic::before { content: "\eac6"; }
.si-automattic.si--color::before { color: #3499CD; }
.si-autoprefixer::before { content: "\eac7"; }
.si-autoprefixer.si--color::before { color: #DD3735; }
.si-avast::before { content: "\eac8"; }
.si-avast.si--color::before { color: #FF7800; }
.si-awesomelists::before { content: "\eac9"; }
.si-awesomelists.si--color::before { color: #FC60A8; }
.si-awesomewm::before { content: "\eaca"; }
.si-awesomewm.si--color::before { color: #535D6C; }
.si-awsamplify::before { content: "\eacb"; }
.si-awsamplify.si--color::before { color: #FF9900; }
.si-azureartifacts::before { content: "\eacc"; }
.si-azureartifacts.si--color::before { color: #CB2E6D; }
.si-azuredataexplorer::before { content: "\eacd"; }
.si-azuredataexplorer.si--color::before { color: #0078D4; }
.si-azuredevops::before { content: "\eace"; }
.si-azuredevops.si--color::before { color: #0078D7; }
.si-azurefunctions::before { content: "\eacf"; }
.si-azurefunctions.si--color::before { color: #0062AD; }
.si-azurepipelines::before { content: "\ead0"; }
.si-azurepipelines.si--color::before { color: #2560E0; }
.si-bandrautomation::before { content: "\ead1"; }
.si-bandrautomation.si--color::before { color: #FF8800; }
.si-babel::before { content: "\ead2"; }
.si-babel.si--color::before { color: #F9DC3E; }
.si-backbonedotjs::before { content: "\ead3"; }
.si-backbonedotjs.si--color::before { color: #0071B5; }
.si-badgr::before { content: "\ead4"; }
.si-badgr.si--color::before { color: #282C4C; }
.si-badoo::before { content: "\ead5"; }
.si-badoo.si--color::before { color: #783BF9; }
.si-baidu::before { content: "\ead6"; }
.si-baidu.si--color::before { color: #2932E1; }
.si-bamboo::before { content: "\ead7"; }
.si-bamboo.si--color::before { color: #0052CC; }
.si-bandcamp::before { content: "\ead8"; }
.si-bandcamp.si--color::before { color: #408294; }
.si-bandlab::before { content: "\ead9"; }
.si-bandlab.si--color::before { color: #DC3710; }
.si-bandsintown::before { content: "\eada"; }
.si-bandsintown.si--color::before { color: #00CEC8; }
.si-bankofamerica::before { content: "\eadb"; }
.si-bankofamerica.si--color::before { color: #012169; }
.si-barclays::before { content: "\eadc"; }
.si-barclays.si--color::before { color: #00AEEF; }
.si-baremetrics::before { content: "\eadd"; }
.si-baremetrics.si--color::before { color: #6078FF; }
.si-basecamp::before { content: "\eade"; }
.si-basecamp.si--color::before { color: #1D2D35; }
.si-bata::before { content: "\eadf"; }
.si-bata.si--color::before { color: #DD282E; }
.si-bathasu::before { content: "\eae0"; }
.si-bathasu.si--color::before { color: #00A3E0; }
.si-battledotnet::before { content: "\eae1"; }
.si-battledotnet.si--color::before { color: #148EFF; }
.si-bbc::before { content: "\eae2"; }
.si-bbc.si--color::before { color: #000000; }
.si-bbciplayer::before { content: "\eae3"; }
.si-bbciplayer.si--color::before { color: #F54997; }
.si-beatport::before { content: "\eae4"; }
.si-beatport.si--color::before { color: #01FF95; }
.si-beats::before { content: "\eae5"; }
.si-beats.si--color::before { color: #005571; }
.si-beatsbydre::before { content: "\eae6"; }
.si-beatsbydre.si--color::before { color: #E01F3D; }
.si-behance::before { content: "\eae7"; }
.si-behance.si--color::before { color: #1769FF; }
.si-beijingsubway::before { content: "\eae8"; }
.si-beijingsubway.si--color::before { color: #004A9D; }
.si-bem::before { content: "\eae9"; }
.si-bem.si--color::before { color: #000000; }
.si-bentley::before { content: "\eaea"; }
.si-bentley.si--color::before { color: #333333; }
.si-betfair::before { content: "\eaeb"; }
.si-betfair.si--color::before { color: #FFB80B; }
.si-bigcartel::before { content: "\eaec"; }
.si-bigcartel.si--color::before { color: #222222; }
.si-bigbasket::before { content: "\eaed"; }
.si-bigbasket.si--color::before { color: #A5CD39; }
.si-bigbluebutton::before { content: "\eaee"; }
.si-bigbluebutton.si--color::before { color: #283274; }
.si-bigcommerce::before { content: "\eaef"; }
.si-bigcommerce.si--color::before { color: #121118; }
.si-bilibili::before { content: "\eaf0"; }
.si-bilibili.si--color::before { color: #00A1D6; }
.si-binance::before { content: "\eaf1"; }
.si-binance.si--color::before { color: #F0B90B; }
.si-biolink::before { content: "\eaf2"; }
.si-biolink.si--color::before { color: #000000; }
.si-bit::before { content: "\eaf3"; }
.si-bit.si--color::before { color: #73398D; }
.si-bitbucket::before { content: "\eaf4"; }
.si-bitbucket.si--color::before { color: #0052CC; }
.si-bitcoin::before { content: "\eaf5"; }
.si-bitcoin.si--color::before { color: #F7931A; }
.si-bitcoincash::before { content: "\eaf6"; }
.si-bitcoincash.si--color::before { color: #0AC18E; }
.si-bitcoinsv::before { content: "\eaf7"; }
.si-bitcoinsv.si--color::before { color: #EAB300; }
.si-bitdefender::before { content: "\eaf8"; }
.si-bitdefender.si--color::before { color: #ED1C24; }
.si-bitly::before { content: "\eaf9"; }
.si-bitly.si--color::before { color: #EE6123; }
.si-bitrise::before { content: "\eafa"; }
.si-bitrise.si--color::before { color: #683D87; }
.si-bitwarden::before { content: "\eafb"; }
.si-bitwarden.si--color::before { color: #175DDC; }
.si-bitwig::before { content: "\eafc"; }
.si-bitwig.si--color::before { color: #FF5A00; }
.si-blackberry::before { content: "\eafd"; }
.si-blackberry.si--color::before { color: #000000; }
.si-blazemeter::before { content: "\eafe"; }
.si-blazemeter.si--color::before { color: #CA2133; }
.si-blazor::before { content: "\eaff"; }
.si-blazor.si--color::before { color: #512BD4; }
.si-blender::before { content: "\eb00"; }
.si-blender.si--color::before { color: #F5792A; }
.si-blockchaindotcom::before { content: "\eb01"; }
.si-blockchaindotcom.si--color::before { color: #121D33; }
.si-blogger::before { content: "\eb02"; }
.si-blogger.si--color::before { color: #FF5722; }
.si-bloglovin::before { content: "\eb03"; }
.si-bloglovin.si--color::before { color: #000000; }
.si-blueprint::before { content: "\eb04"; }
.si-blueprint.si--color::before { color: #137CBD; }
.si-bluetooth::before { content: "\eb05"; }
.si-bluetooth.si--color::before { color: #0082FC; }
.si-bmcsoftware::before { content: "\eb06"; }
.si-bmcsoftware.si--color::before { color: #FE5000; }
.si-bmw::before { content: "\eb07"; }
.si-bmw.si--color::before { color: #0066B1; }
.si-boehringeringelheim::before { content: "\eb08"; }
.si-boehringeringelheim.si--color::before { color: #003366; }
.si-boeing::before { content: "\eb09"; }
.si-boeing.si--color::before { color: #1D439C; }
.si-bookbub::before { content: "\eb0a"; }
.si-bookbub.si--color::before { color: #F44336; }
.si-bookmeter::before { content: "\eb0b"; }
.si-bookmeter.si--color::before { color: #64BC4B; }
.si-bookstack::before { content: "\eb0c"; }
.si-bookstack.si--color::before { color: #0288D1; }
.si-boost::before { content: "\eb0d"; }
.si-boost.si--color::before { color: #F7901E; }
.si-bootstrap::before { content: "\eb0e"; }
.si-bootstrap.si--color::before { color: #7952B3; }
.si-bosch::before { content: "\eb0f"; }
.si-bosch.si--color::before { color: #EA0016; }
.si-bose::before { content: "\eb10"; }
.si-bose.si--color::before { color: #000000; }
.si-boulanger::before { content: "\eb11"; }
.si-boulanger.si--color::before { color: #FD5300; }
.si-bower::before { content: "\eb12"; }
.si-bower.si--color::before { color: #EF5734; }
.si-box::before { content: "\eb13"; }
.si-box.si--color::before { color: #0061D5; }
.si-brandfolder::before { content: "\eb14"; }
.si-brandfolder.si--color::before { color: #40D1F5; }
.si-brave::before { content: "\eb15"; }
.si-brave.si--color::before { color: #FB542B; }
.si-breaker::before { content: "\eb16"; }
.si-breaker.si--color::before { color: #003DAD; }
.si-britishairways::before { content: "\eb17"; }
.si-britishairways.si--color::before { color: #2E5C99; }
.si-broadcom::before { content: "\eb18"; }
.si-broadcom.si--color::before { color: #CC092F; }
.si-bt::before { content: "\eb19"; }
.si-bt.si--color::before { color: #6400AA; }
.si-buddy::before { content: "\eb1a"; }
.si-buddy.si--color::before { color: #1A86FD; }
.si-buefy::before { content: "\eb1b"; }
.si-buefy.si--color::before { color: #7957D5; }
.si-buffer::before { content: "\eb1c"; }
.si-buffer.si--color::before { color: #231F20; }
.si-bugatti::before { content: "\eb1d"; }
.si-bugatti.si--color::before { color: #BE0030; }
.si-bugcrowd::before { content: "\eb1e"; }
.si-bugcrowd.si--color::before { color: #F26822; }
.si-bugsnag::before { content: "\eb1f"; }
.si-bugsnag.si--color::before { color: #4949E4; }
.si-buildkite::before { content: "\eb20"; }
.si-buildkite.si--color::before { color: #14CC80; }
.si-bukalapak::before { content: "\eb21"; }
.si-bukalapak.si--color::before { color: #E31E52; }
.si-bulma::before { content: "\eb22"; }
.si-bulma.si--color::before { color: #00D1B2; }
.si-bunq::before { content: "\eb23"; }
.si-bunq.si--color::before { color: #3394D7; }
.si-burgerking::before { content: "\eb24"; }
.si-burgerking.si--color::before { color: #D62300; }
.si-buymeacoffee::before { content: "\eb25"; }
.si-buymeacoffee.si--color::before { color: #FFDD00; }
.si-buzzfeed::before { content: "\eb26"; }
.si-buzzfeed.si--color::before { color: #EE3322; }
.si-byte::before { content: "\eb27"; }
.si-byte.si--color::before { color: #551DEF; }
.si-bytedance::before { content: "\eb28"; }
.si-bytedance.si--color::before { color: #3C8CFF; }
.si-c::before { content: "\eb29"; }
.si-c.si--color::before { color: #A8B9CC; }
.si-csharp::before { content: "\eb2a"; }
.si-csharp.si--color::before { color: #239120; }
.si-cplusplus::before { content: "\eb2b"; }
.si-cplusplus.si--color::before { color: #00599C; }
.si-cachet::before { content: "\eb2c"; }
.si-cachet.si--color::before { color: #7ED321; }
.si-cairometro::before { content: "\eb2d"; }
.si-cairometro.si--color::before { color: #C10C0C; }
.si-cakephp::before { content: "\eb2e"; }
.si-cakephp.si--color::before { color: #D33C43; }
.si-campaignmonitor::before { content: "\eb2f"; }
.si-campaignmonitor.si--color::before { color: #111324; }
.si-canonical::before { content: "\eb30"; }
.si-canonical.si--color::before { color: #77216F; }
.si-canva::before { content: "\eb31"; }
.si-canva.si--color::before { color: #00C4CC; }
.si-capacitor::before { content: "\eb32"; }
.si-capacitor.si--color::before { color: #119EFF; }
.si-carthrottle::before { content: "\eb33"; }
.si-carthrottle.si--color::before { color: #FF9C42; }
.si-carrefour::before { content: "\eb34"; }
.si-carrefour.si--color::before { color: #004E9F; }
.si-carto::before { content: "\eb35"; }
.si-carto.si--color::before { color: #EB1510; }
.si-cashapp::before { content: "\eb36"; }
.si-cashapp.si--color::before { color: #00C244; }
.si-castbox::before { content: "\eb37"; }
.si-castbox.si--color::before { color: #F55B23; }
.si-castorama::before { content: "\eb38"; }
.si-castorama.si--color::before { color: #0078D7; }
.si-castro::before { content: "\eb39"; }
.si-castro.si--color::before { color: #00B265; }
.si-caterpillar::before { content: "\eb3a"; }
.si-caterpillar.si--color::before { color: #FFCD11; }
.si-cbs::before { content: "\eb3b"; }
.si-cbs.si--color::before { color: #033963; }
.si-cdprojekt::before { content: "\eb3c"; }
.si-cdprojekt.si--color::before { color: #DC0D15; }
.si-celery::before { content: "\eb3d"; }
.si-celery.si--color::before { color: #37814A; }
.si-centos::before { content: "\eb3e"; }
.si-centos.si--color::before { color: #262577; }
.si-ceph::before { content: "\eb3f"; }
.si-ceph.si--color::before { color: #EF5C55; }
.si-cesium::before { content: "\eb40"; }
.si-cesium.si--color::before { color: #6CADDF; }
.si-chai::before { content: "\eb41"; }
.si-chai.si--color::before { color: #A30701; }
.si-chainlink::before { content: "\eb42"; }
.si-chainlink.si--color::before { color: #375BD2; }
.si-chakraui::before { content: "\eb43"; }
.si-chakraui.si--color::before { color: #319795; }
.si-chartdotjs::before { content: "\eb44"; }
.si-chartdotjs.si--color::before { color: #FF6384; }
.si-chartmogul::before { content: "\eb45"; }
.si-chartmogul.si--color::before { color: #13324B; }
.si-chase::before { content: "\eb46"; }
.si-chase.si--color::before { color: #117ACA; }
.si-chatbot::before { content: "\eb47"; }
.si-chatbot.si--color::before { color: #FFD000; }
.si-checkio::before { content: "\eb48"; }
.si-checkio.si--color::before { color: #008DB6; }
.si-checkmarx::before { content: "\eb49"; }
.si-checkmarx.si--color::before { color: #54B848; }
.si-chef::before { content: "\eb4a"; }
.si-chef.si--color::before { color: #F09820; }
.si-chevrolet::before { content: "\eb4b"; }
.si-chevrolet.si--color::before { color: #CD9834; }
.si-chinaeasternairlines::before { content: "\eb4c"; }
.si-chinaeasternairlines.si--color::before { color: #1A2477; }
.si-chinasouthernairlines::before { content: "\eb4d"; }
.si-chinasouthernairlines.si--color::before { color: #008BCB; }
.si-chocolatey::before { content: "\eb4e"; }
.si-chocolatey.si--color::before { color: #80B5E3; }
.si-chrysler::before { content: "\eb4f"; }
.si-chrysler.si--color::before { color: #000000; }
.si-chupachups::before { content: "\eb50"; }
.si-chupachups.si--color::before { color: #CF103E; }
.si-cinema4d::before { content: "\eb51"; }
.si-cinema4d.si--color::before { color: #011A6A; }
.si-circle::before { content: "\eb52"; }
.si-circle.si--color::before { color: #8669AE; }
.si-circleci::before { content: "\eb53"; }
.si-circleci.si--color::before { color: #343434; }
.si-cirrusci::before { content: "\eb54"; }
.si-cirrusci.si--color::before { color: #4051B5; }
.si-cisco::before { content: "\eb55"; }
.si-cisco.si--color::before { color: #1BA0D7; }
.si-citrix::before { content: "\eb56"; }
.si-citrix.si--color::before { color: #452170; }
.si-citroen::before { content: "\eb57"; }
.si-citroen.si--color::before { color: #6E6E6E; }
.si-civicrm::before { content: "\eb58"; }
.si-civicrm.si--color::before { color: #81C459; }
.si-civo::before { content: "\eb59"; }
.si-civo.si--color::before { color: #239DFF; }
.si-ckeditor4::before { content: "\eb5a"; }
.si-ckeditor4.si--color::before { color: #0287D0; }
.si-claris::before { content: "\eb5b"; }
.si-claris.si--color::before { color: #000000; }
.si-clickup::before { content: "\eb5c"; }
.si-clickup.si--color::before { color: #7B68EE; }
.si-clion::before { content: "\eb5d"; }
.si-clion.si--color::before { color: #000000; }
.si-cliqz::before { content: "\eb5e"; }
.si-cliqz.si--color::before { color: #00AEF0; }
.si-clockify::before { content: "\eb5f"; }
.si-clockify.si--color::before { color: #03A9F4; }
.si-clojure::before { content: "\eb60"; }
.si-clojure.si--color::before { color: #5881D8; }
.si-cloud66::before { content: "\eb61"; }
.si-cloud66.si--color::before { color: #3C72B9; }
.si-cloudfoundry::before { content: "\eb62"; }
.si-cloudfoundry.si--color::before { color: #0C9ED5; }
.si-cloudbees::before { content: "\eb63"; }
.si-cloudbees.si--color::before { color: #1997B5; }
.si-cloudcannon::before { content: "\eb64"; }
.si-cloudcannon.si--color::before { color: #407AFC; }
.si-cloudera::before { content: "\eb65"; }
.si-cloudera.si--color::before { color: #F96702; }
.si-cloudflare::before { content: "\eb66"; }
.si-cloudflare.si--color::before { color: #F38020; }
.si-cloudsmith::before { content: "\eb67"; }
.si-cloudsmith.si--color::before { color: #187EB6; }
.si-cloudways::before { content: "\eb68"; }
.si-cloudways.si--color::before { color: #2C39BD; }
.si-clubhouse::before { content: "\eb69"; }
.si-clubhouse.si--color::before { color: #6515DD; }
.si-clyp::before { content: "\eb6a"; }
.si-clyp.si--color::before { color: #3CBDB1; }
.si-cmake::before { content: "\eb6b"; }
.si-cmake.si--color::before { color: #064F8C; }
.si-cnn::before { content: "\eb6c"; }
.si-cnn.si--color::before { color: #CC0000; }
.si-coop::before { content: "\eb6d"; }
.si-coop.si--color::before { color: #00B1E7; }
.si-cockpit::before { content: "\eb6e"; }
.si-cockpit.si--color::before { color: #0066CC; }
.si-cockroachlabs::before { content: "\eb6f"; }
.si-cockroachlabs.si--color::before { color: #6933FF; }
.si-cocoapods::before { content: "\eb70"; }
.si-cocoapods.si--color::before { color: #EE3322; }
.si-cocos::before { content: "\eb71"; }
.si-cocos.si--color::before { color: #55C2E1; }
.si-coda::before { content: "\eb72"; }
.si-coda.si--color::before { color: #F46A54; }
.si-codacy::before { content: "\eb73"; }
.si-codacy.si--color::before { color: #222F29; }
.si-codeclimate::before { content: "\eb74"; }
.si-codeclimate.si--color::before { color: #000000; }
.si-codereview::before { content: "\eb75"; }
.si-codereview.si--color::before { color: #485A62; }
.si-codeberg::before { content: "\eb76"; }
.si-codeberg.si--color::before { color: #2185D0; }
.si-codecademy::before { content: "\eb77"; }
.si-codecademy.si--color::before { color: #1F4056; }
.si-codeceptjs::before { content: "\eb78"; }
.si-codeceptjs.si--color::before { color: #F6E05E; }
.si-codechef::before { content: "\eb79"; }
.si-codechef.si--color::before { color: #5B4638; }
.si-codecov::before { content: "\eb7a"; }
.si-codecov.si--color::before { color: #F01F7A; }
.si-codefactor::before { content: "\eb7b"; }
.si-codefactor.si--color::before { color: #F44A6A; }
.si-codeforces::before { content: "\eb7c"; }
.si-codeforces.si--color::before { color: #1F8ACB; }
.si-codeigniter::before { content: "\eb7d"; }
.si-codeigniter.si--color::before { color: #EF4223; }
.si-codemagic::before { content: "\eb7e"; }
.si-codemagic.si--color::before { color: #F45E3F; }
.si-codemirror::before { content: "\eb7f"; }
.si-codemirror.si--color::before { color: #D30707; }
.si-codenewbie::before { content: "\eb80"; }
.si-codenewbie.si--color::before { color: #6BD80B; }
.si-codepen::before { content: "\eb81"; }
.si-codepen.si--color::before { color: #000000; }
.si-codeproject::before { content: "\eb82"; }
.si-codeproject.si--color::before { color: #FF9900; }
.si-codersrank::before { content: "\eb83"; }
.si-codersrank.si--color::before { color: #67A4AC; }
.si-coderwall::before { content: "\eb84"; }
.si-coderwall.si--color::before { color: #3E8DCC; }
.si-codesandbox::before { content: "\eb85"; }
.si-codesandbox.si--color::before { color: #000000; }
.si-codeship::before { content: "\eb86"; }
.si-codeship.si--color::before { color: #004466; }
.si-codewars::before { content: "\eb87"; }
.si-codewars.si--color::before { color: #B1361E; }
.si-codingninjas::before { content: "\eb88"; }
.si-codingninjas.si--color::before { color: #DD6620; }
.si-codingame::before { content: "\eb89"; }
.si-codingame.si--color::before { color: #F2BB13; }
.si-codio::before { content: "\eb8a"; }
.si-codio.si--color::before { color: #4574E0; }
.si-coffeescript::before { content: "\eb8b"; }
.si-coffeescript.si--color::before { color: #2F2625; }
.si-cognizant::before { content: "\eb8c"; }
.si-cognizant.si--color::before { color: #1A4CA1; }
.si-coil::before { content: "\eb8d"; }
.si-coil.si--color::before { color: #000000; }
.si-coinbase::before { content: "\eb8e"; }
.si-coinbase.si--color::before { color: #0052FF; }
.si-commerzbank::before { content: "\eb8f"; }
.si-commerzbank.si--color::before { color: #FFCC33; }
.si-commonworkflowlanguage::before { content: "\eb90"; }
.si-commonworkflowlanguage.si--color::before { color: #B5314C; }
.si-composer::before { content: "\eb91"; }
.si-composer.si--color::before { color: #885630; }
.si-comsol::before { content: "\eb92"; }
.si-comsol.si--color::before { color: #368CCB; }
.si-concourse::before { content: "\eb93"; }
.si-concourse.si--color::before { color: #3398DC; }
.si-condaforge::before { content: "\eb94"; }
.si-condaforge.si--color::before { color: #000000; }
.si-conekta::before { content: "\eb95"; }
.si-conekta.si--color::before { color: #0A1837; }
.si-confluence::before { content: "\eb96"; }
.si-confluence.si--color::before { color: #172B4D; }
.si-construct3::before { content: "\eb97"; }
.si-construct3.si--color::before { color: #00FFDA; }
.si-consul::before { content: "\eb98"; }
.si-consul.si--color::before { color: #F24C53; }
.si-contactlesspayment::before { content: "\eb99"; }
.si-contactlesspayment.si--color::before { color: #000000; }
.si-containerd::before { content: "\eb9a"; }
.si-containerd.si--color::before { color: #575757; }
.si-contentful::before { content: "\eb9b"; }
.si-contentful.si--color::before { color: #2478CC; }
.si-convertio::before { content: "\eb9c"; }
.si-convertio.si--color::before { color: #FF3333; }
.si-cookiecutter::before { content: "\eb9d"; }
.si-cookiecutter.si--color::before { color: #D4AA00; }
.si-coronaengine::before { content: "\eb9e"; }
.si-coronaengine.si--color::before { color: #F96F29; }
.si-coronarenderer::before { content: "\eb9f"; }
.si-coronarenderer.si--color::before { color: #E6502A; }
.si-corsair::before { content: "\eba0"; }
.si-corsair.si--color::before { color: #000000; }
.si-couchbase::before { content: "\eba1"; }
.si-couchbase.si--color::before { color: #EA2328; }
.si-counterstrike::before { content: "\eba2"; }
.si-counterstrike.si--color::before { color: #000000; }
.si-countingworkspro::before { content: "\eba3"; }
.si-countingworkspro.si--color::before { color: #2E3084; }
.si-coursera::before { content: "\eba4"; }
.si-coursera.si--color::before { color: #0056D2; }
.si-coveralls::before { content: "\eba5"; }
.si-coveralls.si--color::before { color: #3F5767; }
.si-cpanel::before { content: "\eba6"; }
.si-cpanel.si--color::before { color: #FF6C2C; }
.si-craftcms::before { content: "\eba7"; }
.si-craftcms.si--color::before { color: #E5422B; }
.si-createreactapp::before { content: "\eba8"; }
.si-createreactapp.si--color::before { color: #09D3AC; }
.si-creativecommons::before { content: "\eba9"; }
.si-creativecommons.si--color::before { color: #EF9421; }
.si-crehana::before { content: "\ebaa"; }
.si-crehana.si--color::before { color: #4B22F4; }
.si-crowdin::before { content: "\ebab"; }
.si-crowdin.si--color::before { color: #2E3340; }
.si-crowdsource::before { content: "\ebac"; }
.si-crowdsource.si--color::before { color: #4285F4; }
.si-crunchbase::before { content: "\ebad"; }
.si-crunchbase.si--color::before { color: #0288D1; }
.si-crunchyroll::before { content: "\ebae"; }
.si-crunchyroll.si--color::before { color: #F47521; }
.si-cryengine::before { content: "\ebaf"; }
.si-cryengine.si--color::before { color: #000000; }
.si-crystal::before { content: "\ebb0"; }
.si-crystal.si--color::before { color: #000000; }
.si-csswizardry::before { content: "\ebb1"; }
.si-csswizardry.si--color::before { color: #F43059; }
.si-css3::before { content: "\ebb2"; }
.si-css3.si--color::before { color: #1572B6; }
.si-cucumber::before { content: "\ebb3"; }
.si-cucumber.si--color::before { color: #23D96C; }
.si-curl::before { content: "\ebb4"; }
.si-curl.si--color::before { color: #073551; }
.si-curseforge::before { content: "\ebb5"; }
.si-curseforge.si--color::before { color: #6441A4; }
.si-cycling74::before { content: "\ebb6"; }
.si-cycling74.si--color::before { color: #111111; }
.si-cypress::before { content: "\ebb7"; }
.si-cypress.si--color::before { color: #17202C; }
.si-cytoscapedotjs::before { content: "\ebb8"; }
.si-cytoscapedotjs.si--color::before { color: #F7DF1E; }
.si-dedge::before { content: "\ebb9"; }
.si-dedge.si--color::before { color: #432975; }
.si-dwavesystems::before { content: "\ebba"; }
.si-dwavesystems.si--color::before { color: #008CD7; }
.si-d3dotjs::before { content: "\ebbb"; }
.si-d3dotjs.si--color::before { color: #F9A03C; }
.si-dacia::before { content: "\ebbc"; }
.si-dacia.si--color::before { color: #122AFF; }
.si-daf::before { content: "\ebbd"; }
.si-daf.si--color::before { color: #00529B; }
.si-dailymotion::before { content: "\ebbe"; }
.si-dailymotion.si--color::before { color: #0D0D0D; }
.si-daimler::before { content: "\ebbf"; }
.si-daimler.si--color::before { color: #E6E6E6; }
.si-darkreader::before { content: "\ebc0"; }
.si-darkreader.si--color::before { color: #141E24; }
.si-dart::before { content: "\ebc1"; }
.si-dart.si--color::before { color: #0175C2; }
.si-daserste::before { content: "\ebc2"; }
.si-daserste.si--color::before { color: #001A4B; }
.si-dash::before { content: "\ebc3"; }
.si-dash.si--color::before { color: #008DE4; }
.si-dashlane::before { content: "\ebc4"; }
.si-dashlane.si--color::before { color: #0E353D; }
.si-dassaultsystemes::before { content: "\ebc5"; }
.si-dassaultsystemes.si--color::before { color: #005386; }
.si-dataversioncontrol::before { content: "\ebc6"; }
.si-dataversioncontrol.si--color::before { color: #945DD6; }
.si-databricks::before { content: "\ebc7"; }
.si-databricks.si--color::before { color: #FF3621; }
.si-datacamp::before { content: "\ebc8"; }
.si-datacamp.si--color::before { color: #03EF62; }
.si-datadog::before { content: "\ebc9"; }
.si-datadog.si--color::before { color: #632CA6; }
.si-datagrip::before { content: "\ebca"; }
.si-datagrip.si--color::before { color: #000000; }
.si-dataiku::before { content: "\ebcb"; }
.si-dataiku.si--color::before { color: #2AB1AC; }
.si-datastax::before { content: "\ebcc"; }
.si-datastax.si--color::before { color: #3A3A42; }
.si-datocms::before { content: "\ebcd"; }
.si-datocms.si--color::before { color: #FF7751; }
.si-datto::before { content: "\ebce"; }
.si-datto.si--color::before { color: #199ED9; }
.si-dazn::before { content: "\ebcf"; }
.si-dazn.si--color::before { color: #F8F8F5; }
.si-dblp::before { content: "\ebd0"; }
.si-dblp.si--color::before { color: #004F9F; }
.si-dbt::before { content: "\ebd1"; }
.si-dbt.si--color::before { color: #FF694B; }
.si-dcentertainment::before { content: "\ebd2"; }
.si-dcentertainment.si--color::before { color: #0078F0; }
.si-delonghi::before { content: "\ebd3"; }
.si-delonghi.si--color::before { color: #072240; }
.si-debian::before { content: "\ebd4"; }
.si-debian.si--color::before { color: #A81D33; }
.si-deepin::before { content: "\ebd5"; }
.si-deepin.si--color::before { color: #007CFF; }
.si-deepnote::before { content: "\ebd6"; }
.si-deepnote.si--color::before { color: #3793EF; }
.si-deezer::before { content: "\ebd7"; }
.si-deezer.si--color::before { color: #FEAA2D; }
.si-delicious::before { content: "\ebd8"; }
.si-delicious.si--color::before { color: #0000FF; }
.si-deliveroo::before { content: "\ebd9"; }
.si-deliveroo.si--color::before { color: #00CCBC; }
.si-dell::before { content: "\ebda"; }
.si-dell.si--color::before { color: #007DB8; }
.si-delphi::before { content: "\ebdb"; }
.si-delphi.si--color::before { color: #EE1F35; }
.si-delta::before { content: "\ebdc"; }
.si-delta.si--color::before { color: #003366; }
.si-deno::before { content: "\ebdd"; }
.si-deno.si--color::before { color: #000000; }
.si-dependabot::before { content: "\ebde"; }
.si-dependabot.si--color::before { color: #025E8C; }
.si-derspiegel::before { content: "\ebdf"; }
.si-derspiegel.si--color::before { color: #E64415; }
.si-designernews::before { content: "\ebe0"; }
.si-designernews.si--color::before { color: #2D72D9; }
.si-deutschebahn::before { content: "\ebe1"; }
.si-deutschebahn.si--color::before { color: #F01414; }
.si-deutschebank::before { content: "\ebe2"; }
.si-deutschebank.si--color::before { color: #0018A8; }
.si-devdotto::before { content: "\ebe3"; }
.si-devdotto.si--color::before { color: #0A0A0A; }
.si-devexpress::before { content: "\ebe4"; }
.si-devexpress.si--color::before { color: #FF7200; }
.si-deviantart::before { content: "\ebe5"; }
.si-deviantart.si--color::before { color: #05CC47; }
.si-devpost::before { content: "\ebe6"; }
.si-devpost.si--color::before { color: #003E54; }
.si-devrant::before { content: "\ebe7"; }
.si-devrant.si--color::before { color: #F99A66; }
.si-dgraph::before { content: "\ebe8"; }
.si-dgraph.si--color::before { color: #E50695; }
.si-dhl::before { content: "\ebe9"; }
.si-dhl.si--color::before { color: #FFCC00; }
.si-diagramsdotnet::before { content: "\ebea"; }
.si-diagramsdotnet.si--color::before { color: #F08705; }
.si-dialogflow::before { content: "\ebeb"; }
.si-dialogflow.si--color::before { color: #FF9800; }
.si-diaspora::before { content: "\ebec"; }
.si-diaspora.si--color::before { color: #000000; }
.si-digg::before { content: "\ebed"; }
.si-digg.si--color::before { color: #000000; }
.si-digikeyelectronics::before { content: "\ebee"; }
.si-digikeyelectronics.si--color::before { color: #CC0000; }
.si-digitalocean::before { content: "\ebef"; }
.si-digitalocean.si--color::before { color: #0080FF; }
.si-dior::before { content: "\ebf0"; }
.si-dior.si--color::before { color: #000000; }
.si-directus::before { content: "\ebf1"; }
.si-directus.si--color::before { color: #263238; }
.si-discogs::before { content: "\ebf2"; }
.si-discogs.si--color::before { color: #333333; }
.si-discord::before { content: "\ebf3"; }
.si-discord.si--color::before { color: #5865F2; }
.si-discourse::before { content: "\ebf4"; }
.si-discourse.si--color::before { color: #000000; }
.si-discover::before { content: "\ebf5"; }
.si-discover.si--color::before { color: #FF6000; }
.si-disqus::before { content: "\ebf6"; }
.si-disqus.si--color::before { color: #2E9FFF; }
.si-disroot::before { content: "\ebf7"; }
.si-disroot.si--color::before { color: #50162D; }
.si-django::before { content: "\ebf8"; }
.si-django.si--color::before { color: #092E20; }
.si-dlib::before { content: "\ebf9"; }
.si-dlib.si--color::before { color: #008000; }
.si-dlna::before { content: "\ebfa"; }
.si-dlna.si--color::before { color: #48A842; }
.si-docker::before { content: "\ebfb"; }
.si-docker.si--color::before { color: #2496ED; }
.si-docusign::before { content: "\ebfc"; }
.si-docusign.si--color::before { color: #FFCC22; }
.si-dogecoin::before { content: "\ebfd"; }
.si-dogecoin.si--color::before { color: #C2A633; }
.si-dolby::before { content: "\ebfe"; }
.si-dolby.si--color::before { color: #000000; }
.si-doordash::before { content: "\ebff"; }
.si-doordash.si--color::before { color: #FF3008; }
.si-douban::before { content: "\ec00"; }
.si-douban.si--color::before { color: #007722; }
.si-doubanread::before { content: "\ec01"; }
.si-doubanread.si--color::before { color: #389EAC; }
.si-dpd::before { content: "\ec02"; }
.si-dpd.si--color::before { color: #DC0032; }
.si-draugiemdotlv::before { content: "\ec03"; }
.si-draugiemdotlv.si--color::before { color: #FF6600; }
.si-dribbble::before { content: "\ec04"; }
.si-dribbble.si--color::before { color: #EA4C89; }
.si-drone::before { content: "\ec05"; }
.si-drone.si--color::before { color: #212121; }
.si-drooble::before { content: "\ec06"; }
.si-drooble.si--color::before { color: #19C4BE; }
.si-dropbox::before { content: "\ec07"; }
.si-dropbox.si--color::before { color: #0061FF; }
.si-drupal::before { content: "\ec08"; }
.si-drupal.si--color::before { color: #0678BE; }
.si-dsautomobiles::before { content: "\ec09"; }
.si-dsautomobiles.si--color::before { color: #1D1717; }
.si-dtube::before { content: "\ec0a"; }
.si-dtube.si--color::before { color: #F01A30; }
.si-duckduckgo::before { content: "\ec0b"; }
.si-duckduckgo.si--color::before { color: #DE5833; }
.si-dungeonsanddragons::before { content: "\ec0c"; }
.si-dungeonsanddragons.si--color::before { color: #ED1C24; }
.si-dunked::before { content: "\ec0d"; }
.si-dunked.si--color::before { color: #2DA9D7; }
.si-duolingo::before { content: "\ec0e"; }
.si-duolingo.si--color::before { color: #58CC02; }
.si-dwm::before { content: "\ec0f"; }
.si-dwm.si--color::before { color: #1177AA; }
.si-dynamics365::before { content: "\ec10"; }
.si-dynamics365.si--color::before { color: #002050; }
.si-dynatrace::before { content: "\ec11"; }
.si-dynatrace.si--color::before { color: #1496FF; }
.si-edotleclerc::before { content: "\ec12"; }
.si-edotleclerc.si--color::before { color: #0066CC; }
.si-ea::before { content: "\ec13"; }
.si-ea.si--color::before { color: #000000; }
.si-eagle::before { content: "\ec14"; }
.si-eagle.si--color::before { color: #0072EF; }
.si-easyjet::before { content: "\ec15"; }
.si-easyjet.si--color::before { color: #FF6600; }
.si-ebay::before { content: "\ec16"; }
.si-ebay.si--color::before { color: #E53238; }
.si-eclipseche::before { content: "\ec17"; }
.si-eclipseche.si--color::before { color: #525C86; }
.si-eclipseide::before { content: "\ec18"; }
.si-eclipseide.si--color::before { color: #2C2255; }
.si-eclipsejetty::before { content: "\ec19"; }
.si-eclipsejetty.si--color::before { color: #FC390E; }
.si-eclipsemosquitto::before { content: "\ec1a"; }
.si-eclipsemosquitto.si--color::before { color: #3C5280; }
.si-eclipsevertdotx::before { content: "\ec1b"; }
.si-eclipsevertdotx.si--color::before { color: #782A90; }
.si-edeka::before { content: "\ec1c"; }
.si-edeka.si--color::before { color: #1B66B3; }
.si-editorconfig::before { content: "\ec1d"; }
.si-editorconfig.si--color::before { color: #FEFEFE; }
.si-edx::before { content: "\ec1e"; }
.si-edx.si--color::before { color: #02262B; }
.si-egghead::before { content: "\ec1f"; }
.si-egghead.si--color::before { color: #FCFBFA; }
.si-egnyte::before { content: "\ec20"; }
.si-egnyte.si--color::before { color: #00968F; }
.si-eightsleep::before { content: "\ec21"; }
.si-eightsleep.si--color::before { color: #262729; }
.si-eljueves::before { content: "\ec22"; }
.si-eljueves.si--color::before { color: #BE312E; }
.si-elastic::before { content: "\ec23"; }
.si-elastic.si--color::before { color: #005571; }
.si-elasticcloud::before { content: "\ec24"; }
.si-elasticcloud.si--color::before { color: #005571; }
.si-elasticstack::before { content: "\ec25"; }
.si-elasticstack.si--color::before { color: #005571; }
.si-elasticsearch::before { content: "\ec26"; }
.si-elasticsearch.si--color::before { color: #005571; }
.si-electron::before { content: "\ec27"; }
.si-electron.si--color::before { color: #47848F; }
.si-element::before { content: "\ec28"; }
.si-element.si--color::before { color: #0DBD8B; }
.si-elementary::before { content: "\ec29"; }
.si-elementary.si--color::before { color: #64BAFF; }
.si-eleventy::before { content: "\ec2a"; }
.si-eleventy.si--color::before { color: #000000; }
.si-elixir::before { content: "\ec2b"; }
.si-elixir.si--color::before { color: #4B275F; }
.si-ello::before { content: "\ec2c"; }
.si-ello.si--color::before { color: #000000; }
.si-elm::before { content: "\ec2d"; }
.si-elm.si--color::before { color: #1293D8; }
.si-elsevier::before { content: "\ec2e"; }
.si-elsevier.si--color::before { color: #FF6C00; }
.si-embarcadero::before { content: "\ec2f"; }
.si-embarcadero.si--color::before { color: #ED1F35; }
.si-emberdotjs::before { content: "\ec30"; }
.si-emberdotjs.si--color::before { color: #E04E39; }
.si-emby::before { content: "\ec31"; }
.si-emby.si--color::before { color: #52B54B; }
.si-emirates::before { content: "\ec32"; }
.si-emirates.si--color::before { color: #D71921; }
.si-emlakjet::before { content: "\ec33"; }
.si-emlakjet.si--color::before { color: #0AE524; }
.si-empirekred::before { content: "\ec34"; }
.si-empirekred.si--color::before { color: #72BE50; }
.si-enpass::before { content: "\ec35"; }
.si-enpass.si--color::before { color: #0D47A1; }
.si-envato::before { content: "\ec36"; }
.si-envato.si--color::before { color: #81B441; }
.si-epel::before { content: "\ec37"; }
.si-epel.si--color::before { color: #FC0000; }
.si-epicgames::before { content: "\ec38"; }
.si-epicgames.si--color::before { color: #313131; }
.si-epson::before { content: "\ec39"; }
.si-epson.si--color::before { color: #003399; }
.si-equinixmetal::before { content: "\ec3a"; }
.si-equinixmetal.si--color::before { color: #ED2224; }
.si-erlang::before { content: "\ec3b"; }
.si-erlang.si--color::before { color: #A90533; }
.si-esbuild::before { content: "\ec3c"; }
.si-esbuild.si--color::before { color: #FFCF00; }
.si-esea::before { content: "\ec3d"; }
.si-esea.si--color::before { color: #0E9648; }
.si-eslgaming::before { content: "\ec3e"; }
.si-eslgaming.si--color::before { color: #FFFF09; }
.si-eslint::before { content: "\ec3f"; }
.si-eslint.si--color::before { color: #4B32C3; }
.si-esphome::before { content: "\ec40"; }
.si-esphome.si--color::before { color: #000000; }
.si-espressif::before { content: "\ec41"; }
.si-espressif.si--color::before { color: #E7352C; }
.si-etcd::before { content: "\ec42"; }
.si-etcd.si--color::before { color: #419EDA; }
.si-ethereum::before { content: "\ec43"; }
.si-ethereum.si--color::before { color: #3C3C3D; }
.si-ethiopianairlines::before { content: "\ec44"; }
.si-ethiopianairlines.si--color::before { color: #648B1A; }
.si-etihadairways::before { content: "\ec45"; }
.si-etihadairways.si--color::before { color: #BD8B13; }
.si-etsy::before { content: "\ec46"; }
.si-etsy.si--color::before { color: #F16521; }
.si-eventstore::before { content: "\ec47"; }
.si-eventstore.si--color::before { color: #5AB552; }
.si-eventbrite::before { content: "\ec48"; }
.si-eventbrite.si--color::before { color: #F05537; }
.si-evernote::before { content: "\ec49"; }
.si-evernote.si--color::before { color: #00A82D; }
.si-exercism::before { content: "\ec4a"; }
.si-exercism.si--color::before { color: #009CAB; }
.si-expensify::before { content: "\ec4b"; }
.si-expensify.si--color::before { color: #0185FF; }
.si-expertsexchange::before { content: "\ec4c"; }
.si-expertsexchange.si--color::before { color: #00AAE7; }
.si-expo::before { content: "\ec4d"; }
.si-expo.si--color::before { color: #000020; }
.si-express::before { content: "\ec4e"; }
.si-express.si--color::before { color: #000000; }
.si-expressvpn::before { content: "\ec4f"; }
.si-expressvpn.si--color::before { color: #DA3940; }
.si-eyeem::before { content: "\ec50"; }
.si-eyeem.si--color::before { color: #000000; }
.si-fdroid::before { content: "\ec51"; }
.si-fdroid.si--color::before { color: #1976D2; }
.si-fsecure::before { content: "\ec52"; }
.si-fsecure.si--color::before { color: #00BAFF; }
.si-facebook::before { content: "\ec53"; }
.si-facebook.si--color::before { color: #1877F2; }
.si-facebookgaming::before { content: "\ec54"; }
.si-facebookgaming.si--color::before { color: #005FED; }
.si-facebooklive::before { content: "\ec55"; }
.si-facebooklive.si--color::before { color: #ED4242; }
.si-faceit::before { content: "\ec56"; }
.si-faceit.si--color::before { color: #FF5500; }
.si-facepunch::before { content: "\ec57"; }
.si-facepunch.si--color::before { color: #EC1C24; }
.si-falcon::before { content: "\ec58"; }
.si-falcon.si--color::before { color: #F0AD4E; }
.si-fampay::before { content: "\ec59"; }
.si-fampay.si--color::before { color: #FFAD00; }
.si-fandango::before { content: "\ec5a"; }
.si-fandango.si--color::before { color: #FF7300; }
.si-fandom::before { content: "\ec5b"; }
.si-fandom.si--color::before { color: #00D6D6; }
.si-farfetch::before { content: "\ec5c"; }
.si-farfetch.si--color::before { color: #000000; }
.si-fastapi::before { content: "\ec5d"; }
.si-fastapi.si--color::before { color: #009688; }
.si-fastify::before { content: "\ec5e"; }
.si-fastify.si--color::before { color: #000000; }
.si-fastlane::before { content: "\ec5f"; }
.si-fastlane.si--color::before { color: #00F200; }
.si-fastly::before { content: "\ec60"; }
.si-fastly.si--color::before { color: #FF282D; }
.si-fathom::before { content: "\ec61"; }
.si-fathom.si--color::before { color: #9187FF; }
.si-favro::before { content: "\ec62"; }
.si-favro.si--color::before { color: #512DA8; }
.si-feathub::before { content: "\ec63"; }
.si-feathub.si--color::before { color: #9B9B9B; }
.si-fedex::before { content: "\ec64"; }
.si-fedex.si--color::before { color: #4D148C; }
.si-fedora::before { content: "\ec65"; }
.si-fedora.si--color::before { color: #294172; }
.si-feedly::before { content: "\ec66"; }
.si-feedly.si--color::before { color: #2BB24C; }
.si-ferrari::before { content: "\ec67"; }
.si-ferrari.si--color::before { color: #D40000; }
.si-ferrarinv::before { content: "\ec68"; }
.si-ferrarinv.si--color::before { color: #EB2E2C; }
.si-ffmpeg::before { content: "\ec69"; }
.si-ffmpeg.si--color::before { color: #007808; }
.si-fiat::before { content: "\ec6a"; }
.si-fiat.si--color::before { color: #941711; }
.si-fidoalliance::before { content: "\ec6b"; }
.si-fidoalliance.si--color::before { color: #FFBF3B; }
.si-fifa::before { content: "\ec6c"; }
.si-fifa.si--color::before { color: #326295; }
.si-figma::before { content: "\ec6d"; }
.si-figma.si--color::before { color: #F24E1E; }
.si-figshare::before { content: "\ec6e"; }
.si-figshare.si--color::before { color: #556472; }
.si-fila::before { content: "\ec6f"; }
.si-fila.si--color::before { color: #03234C; }
.si-files::before { content: "\ec70"; }
.si-files.si--color::before { color: #4285F4; }
.si-filezilla::before { content: "\ec71"; }
.si-filezilla.si--color::before { color: #BF0000; }
.si-fing::before { content: "\ec72"; }
.si-fing.si--color::before { color: #009AEE; }
.si-firebase::before { content: "\ec73"; }
.si-firebase.si--color::before { color: #FFCA28; }
.si-firefox::before { content: "\ec74"; }
.si-firefox.si--color::before { color: #FF7139; }
.si-firefoxbrowser::before { content: "\ec75"; }
.si-firefoxbrowser.si--color::before { color: #FF7139; }
.si-first::before { content: "\ec76"; }
.si-first.si--color::before { color: #0066B3; }
.si-fitbit::before { content: "\ec77"; }
.si-fitbit.si--color::before { color: #00B0B9; }
.si-fite::before { content: "\ec78"; }
.si-fite.si--color::before { color: #CA0404; }
.si-fivem::before { content: "\ec79"; }
.si-fivem.si--color::before { color: #F40552; }
.si-fiverr::before { content: "\ec7a"; }
.si-fiverr.si--color::before { color: #1DBF73; }
.si-flask::before { content: "\ec7b"; }
.si-flask.si--color::before { color: #000000; }
.si-flat::before { content: "\ec7c"; }
.si-flat.si--color::before { color: #3481FE; }
.si-flathub::before { content: "\ec7d"; }
.si-flathub.si--color::before { color: #4A86CF; }
.si-flattr::before { content: "\ec7e"; }
.si-flattr.si--color::before { color: #000000; }
.si-flickr::before { content: "\ec7f"; }
.si-flickr.si--color::before { color: #0063DC; }
.si-flipboard::before { content: "\ec80"; }
.si-flipboard.si--color::before { color: #E12828; }
.si-flipkart::before { content: "\ec81"; }
.si-flipkart.si--color::before { color: #2874F0; }
.si-floatplane::before { content: "\ec82"; }
.si-floatplane.si--color::before { color: #00AEEF; }
.si-flood::before { content: "\ec83"; }
.si-flood.si--color::before { color: #4285F4; }
.si-fluentd::before { content: "\ec84"; }
.si-fluentd.si--color::before { color: #0E83C8; }
.si-flutter::before { content: "\ec85"; }
.si-flutter.si--color::before { color: #02569B; }
.si-flyway::before { content: "\ec86"; }
.si-flyway.si--color::before { color: #CC0200; }
.si-fmod::before { content: "\ec87"; }
.si-fmod.si--color::before { color: #000000; }
.si-fnac::before { content: "\ec88"; }
.si-fnac.si--color::before { color: #E1A925; }
.si-folium::before { content: "\ec89"; }
.si-folium.si--color::before { color: #77B829; }
.si-fonoma::before { content: "\ec8a"; }
.si-fonoma.si--color::before { color: #02B78F; }
.si-fontawesome::before { content: "\ec8b"; }
.si-fontawesome.si--color::before { color: #339AF0; }
.si-fontbase::before { content: "\ec8c"; }
.si-fontbase.si--color::before { color: #3D03A7; }
.si-foodpanda::before { content: "\ec8d"; }
.si-foodpanda.si--color::before { color: #D70F64; }
.si-ford::before { content: "\ec8e"; }
.si-ford.si--color::before { color: #00274E; }
.si-forestry::before { content: "\ec8f"; }
.si-forestry.si--color::before { color: #343A40; }
.si-formstack::before { content: "\ec90"; }
.si-formstack.si--color::before { color: #21B573; }
.si-fortinet::before { content: "\ec91"; }
.si-fortinet.si--color::before { color: #EE3124; }
.si-fortran::before { content: "\ec92"; }
.si-fortran.si--color::before { color: #734F96; }
.si-fossa::before { content: "\ec93"; }
.si-fossa.si--color::before { color: #289E6D; }
.si-fossilscm::before { content: "\ec94"; }
.si-fossilscm.si--color::before { color: #548294; }
.si-foursquare::before { content: "\ec95"; }
.si-foursquare.si--color::before { color: #3333FF; }
.si-foursquarecityguide::before { content: "\ec96"; }
.si-foursquarecityguide.si--color::before { color: #F94877; }
.si-foxtel::before { content: "\ec97"; }
.si-foxtel.si--color::before { color: #EB5205; }
.si-fozzy::before { content: "\ec98"; }
.si-fozzy.si--color::before { color: #F15B29; }
.si-framer::before { content: "\ec99"; }
.si-framer.si--color::before { color: #0055FF; }
.si-framework7::before { content: "\ec9a"; }
.si-framework7.si--color::before { color: #EE350F; }
.si-franprix::before { content: "\ec9b"; }
.si-franprix.si--color::before { color: #EC6237; }
.si-fraunhofergesellschaft::before { content: "\ec9c"; }
.si-fraunhofergesellschaft.si--color::before { color: #179C7D; }
.si-freebsd::before { content: "\ec9d"; }
.si-freebsd.si--color::before { color: #AB2B28; }
.si-freecodecamp::before { content: "\ec9e"; }
.si-freecodecamp.si--color::before { color: #0A0A23; }
.si-freedesktopdotorg::before { content: "\ec9f"; }
.si-freedesktopdotorg.si--color::before { color: #3B80AE; }
.si-freelancer::before { content: "\eca0"; }
.si-freelancer.si--color::before { color: #29B2FE; }
.si-freenas::before { content: "\eca1"; }
.si-freenas.si--color::before { color: #343434; }
.si-frontendmentor::before { content: "\eca2"; }
.si-frontendmentor.si--color::before { color: #3F54A3; }
.si-fujifilm::before { content: "\eca3"; }
.si-fujifilm.si--color::before { color: #ED1A3A; }
.si-fujitsu::before { content: "\eca4"; }
.si-fujitsu.si--color::before { color: #FF0000; }
.si-funimation::before { content: "\eca5"; }
.si-funimation.si--color::before { color: #5B0BB5; }
.si-furaffinity::before { content: "\eca6"; }
.si-furaffinity.si--color::before { color: #36566F; }
.si-furrynetwork::before { content: "\eca7"; }
.si-furrynetwork.si--color::before { color: #2E75B4; }
.si-futurelearn::before { content: "\eca8"; }
.si-futurelearn.si--color::before { color: #DE00A5; }
.si-g2a::before { content: "\eca9"; }
.si-g2a.si--color::before { color: #F05F00; }
.si-gamejolt::before { content: "\ecaa"; }
.si-gamejolt.si--color::before { color: #CCFF00; }
.si-garmin::before { content: "\ecab"; }
.si-garmin.si--color::before { color: #000000; }
.si-gatling::before { content: "\ecac"; }
.si-gatling.si--color::before { color: #FF9E2A; }
.si-gatsby::before { content: "\ecad"; }
.si-gatsby.si--color::before { color: #663399; }
.si-geant::before { content: "\ecae"; }
.si-geant.si--color::before { color: #DD1F26; }
.si-geeksforgeeks::before { content: "\ecaf"; }
.si-geeksforgeeks.si--color::before { color: #2F8D46; }
.si-generalelectric::before { content: "\ecb0"; }
.si-generalelectric.si--color::before { color: #0870D8; }
.si-generalmotors::before { content: "\ecb1"; }
.si-generalmotors.si--color::before { color: #0170CE; }
.si-genius::before { content: "\ecb2"; }
.si-genius.si--color::before { color: #FFFF64; }
.si-gentoo::before { content: "\ecb3"; }
.si-gentoo.si--color::before { color: #54487A; }
.si-geocaching::before { content: "\ecb4"; }
.si-geocaching.si--color::before { color: #00874D; }
.si-gerrit::before { content: "\ecb5"; }
.si-gerrit.si--color::before { color: #EEEEEE; }
.si-ghost::before { content: "\ecb6"; }
.si-ghost.si--color::before { color: #15171A; }
.si-ghostery::before { content: "\ecb7"; }
.si-ghostery.si--color::before { color: #00AEF0; }
.si-gimp::before { content: "\ecb8"; }
.si-gimp.si--color::before { color: #5C5543; }
.si-giphy::before { content: "\ecb9"; }
.si-giphy.si--color::before { color: #FF6666; }
.si-git::before { content: "\ecba"; }
.si-git.si--color::before { color: #F05032; }
.si-gitextensions::before { content: "\ecbb"; }
.si-gitextensions.si--color::before { color: #212121; }
.si-gitlfs::before { content: "\ecbc"; }
.si-gitlfs.si--color::before { color: #F64935; }
.si-gitbook::before { content: "\ecbd"; }
.si-gitbook.si--color::before { color: #3884FF; }
.si-gitea::before { content: "\ecbe"; }
.si-gitea.si--color::before { color: #609926; }
.si-gitee::before { content: "\ecbf"; }
.si-gitee.si--color::before { color: #C71D23; }
.si-github::before { content: "\ecc0"; }
.si-github.si--color::before { color: #181717; }
.si-githubactions::before { content: "\ecc1"; }
.si-githubactions.si--color::before { color: #2088FF; }
.si-githubpages::before { content: "\ecc2"; }
.si-githubpages.si--color::before { color: #222222; }
.si-githubsponsors::before { content: "\ecc3"; }
.si-githubsponsors.si--color::before { color: #EA4AAA; }
.si-gitignoredotio::before { content: "\ecc4"; }
.si-gitignoredotio.si--color::before { color: #204ECF; }
.si-gitkraken::before { content: "\ecc5"; }
.si-gitkraken.si--color::before { color: #179287; }
.si-gitlab::before { content: "\ecc6"; }
.si-gitlab.si--color::before { color: #FCA121; }
.si-gitpod::before { content: "\ecc7"; }
.si-gitpod.si--color::before { color: #FFAE33; }
.si-gitter::before { content: "\ecc8"; }
.si-gitter.si--color::before { color: #ED1965; }
.si-glassdoor::before { content: "\ecc9"; }
.si-glassdoor.si--color::before { color: #0CAA41; }
.si-glitch::before { content: "\ecca"; }
.si-glitch.si--color::before { color: #3333FF; }
.si-globus::before { content: "\eccb"; }
.si-globus.si--color::before { color: #CA6201; }
.si-gmail::before { content: "\eccc"; }
.si-gmail.si--color::before { color: #EA4335; }
.si-gnome::before { content: "\eccd"; }
.si-gnome.si--color::before { color: #4A86CF; }
.si-gnu::before { content: "\ecce"; }
.si-gnu.si--color::before { color: #A42E2B; }
.si-gnubash::before { content: "\eccf"; }
.si-gnubash.si--color::before { color: #4EAA25; }
.si-gnuemacs::before { content: "\ecd0"; }
.si-gnuemacs.si--color::before { color: #7F5AB6; }
.si-gnuicecat::before { content: "\ecd1"; }
.si-gnuicecat.si--color::before { color: #002F5B; }
.si-gnuprivacyguard::before { content: "\ecd2"; }
.si-gnuprivacyguard.si--color::before { color: #0093DD; }
.si-gnusocial::before { content: "\ecd3"; }
.si-gnusocial.si--color::before { color: #A22430; }
.si-go::before { content: "\ecd4"; }
.si-go.si--color::before { color: #00ADD8; }
.si-gocd::before { content: "\ecd5"; }
.si-gocd.si--color::before { color: #94399E; }
.si-godaddy::before { content: "\ecd6"; }
.si-godaddy.si--color::before { color: #1BDBDB; }
.si-godotengine::before { content: "\ecd7"; }
.si-godotengine.si--color::before { color: #478CBF; }
.si-gofundme::before { content: "\ecd8"; }
.si-gofundme.si--color::before { color: #00B964; }
.si-gogdotcom::before { content: "\ecd9"; }
.si-gogdotcom.si--color::before { color: #86328A; }
.si-goland::before { content: "\ecda"; }
.si-goland.si--color::before { color: #000000; }
.si-goldenline::before { content: "\ecdb"; }
.si-goldenline.si--color::before { color: #FFE005; }
.si-goodreads::before { content: "\ecdc"; }
.si-goodreads.si--color::before { color: #372213; }
.si-google::before { content: "\ecdd"; }
.si-google.si--color::before { color: #4285F4; }
.si-googleads::before { content: "\ecde"; }
.si-googleads.si--color::before { color: #4285F4; }
.si-googleadsense::before { content: "\ecdf"; }
.si-googleadsense.si--color::before { color: #4285F4; }
.si-googleanalytics::before { content: "\ece0"; }
.si-googleanalytics.si--color::before { color: #E37400; }
.si-googleassistant::before { content: "\ece1"; }
.si-googleassistant.si--color::before { color: #4285F4; }
.si-googlecalendar::before { content: "\ece2"; }
.si-googlecalendar.si--color::before { color: #4285F4; }
.si-googlecardboard::before { content: "\ece3"; }
.si-googlecardboard.si--color::before { color: #FF7143; }
.si-googlecast::before { content: "\ece4"; }
.si-googlecast.si--color::before { color: #1BB6F6; }
.si-googlechat::before { content: "\ece5"; }
.si-googlechat.si--color::before { color: #00AC47; }
.si-googlechrome::before { content: "\ece6"; }
.si-googlechrome.si--color::before { color: #4285F4; }
.si-googleclassroom::before { content: "\ece7"; }
.si-googleclassroom.si--color::before { color: #4285F4; }
.si-googlecloud::before { content: "\ece8"; }
.si-googlecloud.si--color::before { color: #4285F4; }
.si-googlecolab::before { content: "\ece9"; }
.si-googlecolab.si--color::before { color: #F9AB00; }
.si-googledomains::before { content: "\ecea"; }
.si-googledomains.si--color::before { color: #4285F4; }
.si-googledrive::before { content: "\eceb"; }
.si-googledrive.si--color::before { color: #4285F4; }
.si-googleearth::before { content: "\ecec"; }
.si-googleearth.si--color::before { color: #4285F4; }
.si-googlefit::before { content: "\eced"; }
.si-googlefit.si--color::before { color: #4285F4; }
.si-googlefonts::before { content: "\ecee"; }
.si-googlefonts.si--color::before { color: #4285F4; }
.si-googlehangouts::before { content: "\ecef"; }
.si-googlehangouts.si--color::before { color: #0C9D58; }
.si-googlekeep::before { content: "\ecf0"; }
.si-googlekeep.si--color::before { color: #FFBB00; }
.si-googlelens::before { content: "\ecf1"; }
.si-googlelens.si--color::before { color: #4285F4; }
.si-googlemaps::before { content: "\ecf2"; }
.si-googlemaps.si--color::before { color: #4285F4; }
.si-googlemeet::before { content: "\ecf3"; }
.si-googlemeet.si--color::before { color: #00897B; }
.si-googlemessages::before { content: "\ecf4"; }
.si-googlemessages.si--color::before { color: #1A73E8; }
.si-googlemybusiness::before { content: "\ecf5"; }
.si-googlemybusiness.si--color::before { color: #4285F4; }
.si-googlenearby::before { content: "\ecf6"; }
.si-googlenearby.si--color::before { color: #4285F4; }
.si-googlenews::before { content: "\ecf7"; }
.si-googlenews.si--color::before { color: #174EA6; }
.si-googleoptimize::before { content: "\ecf8"; }
.si-googleoptimize.si--color::before { color: #B366F6; }
.si-googlepay::before { content: "\ecf9"; }
.si-googlepay.si--color::before { color: #4285F4; }
.si-googlephotos::before { content: "\ecfa"; }
.si-googlephotos.si--color::before { color: #4285F4; }
.si-googleplay::before { content: "\ecfb"; }
.si-googleplay.si--color::before { color: #414141; }
.si-googlepodcasts::before { content: "\ecfc"; }
.si-googlepodcasts.si--color::before { color: #4285F4; }
.si-googlescholar::before { content: "\ecfd"; }
.si-googlescholar.si--color::before { color: #4285F4; }
.si-googlesearchconsole::before { content: "\ecfe"; }
.si-googlesearchconsole.si--color::before { color: #458CF5; }
.si-googlesheets::before { content: "\ecff"; }
.si-googlesheets.si--color::before { color: #34A853; }
.si-googlestreetview::before { content: "\ed00"; }
.si-googlestreetview.si--color::before { color: #FEC111; }
.si-googletagmanager::before { content: "\ed01"; }
.si-googletagmanager.si--color::before { color: #246FDB; }
.si-googletranslate::before { content: "\ed02"; }
.si-googletranslate.si--color::before { color: #4285F4; }
.si-gotomeeting::before { content: "\ed03"; }
.si-gotomeeting.si--color::before { color: #F68D2E; }
.si-grab::before { content: "\ed04"; }
.si-grab.si--color::before { color: #00B14F; }
.si-gradle::before { content: "\ed05"; }
.si-gradle.si--color::before { color: #02303A; }
.si-grafana::before { content: "\ed06"; }
.si-grafana.si--color::before { color: #F46800; }
.si-grammarly::before { content: "\ed07"; }
.si-grammarly.si--color::before { color: #15C39A; }
.si-graphql::before { content: "\ed08"; }
.si-graphql.si--color::before { color: #E10098; }
.si-grav::before { content: "\ed09"; }
.si-grav.si--color::before { color: #221E1F; }
.si-gravatar::before { content: "\ed0a"; }
.si-gravatar.si--color::before { color: #1E8CBE; }
.si-graylog::before { content: "\ed0b"; }
.si-graylog.si--color::before { color: #FF3633; }
.si-greensock::before { content: "\ed0c"; }
.si-greensock.si--color::before { color: #88CE02; }
.si-griddotai::before { content: "\ed0d"; }
.si-griddotai.si--color::before { color: #78FF96; }
.si-gridsome::before { content: "\ed0e"; }
.si-gridsome.si--color::before { color: #00A672; }
.si-groupon::before { content: "\ed0f"; }
.si-groupon.si--color::before { color: #53A318; }
.si-grubhub::before { content: "\ed10"; }
.si-grubhub.si--color::before { color: #F63440; }
.si-grunt::before { content: "\ed11"; }
.si-grunt.si--color::before { color: #FAA918; }
.si-guangzhoumetro::before { content: "\ed12"; }
.si-guangzhoumetro.si--color::before { color: #C51935; }
.si-guilded::before { content: "\ed13"; }
.si-guilded.si--color::before { color: #F5C400; }
.si-gulp::before { content: "\ed14"; }
.si-gulp.si--color::before { color: #CF4647; }
.si-gumroad::before { content: "\ed15"; }
.si-gumroad.si--color::before { color: #36A9AE; }
.si-gumtree::before { content: "\ed16"; }
.si-gumtree.si--color::before { color: #72EF36; }
.si-gunicorn::before { content: "\ed17"; }
.si-gunicorn.si--color::before { color: #499848; }
.si-gutenberg::before { content: "\ed18"; }
.si-gutenberg.si--color::before { color: #000000; }
.si-habr::before { content: "\ed19"; }
.si-habr.si--color::before { color: #65A3BE; }
.si-hackclub::before { content: "\ed1a"; }
.si-hackclub.si--color::before { color: #EC3750; }
.si-hackthebox::before { content: "\ed1b"; }
.si-hackthebox.si--color::before { color: #9FEF00; }
.si-hackaday::before { content: "\ed1c"; }
.si-hackaday.si--color::before { color: #1A1A1A; }
.si-hackernoon::before { content: "\ed1d"; }
.si-hackernoon.si--color::before { color: #00FE00; }
.si-hackerearth::before { content: "\ed1e"; }
.si-hackerearth.si--color::before { color: #2C3454; }
.si-hackerone::before { content: "\ed1f"; }
.si-hackerone.si--color::before { color: #494649; }
.si-hackerrank::before { content: "\ed20"; }
.si-hackerrank.si--color::before { color: #00EA64; }
.si-hackster::before { content: "\ed21"; }
.si-hackster.si--color::before { color: #2E9FE6; }
.si-handlebarsdotjs::before { content: "\ed22"; }
.si-handlebarsdotjs.si--color::before { color: #000000; }
.si-handshake::before { content: "\ed23"; }
.si-handshake.si--color::before { color: #FF2F1C; }
.si-handshake_protocol::before { content: "\ed24"; }
.si-handshake_protocol.si--color::before { color: #000000; }
.si-happycow::before { content: "\ed25"; }
.si-happycow.si--color::before { color: #7C4EC4; }
.si-harbor::before { content: "\ed26"; }
.si-harbor.si--color::before { color: #60B932; }
.si-hashnode::before { content: "\ed27"; }
.si-hashnode.si--color::before { color: #2962FF; }
.si-haskell::before { content: "\ed28"; }
.si-haskell.si--color::before { color: #5D4F85; }
.si-hasura::before { content: "\ed29"; }
.si-hasura.si--color::before { color: #1EB4D4; }
.si-hatenabookmark::before { content: "\ed2a"; }
.si-hatenabookmark.si--color::before { color: #00A4DE; }
.si-haveibeenpwned::before { content: "\ed2b"; }
.si-haveibeenpwned.si--color::before { color: #2A6379; }
.si-haxe::before { content: "\ed2c"; }
.si-haxe.si--color::before { color: #EA8220; }
.si-hbo::before { content: "\ed2d"; }
.si-hbo.si--color::before { color: #000000; }
.si-hcl::before { content: "\ed2e"; }
.si-hcl.si--color::before { color: #006BB6; }
.si-headspace::before { content: "\ed2f"; }
.si-headspace.si--color::before { color: #F47D31; }
.si-hellofresh::before { content: "\ed30"; }
.si-hellofresh.si--color::before { color: #99CC33; }
.si-hellyhansen::before { content: "\ed31"; }
.si-hellyhansen.si--color::before { color: #DA2128; }
.si-helm::before { content: "\ed32"; }
.si-helm.si--color::before { color: #0F1689; }
.si-helpscout::before { content: "\ed33"; }
.si-helpscout.si--color::before { color: #1292EE; }
.si-helpdesk::before { content: "\ed34"; }
.si-helpdesk.si--color::before { color: #FFD000; }
.si-here::before { content: "\ed35"; }
.si-here.si--color::before { color: #00AFAA; }
.si-heroku::before { content: "\ed36"; }
.si-heroku.si--color::before { color: #430098; }
.si-hetzner::before { content: "\ed37"; }
.si-hetzner.si--color::before { color: #D50C2D; }
.si-hexo::before { content: "\ed38"; }
.si-hexo.si--color::before { color: #0E83CD; }
.si-hey::before { content: "\ed39"; }
.si-hey.si--color::before { color: #5522FA; }
.si-hibernate::before { content: "\ed3a"; }
.si-hibernate.si--color::before { color: #59666C; }
.si-hilton::before { content: "\ed3b"; }
.si-hilton.si--color::before { color: #124D97; }
.si-hitachi::before { content: "\ed3c"; }
.si-hitachi.si--color::before { color: #E60027; }
.si-hive::before { content: "\ed3d"; }
.si-hive.si--color::before { color: #FF7A00; }
.si-hive_blockchain::before { content: "\ed3e"; }
.si-hive_blockchain.si--color::before { color: #E31337; }
.si-homeassistant::before { content: "\ed3f"; }
.si-homeassistant.si--color::before { color: #41BDF5; }
.si-homeassistantcommunitystore::before { content: "\ed40"; }
.si-homeassistantcommunitystore.si--color::before { color: #41BDF5; }
.si-homeadvisor::before { content: "\ed41"; }
.si-homeadvisor.si--color::before { color: #F68315; }
.si-homebrew::before { content: "\ed42"; }
.si-homebrew.si--color::before { color: #FBB040; }
.si-homebridge::before { content: "\ed43"; }
.si-homebridge.si--color::before { color: #491F59; }
.si-homify::before { content: "\ed44"; }
.si-homify.si--color::before { color: #7DCDA3; }
.si-honda::before { content: "\ed45"; }
.si-honda.si--color::before { color: #E40521; }
.si-hootsuite::before { content: "\ed46"; }
.si-hootsuite.si--color::before { color: #143059; }
.si-hoppscotch::before { content: "\ed47"; }
.si-hoppscotch.si--color::before { color: #31C48D; }
.si-hotelsdotcom::before { content: "\ed48"; }
.si-hotelsdotcom.si--color::before { color: #D32F2F; }
.si-hotjar::before { content: "\ed49"; }
.si-hotjar.si--color::before { color: #FD3A5C; }
.si-houdini::before { content: "\ed4a"; }
.si-houdini.si--color::before { color: #FF4713; }
.si-houzz::before { content: "\ed4b"; }
.si-houzz.si--color::before { color: #4DBC15; }
.si-hp::before { content: "\ed4c"; }
.si-hp.si--color::before { color: #0096D6; }
.si-htmlacademy::before { content: "\ed4d"; }
.si-htmlacademy.si--color::before { color: #302683; }
.si-html5::before { content: "\ed4e"; }
.si-html5.si--color::before { color: #E34F26; }
.si-huawei::before { content: "\ed4f"; }
.si-huawei.si--color::before { color: #FF0000; }
.si-hubspot::before { content: "\ed50"; }
.si-hubspot.si--color::before { color: #FF7A59; }
.si-hugo::before { content: "\ed51"; }
.si-hugo.si--color::before { color: #FF4088; }
.si-hulu::before { content: "\ed52"; }
.si-hulu.si--color::before { color: #1CE783; }
.si-humblebundle::before { content: "\ed53"; }
.si-humblebundle.si--color::before { color: #CC2929; }
.si-hungryjacks::before { content: "\ed54"; }
.si-hungryjacks.si--color::before { color: #D0021B; }
.si-hurriyetemlak::before { content: "\ed55"; }
.si-hurriyetemlak.si--color::before { color: #E02826; }
.si-husqvarna::before { content: "\ed56"; }
.si-husqvarna.si--color::before { color: #273A60; }
.si-hyper::before { content: "\ed57"; }
.si-hyper.si--color::before { color: #000000; }
.si-hyperledger::before { content: "\ed58"; }
.si-hyperledger.si--color::before { color: #2F3134; }
.si-hypothesis::before { content: "\ed59"; }
.si-hypothesis.si--color::before { color: #BD1C2B; }
.si-hyundai::before { content: "\ed5a"; }
.si-hyundai.si--color::before { color: #002C5F; }
.si-i18next::before { content: "\ed5b"; }
.si-i18next.si--color::before { color: #26A69A; }
.si-iata::before { content: "\ed5c"; }
.si-iata.si--color::before { color: #004E81; }
.si-ibeacon::before { content: "\ed5d"; }
.si-ibeacon.si--color::before { color: #3D7EBB; }
.si-ibm::before { content: "\ed5e"; }
.si-ibm.si--color::before { color: #052FAD; }
.si-ibmwatson::before { content: "\ed5f"; }
.si-ibmwatson.si--color::before { color: #BE95FF; }
.si-iceland::before { content: "\ed60"; }
.si-iceland.si--color::before { color: #CC092F; }
.si-icinga::before { content: "\ed61"; }
.si-icinga.si--color::before { color: #06062C; }
.si-icloud::before { content: "\ed62"; }
.si-icloud.si--color::before { color: #3693F3; }
.si-icomoon::before { content: "\ed63"; }
.si-icomoon.si--color::before { color: #825794; }
.si-icon::before { content: "\ed64"; }
.si-icon.si--color::before { color: #31B8BB; }
.si-iconfinder::before { content: "\ed65"; }
.si-iconfinder.si--color::before { color: #1A1B1F; }
.si-iconify::before { content: "\ed66"; }
.si-iconify.si--color::before { color: #1769AA; }
.si-iconjar::before { content: "\ed67"; }
.si-iconjar.si--color::before { color: #16A5F3; }
.si-icons8::before { content: "\ed68"; }
.si-icons8.si--color::before { color: #1FB141; }
.si-icq::before { content: "\ed69"; }
.si-icq.si--color::before { color: #24FF00; }
.si-ieee::before { content: "\ed6a"; }
.si-ieee.si--color::before { color: #00629B; }
.si-ifixit::before { content: "\ed6b"; }
.si-ifixit.si--color::before { color: #0071CE; }
.si-ifood::before { content: "\ed6c"; }
.si-ifood.si--color::before { color: #EA1D2C; }
.si-ifttt::before { content: "\ed6d"; }
.si-ifttt.si--color::before { color: #000000; }
.si-iheartradio::before { content: "\ed6e"; }
.si-iheartradio.si--color::before { color: #C6002B; }
.si-ikea::before { content: "\ed6f"; }
.si-ikea.si--color::before { color: #0058A3; }
.si-imagej::before { content: "\ed70"; }
.si-imagej.si--color::before { color: #00D8E0; }
.si-imdb::before { content: "\ed71"; }
.si-imdb.si--color::before { color: #F5C518; }
.si-imgur::before { content: "\ed72"; }
.si-imgur.si--color::before { color: #1BB76E; }
.si-immer::before { content: "\ed73"; }
.si-immer.si--color::before { color: #00E7C3; }
.si-imou::before { content: "\ed74"; }
.si-imou.si--color::before { color: #E89313; }
.si-indeed::before { content: "\ed75"; }
.si-indeed.si--color::before { color: #003A9B; }
.si-infiniti::before { content: "\ed76"; }
.si-infiniti.si--color::before { color: #000000; }
.si-influxdb::before { content: "\ed77"; }
.si-influxdb.si--color::before { color: #22ADF6; }
.si-informatica::before { content: "\ed78"; }
.si-informatica.si--color::before { color: #FF4D00; }
.si-infosys::before { content: "\ed79"; }
.si-infosys.si--color::before { color: #007CC3; }
.si-ingress::before { content: "\ed7a"; }
.si-ingress.si--color::before { color: #783CBD; }
.si-inkscape::before { content: "\ed7b"; }
.si-inkscape.si--color::before { color: #000000; }
.si-insomnia::before { content: "\ed7c"; }
.si-insomnia.si--color::before { color: #5849BE; }
.si-instacart::before { content: "\ed7d"; }
.si-instacart.si--color::before { color: #43B02A; }
.si-instagram::before { content: "\ed7e"; }
.si-instagram.si--color::before { color: #E4405F; }
.si-instapaper::before { content: "\ed7f"; }
.si-instapaper.si--color::before { color: #1F1F1F; }
.si-instatus::before { content: "\ed80"; }
.si-instatus.si--color::before { color: #4EE3C2; }
.si-instructables::before { content: "\ed81"; }
.si-instructables.si--color::before { color: #FABF15; }
.si-integromat::before { content: "\ed82"; }
.si-integromat.si--color::before { color: #2F8CBB; }
.si-intel::before { content: "\ed83"; }
.si-intel.si--color::before { color: #0071C5; }
.si-intellijidea::before { content: "\ed84"; }
.si-intellijidea.si--color::before { color: #000000; }
.si-interactjs::before { content: "\ed85"; }
.si-interactjs.si--color::before { color: #2599ED; }
.si-intercom::before { content: "\ed86"; }
.si-intercom.si--color::before { color: #6AFDEF; }
.si-internetarchive::before { content: "\ed87"; }
.si-internetarchive.si--color::before { color: #666666; }
.si-internetexplorer::before { content: "\ed88"; }
.si-internetexplorer.si--color::before { color: #0076D6; }
.si-intigriti::before { content: "\ed89"; }
.si-intigriti.si--color::before { color: #161A36; }
.si-invision::before { content: "\ed8a"; }
.si-invision.si--color::before { color: #FF3366; }
.si-invoiceninja::before { content: "\ed8b"; }
.si-invoiceninja.si--color::before { color: #000000; }
.si-iobroker::before { content: "\ed8c"; }
.si-iobroker.si--color::before { color: #3399CC; }
.si-ionic::before { content: "\ed8d"; }
.si-ionic.si--color::before { color: #3880FF; }
.si-ios::before { content: "\ed8e"; }
.si-ios.si--color::before { color: #000000; }
.si-iota::before { content: "\ed8f"; }
.si-iota.si--color::before { color: #131F37; }
.si-ipfs::before { content: "\ed90"; }
.si-ipfs.si--color::before { color: #65C2CB; }
.si-issuu::before { content: "\ed91"; }
.si-issuu.si--color::before { color: #F36D5D; }
.si-istio::before { content: "\ed92"; }
.si-istio.si--color::before { color: #466BB0; }
.si-itchdotio::before { content: "\ed93"; }
.si-itchdotio.si--color::before { color: #FA5C5C; }
.si-iterm2::before { content: "\ed94"; }
.si-iterm2.si--color::before { color: #000000; }
.si-itunes::before { content: "\ed95"; }
.si-itunes.si--color::before { color: #FB5BC5; }
.si-iveco::before { content: "\ed96"; }
.si-iveco.si--color::before { color: #004994; }
.si-jabber::before { content: "\ed97"; }
.si-jabber.si--color::before { color: #CC0000; }
.si-jaguar::before { content: "\ed98"; }
.si-jaguar.si--color::before { color: #FFFFFF; }
.si-jamboard::before { content: "\ed99"; }
.si-jamboard.si--color::before { color: #F37C20; }
.si-jameson::before { content: "\ed9a"; }
.si-jameson.si--color::before { color: #004027; }
.si-jamstack::before { content: "\ed9b"; }
.si-jamstack.si--color::before { color: #F0047F; }
.si-jasmine::before { content: "\ed9c"; }
.si-jasmine.si--color::before { color: #8A4182; }
.si-java::before { content: "\ed9d"; }
.si-java.si--color::before { color: #007396; }
.si-javascript::before { content: "\ed9e"; }
.si-javascript.si--color::before { color: #F7DF1E; }
.si-jbl::before { content: "\ed9f"; }
.si-jbl.si--color::before { color: #FF3300; }
.si-jcb::before { content: "\eda0"; }
.si-jcb.si--color::before { color: #0B4EA2; }
.si-jeep::before { content: "\eda1"; }
.si-jeep.si--color::before { color: #000000; }
.si-jekyll::before { content: "\eda2"; }
.si-jekyll.si--color::before { color: #CC0000; }
.si-jellyfin::before { content: "\eda3"; }
.si-jellyfin.si--color::before { color: #00A4DC; }
.si-jenkins::before { content: "\eda4"; }
.si-jenkins.si--color::before { color: #D24939; }
.si-jenkinsx::before { content: "\eda5"; }
.si-jenkinsx.si--color::before { color: #73C3D5; }
.si-jest::before { content: "\eda6"; }
.si-jest.si--color::before { color: #C21325; }
.si-jet::before { content: "\eda7"; }
.si-jet.si--color::before { color: #FBBA00; }
.si-jetbrains::before { content: "\eda8"; }
.si-jetbrains.si--color::before { color: #000000; }
.si-jfrog::before { content: "\eda9"; }
.si-jfrog.si--color::before { color: #41BF47; }
.si-jfrogbintray::before { content: "\edaa"; }
.si-jfrogbintray.si--color::before { color: #43A047; }
.si-jinja::before { content: "\edab"; }
.si-jinja.si--color::before { color: #B41717; }
.si-jira::before { content: "\edac"; }
.si-jira.si--color::before { color: #0052CC; }
.si-jirasoftware::before { content: "\edad"; }
.si-jirasoftware.si--color::before { color: #0052CC; }
.si-jitsi::before { content: "\edae"; }
.si-jitsi.si--color::before { color: #97979A; }
.si-johndeere::before { content: "\edaf"; }
.si-johndeere.si--color::before { color: #367C2B; }
.si-joomla::before { content: "\edb0"; }
.si-joomla.si--color::before { color: #5091CD; }
.si-joplin::before { content: "\edb1"; }
.si-joplin.si--color::before { color: #1071D3; }
.si-jordan::before { content: "\edb2"; }
.si-jordan.si--color::before { color: #000000; }
.si-jpeg::before { content: "\edb3"; }
.si-jpeg.si--color::before { color: #8A8A8A; }
.si-jquery::before { content: "\edb4"; }
.si-jquery.si--color::before { color: #0769AD; }
.si-jrgroup::before { content: "\edb5"; }
.si-jrgroup.si--color::before { color: #000000; }
.si-jsdelivr::before { content: "\edb6"; }
.si-jsdelivr.si--color::before { color: #E84D3D; }
.si-jsfiddle::before { content: "\edb7"; }
.si-jsfiddle.si--color::before { color: #0084FF; }
.si-json::before { content: "\edb8"; }
.si-json.si--color::before { color: #000000; }
.si-jsonwebtokens::before { content: "\edb9"; }
.si-jsonwebtokens.si--color::before { color: #000000; }
.si-jss::before { content: "\edba"; }
.si-jss.si--color::before { color: #F7DF1E; }
.si-julia::before { content: "\edbb"; }
.si-julia.si--color::before { color: #9558B2; }
.si-junipernetworks::before { content: "\edbc"; }
.si-junipernetworks.si--color::before { color: #84B135; }
.si-junit5::before { content: "\edbd"; }
.si-junit5.si--color::before { color: #25A162; }
.si-jupyter::before { content: "\edbe"; }
.si-jupyter.si--color::before { color: #F37626; }
.si-justeat::before { content: "\edbf"; }
.si-justeat.si--color::before { color: #F36D00; }
.si-justgiving::before { content: "\edc0"; }
.si-justgiving.si--color::before { color: #AD29B6; }
.si-kaggle::before { content: "\edc1"; }
.si-kaggle.si--color::before { color: #20BEFF; }
.si-kahoot::before { content: "\edc2"; }
.si-kahoot.si--color::before { color: #46178F; }
.si-kaios::before { content: "\edc3"; }
.si-kaios.si--color::before { color: #6F02B5; }
.si-kakao::before { content: "\edc4"; }
.si-kakao.si--color::before { color: #FFCD00; }
.si-kakaotalk::before { content: "\edc5"; }
.si-kakaotalk.si--color::before { color: #FFCD00; }
.si-kalilinux::before { content: "\edc6"; }
.si-kalilinux.si--color::before { color: #557C94; }
.si-karlsruherverkehrsverbund::before { content: "\edc7"; }
.si-karlsruherverkehrsverbund.si--color::before { color: #9B2321; }
.si-kasasmart::before { content: "\edc8"; }
.si-kasasmart.si--color::before { color: #4ACBD6; }
.si-kashflow::before { content: "\edc9"; }
.si-kashflow.si--color::before { color: #E5426E; }
.si-kaspersky::before { content: "\edca"; }
.si-kaspersky.si--color::before { color: #006D5C; }
.si-katacoda::before { content: "\edcb"; }
.si-katacoda.si--color::before { color: #F48220; }
.si-katana::before { content: "\edcc"; }
.si-katana.si--color::before { color: #000000; }
.si-kaufland::before { content: "\edcd"; }
.si-kaufland.si--color::before { color: #E10915; }
.si-kde::before { content: "\edce"; }
.si-kde.si--color::before { color: #1D99F3; }
.si-kdenlive::before { content: "\edcf"; }
.si-kdenlive.si--color::before { color: #527EB2; }
.si-keepassxc::before { content: "\edd0"; }
.si-keepassxc.si--color::before { color: #6CAC4D; }
.si-kentico::before { content: "\edd1"; }
.si-kentico.si--color::before { color: #F05A22; }
.si-keras::before { content: "\edd2"; }
.si-keras.si--color::before { color: #D00000; }
.si-keybase::before { content: "\edd3"; }
.si-keybase.si--color::before { color: #33A0FF; }
.si-keycdn::before { content: "\edd4"; }
.si-keycdn.si--color::before { color: #047AED; }
.si-kfc::before { content: "\edd5"; }
.si-kfc.si--color::before { color: #F40027; }
.si-khanacademy::before { content: "\edd6"; }
.si-khanacademy.si--color::before { color: #14BF96; }
.si-khronosgroup::before { content: "\edd7"; }
.si-khronosgroup.si--color::before { color: #CC3333; }
.si-kia::before { content: "\edd8"; }
.si-kia.si--color::before { color: #05141F; }
.si-kibana::before { content: "\edd9"; }
.si-kibana.si--color::before { color: #005571; }
.si-kickstarter::before { content: "\edda"; }
.si-kickstarter.si--color::before { color: #05CE78; }
.si-kik::before { content: "\eddb"; }
.si-kik.si--color::before { color: #82BC23; }
.si-kinopoisk::before { content: "\eddc"; }
.si-kinopoisk.si--color::before { color: #FF6600; }
.si-kirby::before { content: "\eddd"; }
.si-kirby.si--color::before { color: #000000; }
.si-kitsu::before { content: "\edde"; }
.si-kitsu.si--color::before { color: #FD755C; }
.si-klarna::before { content: "\eddf"; }
.si-klarna.si--color::before { color: #FFB3C7; }
.si-klm::before { content: "\ede0"; }
.si-klm.si--color::before { color: #00A1DE; }
.si-klook::before { content: "\ede1"; }
.si-klook.si--color::before { color: #FF5722; }
.si-knowledgebase::before { content: "\ede2"; }
.si-knowledgebase.si--color::before { color: #FFD000; }
.si-known::before { content: "\ede3"; }
.si-known.si--color::before { color: #333333; }
.si-kofi::before { content: "\ede4"; }
.si-kofi.si--color::before { color: #FF5E5B; }
.si-koa::before { content: "\ede5"; }
.si-koa.si--color::before { color: #33333D; }
.si-koc::before { content: "\ede6"; }
.si-koc.si--color::before { color: #F9423A; }
.si-kodi::before { content: "\ede7"; }
.si-kodi.si--color::before { color: #17B2E7; }
.si-koding::before { content: "\ede8"; }
.si-koding.si--color::before { color: #00B057; }
.si-kofax::before { content: "\ede9"; }
.si-kofax.si--color::before { color: #00558C; }
.si-komoot::before { content: "\edea"; }
.si-komoot.si--color::before { color: #6AA127; }
.si-kongregate::before { content: "\edeb"; }
.si-kongregate.si--color::before { color: #990000; }
.si-konva::before { content: "\edec"; }
.si-konva.si--color::before { color: #0D83CD; }
.si-kotlin::before { content: "\eded"; }
.si-kotlin.si--color::before { color: #7F52FF; }
.si-krita::before { content: "\edee"; }
.si-krita.si--color::before { color: #3BABFF; }
.si-ktm::before { content: "\edef"; }
.si-ktm.si--color::before { color: #FF6600; }
.si-kuaishou::before { content: "\edf0"; }
.si-kuaishou.si--color::before { color: #FF4906; }
.si-kubernetes::before { content: "\edf1"; }
.si-kubernetes.si--color::before { color: #326CE5; }
.si-kubuntu::before { content: "\edf2"; }
.si-kubuntu.si--color::before { color: #0079C1; }
.si-kyocera::before { content: "\edf3"; }
.si-kyocera.si--color::before { color: #DF0522; }
.si-labview::before { content: "\edf4"; }
.si-labview.si--color::before { color: #FFDB00; }
.si-lada::before { content: "\edf5"; }
.si-lada.si--color::before { color: #ED6B21; }
.si-lamborghini::before { content: "\edf6"; }
.si-lamborghini.si--color::before { color: #DDB320; }
.si-landrover::before { content: "\edf7"; }
.si-landrover.si--color::before { color: #005A2B; }
.si-laragon::before { content: "\edf8"; }
.si-laragon.si--color::before { color: #0E83CD; }
.si-laravel::before { content: "\edf9"; }
.si-laravel.si--color::before { color: #FF2D20; }
.si-laravelhorizon::before { content: "\edfa"; }
.si-laravelhorizon.si--color::before { color: #405263; }
.si-laravelnova::before { content: "\edfb"; }
.si-laravelnova.si--color::before { color: #252D37; }
.si-lastdotfm::before { content: "\edfc"; }
.si-lastdotfm.si--color::before { color: #D51007; }
.si-lastpass::before { content: "\edfd"; }
.si-lastpass.si--color::before { color: #D32D27; }
.si-latex::before { content: "\edfe"; }
.si-latex.si--color::before { color: #008080; }
.si-launchpad::before { content: "\edff"; }
.si-launchpad.si--color::before { color: #F8C300; }
.si-lbry::before { content: "\ee00"; }
.si-lbry.si--color::before { color: #2F9176; }
.si-leaflet::before { content: "\ee01"; }
.si-leaflet.si--color::before { color: #199900; }
.si-leanpub::before { content: "\ee02"; }
.si-leanpub.si--color::before { color: #FFFFFF; }
.si-leetcode::before { content: "\ee03"; }
.si-leetcode.si--color::before { color: #FFA116; }
.si-lemmy::before { content: "\ee04"; }
.si-lemmy.si--color::before { color: #FFFFFF; }
.si-lenovo::before { content: "\ee05"; }
.si-lenovo.si--color::before { color: #E2231A; }
.si-less::before { content: "\ee06"; }
.si-less.si--color::before { color: #1D365D; }
.si-letsencrypt::before { content: "\ee07"; }
.si-letsencrypt.si--color::before { color: #003A70; }
.si-letterboxd::before { content: "\ee08"; }
.si-letterboxd.si--color::before { color: #00D735; }
.si-lg::before { content: "\ee09"; }
.si-lg.si--color::before { color: #A50034; }
.si-lgtm::before { content: "\ee0a"; }
.si-lgtm.si--color::before { color: #FFFFFF; }
.si-liberapay::before { content: "\ee0b"; }
.si-liberapay.si--color::before { color: #F6C915; }
.si-librariesdotio::before { content: "\ee0c"; }
.si-librariesdotio.si--color::before { color: #337AB7; }
.si-librarything::before { content: "\ee0d"; }
.si-librarything.si--color::before { color: #251A15; }
.si-libreoffice::before { content: "\ee0e"; }
.si-libreoffice.si--color::before { color: #18A303; }
.si-libuv::before { content: "\ee0f"; }
.si-libuv.si--color::before { color: #403C3D; }
.si-lichess::before { content: "\ee10"; }
.si-lichess.si--color::before { color: #000000; }
.si-lidl::before { content: "\ee11"; }
.si-lidl.si--color::before { color: #0050AA; }
.si-lifx::before { content: "\ee12"; }
.si-lifx.si--color::before { color: #000000; }
.si-lighthouse::before { content: "\ee13"; }
.si-lighthouse.si--color::before { color: #F44B21; }
.si-line::before { content: "\ee14"; }
.si-line.si--color::before { color: #00C300; }
.si-lineageos::before { content: "\ee15"; }
.si-lineageos.si--color::before { color: #167C80; }
.si-linkedin::before { content: "\ee16"; }
.si-linkedin.si--color::before { color: #0A66C2; }
.si-linktree::before { content: "\ee17"; }
.si-linktree.si--color::before { color: #39E09B; }
.si-linode::before { content: "\ee18"; }
.si-linode.si--color::before { color: #00A95C; }
.si-linux::before { content: "\ee19"; }
.si-linux.si--color::before { color: #FCC624; }
.si-linuxcontainers::before { content: "\ee1a"; }
.si-linuxcontainers.si--color::before { color: #333333; }
.si-linuxfoundation::before { content: "\ee1b"; }
.si-linuxfoundation.si--color::before { color: #003366; }
.si-linuxmint::before { content: "\ee1c"; }
.si-linuxmint.si--color::before { color: #87CF3E; }
.si-lionair::before { content: "\ee1d"; }
.si-lionair.si--color::before { color: #ED3237; }
.si-lit::before { content: "\ee1e"; }
.si-lit.si--color::before { color: #324FFF; }
.si-litecoin::before { content: "\ee1f"; }
.si-litecoin.si--color::before { color: #A6A9AA; }
.si-livechat::before { content: "\ee20"; }
.si-livechat.si--color::before { color: #FFD000; }
.si-livejournal::before { content: "\ee21"; }
.si-livejournal.si--color::before { color: #00B0EA; }
.si-livewire::before { content: "\ee22"; }
.si-livewire.si--color::before { color: #4E56A6; }
.si-llvm::before { content: "\ee23"; }
.si-llvm.si--color::before { color: #262D3A; }
.si-lmms::before { content: "\ee24"; }
.si-lmms.si--color::before { color: #10B146; }
.si-lodash::before { content: "\ee25"; }
.si-lodash.si--color::before { color: #3492FF; }
.si-logitech::before { content: "\ee26"; }
.si-logitech.si--color::before { color: #00B8FC; }
.si-logmein::before { content: "\ee27"; }
.si-logmein.si--color::before { color: #45B6F2; }
.si-logstash::before { content: "\ee28"; }
.si-logstash.si--color::before { color: #005571; }
.si-looker::before { content: "\ee29"; }
.si-looker.si--color::before { color: #4285F4; }
.si-loom::before { content: "\ee2a"; }
.si-loom.si--color::before { color: #625DF5; }
.si-loop::before { content: "\ee2b"; }
.si-loop.si--color::before { color: #F29400; }
.si-lospec::before { content: "\ee2c"; }
.si-lospec.si--color::before { color: #EAEAEA; }
.si-lotpolishairlines::before { content: "\ee2d"; }
.si-lotpolishairlines.si--color::before { color: #11397E; }
.si-lua::before { content: "\ee2e"; }
.si-lua.si--color::before { color: #2C2D72; }
.si-lubuntu::before { content: "\ee2f"; }
.si-lubuntu.si--color::before { color: #0068C8; }
.si-lufthansa::before { content: "\ee30"; }
.si-lufthansa.si--color::before { color: #05164D; }
.si-lumen::before { content: "\ee31"; }
.si-lumen.si--color::before { color: #E74430; }
.si-lydia::before { content: "\ee32"; }
.si-lydia.si--color::before { color: #0180FF; }
.si-lyft::before { content: "\ee33"; }
.si-lyft.si--color::before { color: #FF00BF; }
.si-maas::before { content: "\ee34"; }
.si-maas.si--color::before { color: #E95420; }
.si-macos::before { content: "\ee35"; }
.si-macos.si--color::before { color: #000000; }
.si-macys::before { content: "\ee36"; }
.si-macys.si--color::before { color: #E21A2C; }
.si-magento::before { content: "\ee37"; }
.si-magento.si--color::before { color: #EE672F; }
.si-magisk::before { content: "\ee38"; }
.si-magisk.si--color::before { color: #00AF9C; }
.si-maildotru::before { content: "\ee39"; }
.si-maildotru.si--color::before { color: #005FF9; }
.si-mailchimp::before { content: "\ee3a"; }
.si-mailchimp.si--color::before { color: #FFE01B; }
.si-majorleaguehacking::before { content: "\ee3b"; }
.si-majorleaguehacking.si--color::before { color: #265A8F; }
.si-makerbot::before { content: "\ee3c"; }
.si-makerbot.si--color::before { color: #FF1E0D; }
.si-mamp::before { content: "\ee3d"; }
.si-mamp.si--color::before { color: #02749C; }
.si-man::before { content: "\ee3e"; }
.si-man.si--color::before { color: #E40045; }
.si-manageiq::before { content: "\ee3f"; }
.si-manageiq.si--color::before { color: #EF2929; }
.si-manjaro::before { content: "\ee40"; }
.si-manjaro.si--color::before { color: #35BF5C; }
.si-mapbox::before { content: "\ee41"; }
.si-mapbox.si--color::before { color: #000000; }
.si-mariadb::before { content: "\ee42"; }
.si-mariadb.si--color::before { color: #003545; }
.si-mariadbfoundation::before { content: "\ee43"; }
.si-mariadbfoundation.si--color::before { color: #1F305F; }
.si-markdown::before { content: "\ee44"; }
.si-markdown.si--color::before { color: #000000; }
.si-marketo::before { content: "\ee45"; }
.si-marketo.si--color::before { color: #5C4C9F; }
.si-marriott::before { content: "\ee46"; }
.si-marriott.si--color::before { color: #A70023; }
.si-maserati::before { content: "\ee47"; }
.si-maserati.si--color::before { color: #0C2340; }
.si-mastercard::before { content: "\ee48"; }
.si-mastercard.si--color::before { color: #EB001B; }
.si-mastercomfig::before { content: "\ee49"; }
.si-mastercomfig.si--color::before { color: #009688; }
.si-mastodon::before { content: "\ee4a"; }
.si-mastodon.si--color::before { color: #3088D4; }
.si-materialdesign::before { content: "\ee4b"; }
.si-materialdesign.si--color::before { color: #757575; }
.si-materialdesignicons::before { content: "\ee4c"; }
.si-materialdesignicons.si--color::before { color: #2196F3; }
.si-materialui::before { content: "\ee4d"; }
.si-materialui.si--color::before { color: #0081CB; }
.si-matomo::before { content: "\ee4e"; }
.si-matomo.si--color::before { color: #3152A0; }
.si-matrix::before { content: "\ee4f"; }
.si-matrix.si--color::before { color: #000000; }
.si-mattermost::before { content: "\ee50"; }
.si-mattermost.si--color::before { color: #0058CC; }
.si-matternet::before { content: "\ee51"; }
.si-matternet.si--color::before { color: #261C29; }
.si-max::before { content: "\ee52"; }
.si-max.si--color::before { color: #525252; }
.si-maxplanckgesellschaft::before { content: "\ee53"; }
.si-maxplanckgesellschaft.si--color::before { color: #006C66; }
.si-maytag::before { content: "\ee54"; }
.si-maytag.si--color::before { color: #002E5F; }
.si-mazda::before { content: "\ee55"; }
.si-mazda.si--color::before { color: #101010; }
.si-mcafee::before { content: "\ee56"; }
.si-mcafee.si--color::before { color: #C01818; }
.si-mcdonalds::before { content: "\ee57"; }
.si-mcdonalds.si--color::before { color: #FBC817; }
.si-mclaren::before { content: "\ee58"; }
.si-mclaren.si--color::before { color: #FF0000; }
.si-mdnwebdocs::before { content: "\ee59"; }
.si-mdnwebdocs.si--color::before { color: #000000; }
.si-mediafire::before { content: "\ee5a"; }
.si-mediafire.si--color::before { color: #1299F3; }
.si-mediamarkt::before { content: "\ee5b"; }
.si-mediamarkt.si--color::before { color: #DF0000; }
.si-mediatek::before { content: "\ee5c"; }
.si-mediatek.si--color::before { color: #EC9430; }
.si-mediatemple::before { content: "\ee5d"; }
.si-mediatemple.si--color::before { color: #000000; }
.si-medium::before { content: "\ee5e"; }
.si-medium.si--color::before { color: #000000; }
.si-meetup::before { content: "\ee5f"; }
.si-meetup.si--color::before { color: #ED1C40; }
.si-mega::before { content: "\ee60"; }
.si-mega.si--color::before { color: #D9272E; }
.si-mendeley::before { content: "\ee61"; }
.si-mendeley.si--color::before { color: #9D1620; }
.si-mercedes::before { content: "\ee62"; }
.si-mercedes.si--color::before { color: #242424; }
.si-merck::before { content: "\ee63"; }
.si-merck.si--color::before { color: #007A73; }
.si-mercurial::before { content: "\ee64"; }
.si-mercurial.si--color::before { color: #999999; }
.si-messenger::before { content: "\ee65"; }
.si-messenger.si--color::before { color: #00B2FF; }
.si-metabase::before { content: "\ee66"; }
.si-metabase.si--color::before { color: #509EE3; }
.si-metafilter::before { content: "\ee67"; }
.si-metafilter.si--color::before { color: #065A8F; }
.si-meteor::before { content: "\ee68"; }
.si-meteor.si--color::before { color: #DE4F4F; }
.si-metro::before { content: "\ee69"; }
.si-metro.si--color::before { color: #EF4242; }
.si-metrodelaciudaddemexico::before { content: "\ee6a"; }
.si-metrodelaciudaddemexico.si--color::before { color: #F77E1C; }
.si-metrodemadrid::before { content: "\ee6b"; }
.si-metrodemadrid.si--color::before { color: #255E9C; }
.si-metrodeparis::before { content: "\ee6c"; }
.si-metrodeparis.si--color::before { color: #003E95; }
.si-mewe::before { content: "\ee6d"; }
.si-mewe.si--color::before { color: #17377F; }
.si-microbit::before { content: "\ee6e"; }
.si-microbit.si--color::before { color: #00ED00; }
.si-microdotblog::before { content: "\ee6f"; }
.si-microdotblog.si--color::before { color: #FF8800; }
.si-microgenetics::before { content: "\ee70"; }
.si-microgenetics.si--color::before { color: #FF0000; }
.si-micropython::before { content: "\ee71"; }
.si-micropython.si--color::before { color: #2B2728; }
.si-microsoft::before { content: "\ee72"; }
.si-microsoft.si--color::before { color: #5E5E5E; }
.si-microsoftacademic::before { content: "\ee73"; }
.si-microsoftacademic.si--color::before { color: #2D9FD9; }
.si-microsoftaccess::before { content: "\ee74"; }
.si-microsoftaccess.si--color::before { color: #A4373A; }
.si-microsoftazure::before { content: "\ee75"; }
.si-microsoftazure.si--color::before { color: #0078D4; }
.si-microsoftbing::before { content: "\ee76"; }
.si-microsoftbing.si--color::before { color: #258FFA; }
.si-microsoftedge::before { content: "\ee77"; }
.si-microsoftedge.si--color::before { color: #0078D7; }
.si-microsoftexcel::before { content: "\ee78"; }
.si-microsoftexcel.si--color::before { color: #217346; }
.si-microsoftexchange::before { content: "\ee79"; }
.si-microsoftexchange.si--color::before { color: #0078D4; }
.si-microsoftoffice::before { content: "\ee7a"; }
.si-microsoftoffice.si--color::before { color: #D83B01; }
.si-microsoftonedrive::before { content: "\ee7b"; }
.si-microsoftonedrive.si--color::before { color: #0078D4; }
.si-microsoftonenote::before { content: "\ee7c"; }
.si-microsoftonenote.si--color::before { color: #7719AA; }
.si-microsoftoutlook::before { content: "\ee7d"; }
.si-microsoftoutlook.si--color::before { color: #0078D4; }
.si-microsoftpowerpoint::before { content: "\ee7e"; }
.si-microsoftpowerpoint.si--color::before { color: #B7472A; }
.si-microsoftsharepoint::before { content: "\ee7f"; }
.si-microsoftsharepoint.si--color::before { color: #0078D4; }
.si-microsoftsqlserver::before { content: "\ee80"; }
.si-microsoftsqlserver.si--color::before { color: #CC2927; }
.si-microsoftteams::before { content: "\ee81"; }
.si-microsoftteams.si--color::before { color: #6264A7; }
.si-microsoftvisio::before { content: "\ee82"; }
.si-microsoftvisio.si--color::before { color: #3955A3; }
.si-microsoftword::before { content: "\ee83"; }
.si-microsoftword.si--color::before { color: #2B579A; }
.si-microstrategy::before { content: "\ee84"; }
.si-microstrategy.si--color::before { color: #D9232E; }
.si-midi::before { content: "\ee85"; }
.si-midi.si--color::before { color: #000000; }
.si-minds::before { content: "\ee86"; }
.si-minds.si--color::before { color: #FED12F; }
.si-minecraft::before { content: "\ee87"; }
.si-minecraft.si--color::before { color: #62B47A; }
.si-minetest::before { content: "\ee88"; }
.si-minetest.si--color::before { color: #53AC56; }
.si-mini::before { content: "\ee89"; }
.si-mini.si--color::before { color: #000000; }
.si-minutemailer::before { content: "\ee8a"; }
.si-minutemailer.si--color::before { color: #30B980; }
.si-miro::before { content: "\ee8b"; }
.si-miro.si--color::before { color: #050038; }
.si-mitsubishi::before { content: "\ee8c"; }
.si-mitsubishi.si--color::before { color: #E60012; }
.si-mix::before { content: "\ee8d"; }
.si-mix.si--color::before { color: #FF8126; }
.si-mixcloud::before { content: "\ee8e"; }
.si-mixcloud.si--color::before { color: #5000FF; }
.si-mlb::before { content: "\ee8f"; }
.si-mlb.si--color::before { color: #041E42; }
.si-mobx::before { content: "\ee90"; }
.si-mobx.si--color::before { color: #FF9955; }
.si-mobxstatetree::before { content: "\ee91"; }
.si-mobxstatetree.si--color::before { color: #FF7102; }
.si-mocha::before { content: "\ee92"; }
.si-mocha.si--color::before { color: #8D6748; }
.si-modx::before { content: "\ee93"; }
.si-modx.si--color::before { color: #102C53; }
.si-mojangstudios::before { content: "\ee94"; }
.si-mojangstudios.si--color::before { color: #EF323D; }
.si-moleculer::before { content: "\ee95"; }
.si-moleculer.si--color::before { color: #3CAFCE; }
.si-momenteo::before { content: "\ee96"; }
.si-momenteo.si--color::before { color: #5A6AB1; }
.si-monero::before { content: "\ee97"; }
.si-monero.si--color::before { color: #FF6600; }
.si-mongodb::before { content: "\ee98"; }
.si-mongodb.si--color::before { color: #47A248; }
.si-monkeytie::before { content: "\ee99"; }
.si-monkeytie.si--color::before { color: #1A52C2; }
.si-monster::before { content: "\ee9a"; }
.si-monster.si--color::before { color: #6D4C9F; }
.si-monzo::before { content: "\ee9b"; }
.si-monzo.si--color::before { color: #14233C; }
.si-moo::before { content: "\ee9c"; }
.si-moo.si--color::before { color: #00945E; }
.si-moscowmetro::before { content: "\ee9d"; }
.si-moscowmetro.si--color::before { color: #D9232E; }
.si-motorola::before { content: "\ee9e"; }
.si-motorola.si--color::before { color: #E1140A; }
.si-mozilla::before { content: "\ee9f"; }
.si-mozilla.si--color::before { color: #000000; }
.si-msi::before { content: "\eea0"; }
.si-msi.si--color::before { color: #FF0000; }
.si-mta::before { content: "\eea1"; }
.si-mta.si--color::before { color: #0039A6; }
.si-mtr::before { content: "\eea2"; }
.si-mtr.si--color::before { color: #AC2E45; }
.si-mulesoft::before { content: "\eea3"; }
.si-mulesoft.si--color::before { color: #00A0DF; }
.si-mumble::before { content: "\eea4"; }
.si-mumble.si--color::before { color: #FFFFFF; }
.si-musescore::before { content: "\eea5"; }
.si-musescore.si--color::before { color: #1A70B8; }
.si-musicbrainz::before { content: "\eea6"; }
.si-musicbrainz.si--color::before { color: #BA478F; }
.si-mxlinux::before { content: "\eea7"; }
.si-mxlinux.si--color::before { color: #000000; }
.si-myanimelist::before { content: "\eea8"; }
.si-myanimelist.si--color::before { color: #2E51A2; }
.si-myob::before { content: "\eea9"; }
.si-myob.si--color::before { color: #6100A5; }
.si-myspace::before { content: "\eeaa"; }
.si-myspace.si--color::before { color: #030303; }
.si-mysql::before { content: "\eeab"; }
.si-mysql.si--color::before { color: #4479A1; }
.si-n26::before { content: "\eeac"; }
.si-n26.si--color::before { color: #48AC98; }
.si-namebase::before { content: "\eead"; }
.si-namebase.si--color::before { color: #0068FF; }
.si-namecheap::before { content: "\eeae"; }
.si-namecheap.si--color::before { color: #DE3723; }
.si-nano::before { content: "\eeaf"; }
.si-nano.si--color::before { color: #4A90E2; }
.si-nasa::before { content: "\eeb0"; }
.si-nasa.si--color::before { color: #E03C31; }
.si-nationalgrid::before { content: "\eeb1"; }
.si-nationalgrid.si--color::before { color: #00148C; }
.si-nativescript::before { content: "\eeb2"; }
.si-nativescript.si--color::before { color: #3655FF; }
.si-naver::before { content: "\eeb3"; }
.si-naver.si--color::before { color: #03C75A; }
.si-nba::before { content: "\eeb4"; }
.si-nba.si--color::before { color: #253B73; }
.si-nbb::before { content: "\eeb5"; }
.si-nbb.si--color::before { color: #FF7100; }
.si-ndr::before { content: "\eeb6"; }
.si-ndr.si--color::before { color: #0C1754; }
.si-nec::before { content: "\eeb7"; }
.si-nec.si--color::before { color: #1414A0; }
.si-neo4j::before { content: "\eeb8"; }
.si-neo4j.si--color::before { color: #008CC1; }
.si-neovim::before { content: "\eeb9"; }
.si-neovim.si--color::before { color: #57A143; }
.si-nestjs::before { content: "\eeba"; }
.si-nestjs.si--color::before { color: #E0234E; }
.si-netapp::before { content: "\eebb"; }
.si-netapp.si--color::before { color: #0067C5; }
.si-netflix::before { content: "\eebc"; }
.si-netflix.si--color::before { color: #E50914; }
.si-netlify::before { content: "\eebd"; }
.si-netlify.si--color::before { color: #00C7B7; }
.si-nette::before { content: "\eebe"; }
.si-nette.si--color::before { color: #3484D2; }
.si-netto::before { content: "\eebf"; }
.si-netto.si--color::before { color: #FFE500; }
.si-newbalance::before { content: "\eec0"; }
.si-newbalance.si--color::before { color: #CF0A2C; }
.si-newjapanprowrestling::before { content: "\eec1"; }
.si-newjapanprowrestling.si--color::before { color: #FF160B; }
.si-newrelic::before { content: "\eec2"; }
.si-newrelic.si--color::before { color: #008C99; }
.si-newyorktimes::before { content: "\eec3"; }
.si-newyorktimes.si--color::before { color: #000000; }
.si-nextdotjs::before { content: "\eec4"; }
.si-nextdotjs.si--color::before { color: #000000; }
.si-nextcloud::before { content: "\eec5"; }
.si-nextcloud.si--color::before { color: #0082C9; }
.si-nextdoor::before { content: "\eec6"; }
.si-nextdoor.si--color::before { color: #8ED500; }
.si-nfc::before { content: "\eec7"; }
.si-nfc.si--color::before { color: #002E5F; }
.si-nginx::before { content: "\eec8"; }
.si-nginx.si--color::before { color: #009639; }
.si-ngrok::before { content: "\eec9"; }
.si-ngrok.si--color::before { color: #1F1E37; }
.si-niconico::before { content: "\eeca"; }
.si-niconico.si--color::before { color: #231815; }
.si-nike::before { content: "\eecb"; }
.si-nike.si--color::before { color: #111111; }
.si-nim::before { content: "\eecc"; }
.si-nim.si--color::before { color: #FFE953; }
.si-nintendo::before { content: "\eecd"; }
.si-nintendo.si--color::before { color: #8F8F8F; }
.si-nintendo3ds::before { content: "\eece"; }
.si-nintendo3ds.si--color::before { color: #D12228; }
.si-nintendogamecube::before { content: "\eecf"; }
.si-nintendogamecube.si--color::before { color: #6A5FBB; }
.si-nintendonetwork::before { content: "\eed0"; }
.si-nintendonetwork.si--color::before { color: #FF7D00; }
.si-nintendoswitch::before { content: "\eed1"; }
.si-nintendoswitch.si--color::before { color: #E60012; }
.si-nissan::before { content: "\eed2"; }
.si-nissan.si--color::before { color: #C3002F; }
.si-nixos::before { content: "\eed3"; }
.si-nixos.si--color::before { color: #5277C3; }
.si-nodered::before { content: "\eed4"; }
.si-nodered.si--color::before { color: #8F0000; }
.si-nodedotjs::before { content: "\eed5"; }
.si-nodedotjs.si--color::before { color: #339933; }
.si-nodemon::before { content: "\eed6"; }
.si-nodemon.si--color::before { color: #76D04B; }
.si-nokia::before { content: "\eed7"; }
.si-nokia.si--color::before { color: #124191; }
.si-nordvpn::before { content: "\eed8"; }
.si-nordvpn.si--color::before { color: #4687FF; }
.si-norwegian::before { content: "\eed9"; }
.si-norwegian.si--color::before { color: #D81939; }
.si-notepadplusplus::before { content: "\eeda"; }
.si-notepadplusplus.si--color::before { color: #90E59A; }
.si-notion::before { content: "\eedb"; }
.si-notion.si--color::before { color: #000000; }
.si-notist::before { content: "\eedc"; }
.si-notist.si--color::before { color: #333333; }
.si-nounproject::before { content: "\eedd"; }
.si-nounproject.si--color::before { color: #000000; }
.si-now::before { content: "\eede"; }
.si-now.si--color::before { color: #001211; }
.si-npm::before { content: "\eedf"; }
.si-npm.si--color::before { color: #CB3837; }
.si-nrwl::before { content: "\eee0"; }
.si-nrwl.si--color::before { color: #96D7E8; }
.si-nubank::before { content: "\eee1"; }
.si-nubank.si--color::before { color: #820AD1; }
.si-nucleo::before { content: "\eee2"; }
.si-nucleo.si--color::before { color: #252B2D; }
.si-nuget::before { content: "\eee3"; }
.si-nuget.si--color::before { color: #004880; }
.si-nuke::before { content: "\eee4"; }
.si-nuke.si--color::before { color: #000000; }
.si-numba::before { content: "\eee5"; }
.si-numba.si--color::before { color: #00A3E0; }
.si-numpy::before { content: "\eee6"; }
.si-numpy.si--color::before { color: #013243; }
.si-nutanix::before { content: "\eee7"; }
.si-nutanix.si--color::before { color: #024DA1; }
.si-nuxtdotjs::before { content: "\eee8"; }
.si-nuxtdotjs.si--color::before { color: #00DC82; }
.si-nvidia::before { content: "\eee9"; }
.si-nvidia.si--color::before { color: #76B900; }
.si-nx::before { content: "\eeea"; }
.si-nx.si--color::before { color: #143055; }
.si-nzxt::before { content: "\eeeb"; }
.si-nzxt.si--color::before { color: #000000; }
.si-oreilly::before { content: "\eeec"; }
.si-oreilly.si--color::before { color: #D3002D; }
.si-obsstudio::before { content: "\eeed"; }
.si-obsstudio.si--color::before { color: #302E31; }
.si-observable::before { content: "\eeee"; }
.si-observable.si--color::before { color: #353E58; }
.si-obsidian::before { content: "\eeef"; }
.si-obsidian.si--color::before { color: #483699; }
.si-ocaml::before { content: "\eef0"; }
.si-ocaml.si--color::before { color: #EC6813; }
.si-octave::before { content: "\eef1"; }
.si-octave.si--color::before { color: #0790C0; }
.si-octopusdeploy::before { content: "\eef2"; }
.si-octopusdeploy.si--color::before { color: #2F93E0; }
.si-oculus::before { content: "\eef3"; }
.si-oculus.si--color::before { color: #1C1E20; }
.si-odnoklassniki::before { content: "\eef4"; }
.si-odnoklassniki.si--color::before { color: #EE8208; }
.si-odysee::before { content: "\eef5"; }
.si-odysee.si--color::before { color: #EF1970; }
.si-ohdear::before { content: "\eef6"; }
.si-ohdear.si--color::before { color: #FFFFFF; }
.si-okcupid::before { content: "\eef7"; }
.si-okcupid.si--color::before { color: #0500BE; }
.si-okta::before { content: "\eef8"; }
.si-okta.si--color::before { color: #007DC1; }
.si-oneplus::before { content: "\eef9"; }
.si-oneplus.si--color::before { color: #F5010C; }
.si-onlyfans::before { content: "\eefa"; }
.si-onlyfans.si--color::before { color: #00AFF0; }
.si-onnx::before { content: "\eefb"; }
.si-onnx.si--color::before { color: #005CED; }
.si-onstar::before { content: "\eefc"; }
.si-onstar.si--color::before { color: #003D7D; }
.si-opel::before { content: "\eefd"; }
.si-opel.si--color::before { color: #F7FF14; }
.si-openaccess::before { content: "\eefe"; }
.si-openaccess.si--color::before { color: #F68212; }
.si-openbadges::before { content: "\eeff"; }
.si-openbadges.si--color::before { color: #073B5A; }
.si-openbugbounty::before { content: "\ef00"; }
.si-openbugbounty.si--color::before { color: #F67909; }
.si-opencollective::before { content: "\ef01"; }
.si-opencollective.si--color::before { color: #7FADF2; }
.si-opencontainersinitiative::before { content: "\ef02"; }
.si-opencontainersinitiative.si--color::before { color: #262261; }
.si-opensourceinitiative::before { content: "\ef03"; }
.si-opensourceinitiative.si--color::before { color: #3DA639; }
.si-openai::before { content: "\ef04"; }
.si-openai.si--color::before { color: #412991; }
.si-openaigym::before { content: "\ef05"; }
.si-openaigym.si--color::before { color: #0081A5; }
.si-openapiinitiative::before { content: "\ef06"; }
.si-openapiinitiative.si--color::before { color: #6BA539; }
.si-openbsd::before { content: "\ef07"; }
.si-openbsd.si--color::before { color: #F2CA30; }
.si-opencv::before { content: "\ef08"; }
.si-opencv.si--color::before { color: #5C3EE8; }
.si-openfaas::before { content: "\ef09"; }
.si-openfaas.si--color::before { color: #3B5EE9; }
.si-opengl::before { content: "\ef0a"; }
.si-opengl.si--color::before { color: #5586A4; }
.si-openid::before { content: "\ef0b"; }
.si-openid.si--color::before { color: #F78C40; }
.si-openlayers::before { content: "\ef0c"; }
.si-openlayers.si--color::before { color: #1F6B75; }
.si-openmined::before { content: "\ef0d"; }
.si-openmined.si--color::before { color: #ED986C; }
.si-opennebula::before { content: "\ef0e"; }
.si-opennebula.si--color::before { color: #0097C2; }
.si-openssl::before { content: "\ef0f"; }
.si-openssl.si--color::before { color: #721412; }
.si-openstack::before { content: "\ef10"; }
.si-openstack.si--color::before { color: #ED1944; }
.si-openstreetmap::before { content: "\ef11"; }
.si-openstreetmap.si--color::before { color: #7EBC6F; }
.si-opensuse::before { content: "\ef12"; }
.si-opensuse.si--color::before { color: #73BA25; }
.si-openvpn::before { content: "\ef13"; }
.si-openvpn.si--color::before { color: #EA7E20; }
.si-openwrt::before { content: "\ef14"; }
.si-openwrt.si--color::before { color: #00B5E2; }
.si-opera::before { content: "\ef15"; }
.si-opera.si--color::before { color: #FF1B2D; }
.si-opnsense::before { content: "\ef16"; }
.si-opnsense.si--color::before { color: #D94F00; }
.si-opsgenie::before { content: "\ef17"; }
.si-opsgenie.si--color::before { color: #172B4D; }
.si-opslevel::before { content: "\ef18"; }
.si-opslevel.si--color::before { color: #1890FF; }
.si-oracle::before { content: "\ef19"; }
.si-oracle.si--color::before { color: #F80000; }
.si-orcid::before { content: "\ef1a"; }
.si-orcid.si--color::before { color: #A6CE39; }
.si-org::before { content: "\ef1b"; }
.si-org.si--color::before { color: #77AA99; }
.si-origin::before { content: "\ef1c"; }
.si-origin.si--color::before { color: #F56C2D; }
.si-osano::before { content: "\ef1d"; }
.si-osano.si--color::before { color: #7764FA; }
.si-oshkosh::before { content: "\ef1e"; }
.si-oshkosh.si--color::before { color: #E6830F; }
.si-osmc::before { content: "\ef1f"; }
.si-osmc.si--color::before { color: #17394A; }
.si-osu::before { content: "\ef20"; }
.si-osu.si--color::before { color: #FF66AA; }
.si-overcast::before { content: "\ef21"; }
.si-overcast.si--color::before { color: #FC7E0F; }
.si-overleaf::before { content: "\ef22"; }
.si-overleaf.si--color::before { color: #47A141; }
.si-ovh::before { content: "\ef23"; }
.si-ovh.si--color::before { color: #123F6D; }
.si-owasp::before { content: "\ef24"; }
.si-owasp.si--color::before { color: #000000; }
.si-oxygen::before { content: "\ef25"; }
.si-oxygen.si--color::before { color: #3A209E; }
.si-oyo::before { content: "\ef26"; }
.si-oyo.si--color::before { color: #EE2E24; }
.si-p5dotjs::before { content: "\ef27"; }
.si-p5dotjs.si--color::before { color: #ED225D; }
.si-packagist::before { content: "\ef28"; }
.si-packagist.si--color::before { color: #F28D1A; }
.si-packer::before { content: "\ef29"; }
.si-packer.si--color::before { color: #02A8EF; }
.si-paddypower::before { content: "\ef2a"; }
.si-paddypower.si--color::before { color: #004833; }
.si-pagekit::before { content: "\ef2b"; }
.si-pagekit.si--color::before { color: #212121; }
.si-pagerduty::before { content: "\ef2c"; }
.si-pagerduty.si--color::before { color: #06AC38; }
.si-pagespeedinsights::before { content: "\ef2d"; }
.si-pagespeedinsights.si--color::before { color: #4285F4; }
.si-pagseguro::before { content: "\ef2e"; }
.si-pagseguro.si--color::before { color: #FFC801; }
.si-palantir::before { content: "\ef2f"; }
.si-palantir.si--color::before { color: #101113; }
.si-paloaltosoftware::before { content: "\ef30"; }
.si-paloaltosoftware.si--color::before { color: #83DA77; }
.si-pandas::before { content: "\ef31"; }
.si-pandas.si--color::before { color: #150458; }
.si-pandora::before { content: "\ef32"; }
.si-pandora.si--color::before { color: #224099; }
.si-pantheon::before { content: "\ef33"; }
.si-pantheon.si--color::before { color: #FFDC28; }
.si-paritysubstrate::before { content: "\ef34"; }
.si-paritysubstrate.si--color::before { color: #282828; }
.si-parsedotly::before { content: "\ef35"; }
.si-parsedotly.si--color::before { color: #5BA745; }
.si-passport::before { content: "\ef36"; }
.si-passport.si--color::before { color: #34E27A; }
.si-pastebin::before { content: "\ef37"; }
.si-pastebin.si--color::before { color: #02456C; }
.si-patreon::before { content: "\ef38"; }
.si-patreon.si--color::before { color: #FF424D; }
.si-payoneer::before { content: "\ef39"; }
.si-payoneer.si--color::before { color: #FF4800; }
.si-paypal::before { content: "\ef3a"; }
.si-paypal.si--color::before { color: #00457C; }
.si-paytm::before { content: "\ef3b"; }
.si-paytm.si--color::before { color: #20336B; }
.si-pcgamingwiki::before { content: "\ef3c"; }
.si-pcgamingwiki.si--color::before { color: #556DB3; }
.si-peakdesign::before { content: "\ef3d"; }
.si-peakdesign.si--color::before { color: #1C1B1C; }
.si-peertube::before { content: "\ef3e"; }
.si-peertube.si--color::before { color: #F1680D; }
.si-pegasusairlines::before { content: "\ef3f"; }
.si-pegasusairlines.si--color::before { color: #FDC43E; }
.si-pelican::before { content: "\ef40"; }
.si-pelican.si--color::before { color: #14A0C4; }
.si-peloton::before { content: "\ef41"; }
.si-peloton.si--color::before { color: #181A1D; }
.si-penny::before { content: "\ef42"; }
.si-penny.si--color::before { color: #CD1414; }
.si-pepsi::before { content: "\ef43"; }
.si-pepsi.si--color::before { color: #2151A1; }
.si-percy::before { content: "\ef44"; }
.si-percy.si--color::before { color: #9E66BF; }
.si-perforce::before { content: "\ef45"; }
.si-perforce.si--color::before { color: #404040; }
.si-perl::before { content: "\ef46"; }
.si-perl.si--color::before { color: #39457E; }
.si-personio::before { content: "\ef47"; }
.si-personio.si--color::before { color: #FFFFFF; }
.si-peugeot::before { content: "\ef48"; }
.si-peugeot.si--color::before { color: #000000; }
.si-pexels::before { content: "\ef49"; }
.si-pexels.si--color::before { color: #05A081; }
.si-pfsense::before { content: "\ef4a"; }
.si-pfsense.si--color::before { color: #212121; }
.si-phabricator::before { content: "\ef4b"; }
.si-phabricator.si--color::before { color: #4A5F88; }
.si-philipshue::before { content: "\ef4c"; }
.si-philipshue.si--color::before { color: #0065D3; }
.si-phonepe::before { content: "\ef4d"; }
.si-phonepe.si--color::before { color: #5F259F; }
.si-photobucket::before { content: "\ef4e"; }
.si-photobucket.si--color::before { color: #0672CB; }
.si-photocrowd::before { content: "\ef4f"; }
.si-photocrowd.si--color::before { color: #3DAD4B; }
.si-photopea::before { content: "\ef50"; }
.si-photopea.si--color::before { color: #18A497; }
.si-php::before { content: "\ef51"; }
.si-php.si--color::before { color: #777BB4; }
.si-phpmyadmin::before { content: "\ef52"; }
.si-phpmyadmin.si--color::before { color: #6C78AF; }
.si-phpstorm::before { content: "\ef53"; }
.si-phpstorm.si--color::before { color: #000000; }
.si-pihole::before { content: "\ef54"; }
.si-pihole.si--color::before { color: #96060C; }
.si-picartodottv::before { content: "\ef55"; }
.si-picartodottv.si--color::before { color: #1DA456; }
.si-picnic::before { content: "\ef56"; }
.si-picnic.si--color::before { color: #E1171E; }
.si-picpay::before { content: "\ef57"; }
.si-picpay.si--color::before { color: #21C25E; }
.si-pimcore::before { content: "\ef58"; }
.si-pimcore.si--color::before { color: #6428B4; }
.si-pinboard::before { content: "\ef59"; }
.si-pinboard.si--color::before { color: #0000FF; }
.si-pingdom::before { content: "\ef5a"; }
.si-pingdom.si--color::before { color: #FFF000; }
.si-pinterest::before { content: "\ef5b"; }
.si-pinterest.si--color::before { color: #BD081C; }
.si-pioneerdj::before { content: "\ef5c"; }
.si-pioneerdj.si--color::before { color: #1A1928; }
.si-pivotaltracker::before { content: "\ef5d"; }
.si-pivotaltracker.si--color::before { color: #517A9E; }
.si-piwigo::before { content: "\ef5e"; }
.si-piwigo.si--color::before { color: #FF7700; }
.si-pixabay::before { content: "\ef5f"; }
.si-pixabay.si--color::before { color: #2EC66D; }
.si-pixiv::before { content: "\ef60"; }
.si-pixiv.si--color::before { color: #0096FA; }
.si-pjsip::before { content: "\ef61"; }
.si-pjsip.si--color::before { color: #F86001; }
.si-planet::before { content: "\ef62"; }
.si-planet.si--color::before { color: #009DB1; }
.si-plangrid::before { content: "\ef63"; }
.si-plangrid.si--color::before { color: #0085DE; }
.si-platformdotsh::before { content: "\ef64"; }
.si-platformdotsh.si--color::before { color: #1A182A; }
.si-platzi::before { content: "\ef65"; }
.si-platzi.si--color::before { color: #98CA3F; }
.si-plausibleanalytics::before { content: "\ef66"; }
.si-plausibleanalytics.si--color::before { color: #5850EC; }
.si-playcanvas::before { content: "\ef67"; }
.si-playcanvas.si--color::before { color: #E05F2C; }
.si-playerfm::before { content: "\ef68"; }
.si-playerfm.si--color::before { color: #C8122A; }
.si-playerdotme::before { content: "\ef69"; }
.si-playerdotme.si--color::before { color: #C0379A; }
.si-playstation::before { content: "\ef6a"; }
.si-playstation.si--color::before { color: #003791; }
.si-playstation2::before { content: "\ef6b"; }
.si-playstation2.si--color::before { color: #003791; }
.si-playstation3::before { content: "\ef6c"; }
.si-playstation3.si--color::before { color: #003791; }
.si-playstation4::before { content: "\ef6d"; }
.si-playstation4.si--color::before { color: #003791; }
.si-playstation5::before { content: "\ef6e"; }
.si-playstation5.si--color::before { color: #003791; }
.si-playstationvita::before { content: "\ef6f"; }
.si-playstationvita.si--color::before { color: #003791; }
.si-pleroma::before { content: "\ef70"; }
.si-pleroma.si--color::before { color: #FBA457; }
.si-plesk::before { content: "\ef71"; }
.si-plesk.si--color::before { color: #52BBE6; }
.si-plex::before { content: "\ef72"; }
.si-plex.si--color::before { color: #E5A00D; }
.si-plotly::before { content: "\ef73"; }
.si-plotly.si--color::before { color: #3F4F75; }
.si-pluralsight::before { content: "\ef74"; }
.si-pluralsight.si--color::before { color: #F15B2A; }
.si-plurk::before { content: "\ef75"; }
.si-plurk.si--color::before { color: #FF574D; }
.si-pluscodes::before { content: "\ef76"; }
.si-pluscodes.si--color::before { color: #4285F4; }
.si-pm2::before { content: "\ef77"; }
.si-pm2.si--color::before { color: #2B037A; }
.si-pnpm::before { content: "\ef78"; }
.si-pnpm.si--color::before { color: #F69220; }
.si-pocket::before { content: "\ef79"; }
.si-pocket.si--color::before { color: #EF3F56; }
.si-pocketcasts::before { content: "\ef7a"; }
.si-pocketcasts.si--color::before { color: #F43E37; }
.si-podcastaddict::before { content: "\ef7b"; }
.si-podcastaddict.si--color::before { color: #F4842D; }
.si-podman::before { content: "\ef7c"; }
.si-podman.si--color::before { color: #892CA0; }
.si-pointy::before { content: "\ef7d"; }
.si-pointy.si--color::before { color: #009DE0; }
.si-pokemon::before { content: "\ef7e"; }
.si-pokemon.si--color::before { color: #FFCB05; }
.si-polkadot::before { content: "\ef7f"; }
.si-polkadot.si--color::before { color: #E6007A; }
.si-poly::before { content: "\ef80"; }
.si-poly.si--color::before { color: #EB3C00; }
.si-polymerproject::before { content: "\ef81"; }
.si-polymerproject.si--color::before { color: #FF4470; }
.si-polywork::before { content: "\ef82"; }
.si-polywork.si--color::before { color: #543DE0; }
.si-popos::before { content: "\ef83"; }
.si-popos.si--color::before { color: #48B9C7; }
.si-porsche::before { content: "\ef84"; }
.si-porsche.si--color::before { color: #B12B28; }
.si-portainer::before { content: "\ef85"; }
.si-portainer.si--color::before { color: #13BEF9; }
.si-postcss::before { content: "\ef86"; }
.si-postcss.si--color::before { color: #DD3A0A; }
.si-postgresql::before { content: "\ef87"; }
.si-postgresql.si--color::before { color: #4169E1; }
.si-postman::before { content: "\ef88"; }
.si-postman.si--color::before { color: #FF6C37; }
.si-postmates::before { content: "\ef89"; }
.si-postmates.si--color::before { color: #FFDF18; }
.si-powerapps::before { content: "\ef8a"; }
.si-powerapps.si--color::before { color: #742774; }
.si-powerbi::before { content: "\ef8b"; }
.si-powerbi.si--color::before { color: #F2C811; }
.si-powers::before { content: "\ef8c"; }
.si-powers.si--color::before { color: #E74536; }
.si-powershell::before { content: "\ef8d"; }
.si-powershell.si--color::before { color: #5391FE; }
.si-prdotco::before { content: "\ef8e"; }
.si-prdotco.si--color::before { color: #0080FF; }
.si-precommit::before { content: "\ef8f"; }
.si-precommit.si--color::before { color: #FAB040; }
.si-premierleague::before { content: "\ef90"; }
.si-premierleague.si--color::before { color: #360D3A; }
.si-prestashop::before { content: "\ef91"; }
.si-prestashop.si--color::before { color: #DF0067; }
.si-presto::before { content: "\ef92"; }
.si-presto.si--color::before { color: #5890FF; }
.si-prettier::before { content: "\ef93"; }
.si-prettier.si--color::before { color: #F7B93E; }
.si-prezi::before { content: "\ef94"; }
.si-prezi.si--color::before { color: #3181FF; }
.si-prime::before { content: "\ef95"; }
.si-prime.si--color::before { color: #00A8E1; }
.si-primevideo::before { content: "\ef96"; }
.si-primevideo.si--color::before { color: #1F2E3E; }
.si-prisma::before { content: "\ef97"; }
.si-prisma.si--color::before { color: #2D3748; }
.si-prismic::before { content: "\ef98"; }
.si-prismic.si--color::before { color: #5163BA; }
.si-privateinternetaccess::before { content: "\ef99"; }
.si-privateinternetaccess.si--color::before { color: #4BB749; }
.si-protools::before { content: "\ef9a"; }
.si-protools.si--color::before { color: #7ACB10; }
.si-probot::before { content: "\ef9b"; }
.si-probot.si--color::before { color: #00B0D8; }
.si-processingfoundation::before { content: "\ef9c"; }
.si-processingfoundation.si--color::before { color: #006699; }
.si-processwire::before { content: "\ef9d"; }
.si-processwire.si--color::before { color: #2480E6; }
.si-producthunt::before { content: "\ef9e"; }
.si-producthunt.si--color::before { color: #DA552F; }
.si-progate::before { content: "\ef9f"; }
.si-progate.si--color::before { color: #380953; }
.si-progress::before { content: "\efa0"; }
.si-progress.si--color::before { color: #5CE500; }
.si-prometheus::before { content: "\efa1"; }
.si-prometheus.si--color::before { color: #E6522C; }
.si-prosieben::before { content: "\efa2"; }
.si-prosieben.si--color::before { color: #E6000F; }
.si-protodotio::before { content: "\efa3"; }
.si-protodotio.si--color::before { color: #34A7C1; }
.si-protocolsdotio::before { content: "\efa4"; }
.si-protocolsdotio.si--color::before { color: #4D9FE7; }
.si-protondb::before { content: "\efa5"; }
.si-protondb.si--color::before { color: #F50057; }
.si-protonmail::before { content: "\efa6"; }
.si-protonmail.si--color::before { color: #8B89CC; }
.si-protonvpn::before { content: "\efa7"; }
.si-protonvpn.si--color::before { color: #56B366; }
.si-protractor::before { content: "\efa8"; }
.si-protractor.si--color::before { color: #ED163A; }
.si-proxmox::before { content: "\efa9"; }
.si-proxmox.si--color::before { color: #E57000; }
.si-publons::before { content: "\efaa"; }
.si-publons.si--color::before { color: #336699; }
.si-pubmed::before { content: "\efab"; }
.si-pubmed.si--color::before { color: #326599; }
.si-pug::before { content: "\efac"; }
.si-pug.si--color::before { color: #A86454; }
.si-pulumi::before { content: "\efad"; }
.si-pulumi.si--color::before { color: #8A3391; }
.si-puma::before { content: "\efae"; }
.si-puma.si--color::before { color: #242B2F; }
.si-puppet::before { content: "\efaf"; }
.si-puppet.si--color::before { color: #FFAE1A; }
.si-puppeteer::before { content: "\efb0"; }
.si-puppeteer.si--color::before { color: #40B5A4; }
.si-purescript::before { content: "\efb1"; }
.si-purescript.si--color::before { color: #14161A; }
.si-purgecss::before { content: "\efb2"; }
.si-purgecss.si--color::before { color: #14161A; }
.si-purism::before { content: "\efb3"; }
.si-purism.si--color::before { color: #2D2D2D; }
.si-pusher::before { content: "\efb4"; }
.si-pusher.si--color::before { color: #300D4F; }
.si-pwa::before { content: "\efb5"; }
.si-pwa.si--color::before { color: #5A0FC8; }
.si-pycharm::before { content: "\efb6"; }
.si-pycharm.si--color::before { color: #000000; }
.si-pypi::before { content: "\efb7"; }
.si-pypi.si--color::before { color: #3775A9; }
.si-pypy::before { content: "\efb8"; }
.si-pypy.si--color::before { color: #193440; }
.si-pyscaffold::before { content: "\efb9"; }
.si-pyscaffold.si--color::before { color: #005CA0; }
.si-pytest::before { content: "\efba"; }
.si-pytest.si--color::before { color: #0A9EDC; }
.si-python::before { content: "\efbb"; }
.si-python.si--color::before { color: #3776AB; }
.si-pytorch::before { content: "\efbc"; }
.si-pytorch.si--color::before { color: #EE4C2C; }
.si-pytorchlightning::before { content: "\efbd"; }
.si-pytorchlightning.si--color::before { color: #792EE5; }
.si-pyup::before { content: "\efbe"; }
.si-pyup.si--color::before { color: #9F55FF; }
.si-qantas::before { content: "\efbf"; }
.si-qantas.si--color::before { color: #E40000; }
.si-qatarairways::before { content: "\efc0"; }
.si-qatarairways.si--color::before { color: #5C0D34; }
.si-qemu::before { content: "\efc1"; }
.si-qemu.si--color::before { color: #FF6600; }
.si-qgis::before { content: "\efc2"; }
.si-qgis.si--color::before { color: #589632; }
.si-qi::before { content: "\efc3"; }
.si-qi.si--color::before { color: #000000; }
.si-qiita::before { content: "\efc4"; }
.si-qiita.si--color::before { color: #55C500; }
.si-qiskit::before { content: "\efc5"; }
.si-qiskit.si--color::before { color: #6929C4; }
.si-qiwi::before { content: "\efc6"; }
.si-qiwi.si--color::before { color: #FF8C00; }
.si-qt::before { content: "\efc7"; }
.si-qt.si--color::before { color: #41CD52; }
.si-qualcomm::before { content: "\efc8"; }
.si-qualcomm.si--color::before { color: #3253DC; }
.si-qualtrics::before { content: "\efc9"; }
.si-qualtrics.si--color::before { color: #00B4EF; }
.si-qualys::before { content: "\efca"; }
.si-qualys.si--color::before { color: #ED2E26; }
.si-quantcast::before { content: "\efcb"; }
.si-quantcast.si--color::before { color: #000000; }
.si-quantconnect::before { content: "\efcc"; }
.si-quantconnect.si--color::before { color: #F5AE29; }
.si-quarkus::before { content: "\efcd"; }
.si-quarkus.si--color::before { color: #4695EB; }
.si-quasar::before { content: "\efce"; }
.si-quasar.si--color::before { color: #1976D2; }
.si-qubesos::before { content: "\efcf"; }
.si-qubesos.si--color::before { color: #3874D8; }
.si-quest::before { content: "\efd0"; }
.si-quest.si--color::before { color: #FB4F14; }
.si-quickbooks::before { content: "\efd1"; }
.si-quickbooks.si--color::before { color: #2CA01C; }
.si-quicktime::before { content: "\efd2"; }
.si-quicktime.si--color::before { color: #1C69F0; }
.si-quip::before { content: "\efd3"; }
.si-quip.si--color::before { color: #F27557; }
.si-quora::before { content: "\efd4"; }
.si-quora.si--color::before { color: #B92B27; }
.si-qwiklabs::before { content: "\efd5"; }
.si-qwiklabs.si--color::before { color: #F5CD0E; }
.si-qzone::before { content: "\efd6"; }
.si-qzone.si--color::before { color: #FECE00; }
.si-r::before { content: "\efd7"; }
.si-r.si--color::before { color: #276DC3; }
.si-r3::before { content: "\efd8"; }
.si-r3.si--color::before { color: #EC1D24; }
.si-rabbitmq::before { content: "\efd9"; }
.si-rabbitmq.si--color::before { color: #FF6600; }
.si-racket::before { content: "\efda"; }
.si-racket.si--color::before { color: #9F1D20; }
.si-radar::before { content: "\efdb"; }
.si-radar.si--color::before { color: #007AFF; }
.si-radiopublic::before { content: "\efdc"; }
.si-radiopublic.si--color::before { color: #CE262F; }
.si-railway::before { content: "\efdd"; }
.si-railway.si--color::before { color: #0B0D0E; }
.si-rainmeter::before { content: "\efde"; }
.si-rainmeter.si--color::before { color: #19519B; }
.si-rakuten::before { content: "\efdf"; }
.si-rakuten.si--color::before { color: #BF0000; }
.si-ram::before { content: "\efe0"; }
.si-ram.si--color::before { color: #000000; }
.si-rancher::before { content: "\efe1"; }
.si-rancher.si--color::before { color: #0075A8; }
.si-raspberrypi::before { content: "\efe2"; }
.si-raspberrypi.si--color::before { color: #A22846; }
.si-razer::before { content: "\efe3"; }
.si-razer.si--color::before { color: #00FF00; }
.si-razorpay::before { content: "\efe4"; }
.si-razorpay.si--color::before { color: #0C2451; }
.si-react::before { content: "\efe5"; }
.si-react.si--color::before { color: #61DAFB; }
.si-reactrouter::before { content: "\efe6"; }
.si-reactrouter.si--color::before { color: #CA4245; }
.si-reacttable::before { content: "\efe7"; }
.si-reacttable.si--color::before { color: #FF4154; }
.si-reactivex::before { content: "\efe8"; }
.si-reactivex.si--color::before { color: #B7178C; }
.si-reactos::before { content: "\efe9"; }
.si-reactos.si--color::before { color: #0088CC; }
.si-readthedocs::before { content: "\efea"; }
.si-readthedocs.si--color::before { color: #8CA1AF; }
.si-realm::before { content: "\efeb"; }
.si-realm.si--color::before { color: #39477F; }
.si-reason::before { content: "\efec"; }
.si-reason.si--color::before { color: #DD4B39; }
.si-reasonstudios::before { content: "\efed"; }
.si-reasonstudios.si--color::before { color: #FFFFFF; }
.si-redhat::before { content: "\efee"; }
.si-redhat.si--color::before { color: #EE0000; }
.si-redhatopenshift::before { content: "\efef"; }
.si-redhatopenshift.si--color::before { color: #EE0000; }
.si-redbubble::before { content: "\eff0"; }
.si-redbubble.si--color::before { color: #E41321; }
.si-reddit::before { content: "\eff1"; }
.si-reddit.si--color::before { color: #FF4500; }
.si-redis::before { content: "\eff2"; }
.si-redis.si--color::before { color: #DC382D; }
.si-redmine::before { content: "\eff3"; }
.si-redmine.si--color::before { color: #B32024; }
.si-redux::before { content: "\eff4"; }
.si-redux.si--color::before { color: #764ABC; }
.si-reduxsaga::before { content: "\eff5"; }
.si-reduxsaga.si--color::before { color: #999999; }
.si-redwoodjs::before { content: "\eff6"; }
.si-redwoodjs.si--color::before { color: #BF4722; }
.si-reebok::before { content: "\eff7"; }
.si-reebok.si--color::before { color: #E41D1B; }
.si-relianceindustrieslimited::before { content: "\eff8"; }
.si-relianceindustrieslimited.si--color::before { color: #D1AB66; }
.si-renpy::before { content: "\eff9"; }
.si-renpy.si--color::before { color: #FF7F7F; }
.si-renault::before { content: "\effa"; }
.si-renault.si--color::before { color: #FFCC33; }
.si-renovatebot::before { content: "\effb"; }
.si-renovatebot.si--color::before { color: #1A1F6C; }
.si-renren::before { content: "\effc"; }
.si-renren.si--color::before { color: #217DC6; }
.si-replit::before { content: "\effd"; }
.si-replit.si--color::before { color: #667881; }
.si-rescuetime::before { content: "\effe"; }
.si-rescuetime.si--color::before { color: #161A3B; }
.si-researchgate::before { content: "\efff"; }
.si-researchgate.si--color::before { color: #00CCBB; }
.si-resurrectionremixos::before { content: "\f000"; }
.si-resurrectionremixos.si--color::before { color: #000000; }
.si-retroarch::before { content: "\f001"; }
.si-retroarch.si--color::before { color: #000000; }
.si-retropie::before { content: "\f002"; }
.si-retropie.si--color::before { color: #CC0000; }
.si-revealdotjs::before { content: "\f003"; }
.si-revealdotjs.si--color::before { color: #F2E142; }
.si-reverbnation::before { content: "\f004"; }
.si-reverbnation.si--color::before { color: #E43526; }
.si-revolut::before { content: "\f005"; }
.si-revolut.si--color::before { color: #0075EB; }
.si-revue::before { content: "\f006"; }
.si-revue.si--color::before { color: #E15718; }
.si-rewe::before { content: "\f007"; }
.si-rewe.si--color::before { color: #CC071E; }
.si-rezgo::before { content: "\f008"; }
.si-rezgo.si--color::before { color: #F76C00; }
.si-rhinoceros::before { content: "\f009"; }
.si-rhinoceros.si--color::before { color: #801010; }
.si-rider::before { content: "\f00a"; }
.si-rider.si--color::before { color: #000000; }
.si-rimacautomobili::before { content: "\f00b"; }
.si-rimacautomobili.si--color::before { color: #0A222E; }
.si-ring::before { content: "\f00c"; }
.si-ring.si--color::before { color: #1C9AD6; }
.si-riotgames::before { content: "\f00d"; }
.si-riotgames.si--color::before { color: #D32936; }
.si-ripple::before { content: "\f00e"; }
.si-ripple.si--color::before { color: #0085C0; }
.si-riseup::before { content: "\f00f"; }
.si-riseup.si--color::before { color: #FF0000; }
.si-roamresearch::before { content: "\f010"; }
.si-roamresearch.si--color::before { color: #343A40; }
.si-roblox::before { content: "\f011"; }
.si-roblox.si--color::before { color: #000000; }
.si-robotframework::before { content: "\f012"; }
.si-robotframework.si--color::before { color: #000000; }
.si-rocketdotchat::before { content: "\f013"; }
.si-rocketdotchat.si--color::before { color: #F5455C; }
.si-rockylinux::before { content: "\f014"; }
.si-rockylinux.si--color::before { color: #10B981; }
.si-roku::before { content: "\f015"; }
.si-roku.si--color::before { color: #662D91; }
.si-rollsroyce::before { content: "\f016"; }
.si-rollsroyce.si--color::before { color: #281432; }
.si-rollupdotjs::before { content: "\f017"; }
.si-rollupdotjs.si--color::before { color: #EC4A3F; }
.si-rome::before { content: "\f018"; }
.si-rome.si--color::before { color: #27272A; }
.si-roots::before { content: "\f019"; }
.si-roots.si--color::before { color: #525DDC; }
.si-rootsbedrock::before { content: "\f01a"; }
.si-rootsbedrock.si--color::before { color: #525DDC; }
.si-rootssage::before { content: "\f01b"; }
.si-rootssage.si--color::before { color: #525DDC; }
.si-ros::before { content: "\f01c"; }
.si-ros.si--color::before { color: #22314E; }
.si-rotaryinternational::before { content: "\f01d"; }
.si-rotaryinternational.si--color::before { color: #F7A81B; }
.si-rottentomatoes::before { content: "\f01e"; }
.si-rottentomatoes.si--color::before { color: #FA320A; }
.si-roundcube::before { content: "\f01f"; }
.si-roundcube.si--color::before { color: #37BEFF; }
.si-rss::before { content: "\f020"; }
.si-rss.si--color::before { color: #FFA500; }
.si-rstudio::before { content: "\f021"; }
.si-rstudio.si--color::before { color: #75AADB; }
.si-rte::before { content: "\f022"; }
.si-rte.si--color::before { color: #00A7B3; }
.si-rtl::before { content: "\f023"; }
.si-rtl.si--color::before { color: #E9113B; }
.si-rtlzwei::before { content: "\f024"; }
.si-rtlzwei.si--color::before { color: #00BCF6; }
.si-ruby::before { content: "\f025"; }
.si-ruby.si--color::before { color: #CC342D; }
.si-rubyonrails::before { content: "\f026"; }
.si-rubyonrails.si--color::before { color: #CC0000; }
.si-rubysinatra::before { content: "\f027"; }
.si-rubysinatra.si--color::before { color: #000000; }
.si-rubygems::before { content: "\f028"; }
.si-rubygems.si--color::before { color: #E9573F; }
.si-runkeeper::before { content: "\f029"; }
.si-runkeeper.si--color::before { color: #001E62; }
.si-runkit::before { content: "\f02a"; }
.si-runkit.si--color::before { color: #491757; }
.si-rust::before { content: "\f02b"; }
.si-rust.si--color::before { color: #000000; }
.si-rxdb::before { content: "\f02c"; }
.si-rxdb.si--color::before { color: #8D1F89; }
.si-ryanair::before { content: "\f02d"; }
.si-ryanair.si--color::before { color: #073590; }
.si-s7airlines::before { content: "\f02e"; }
.si-s7airlines.si--color::before { color: #C4D600; }
.si-sabanci::before { content: "\f02f"; }
.si-sabanci.si--color::before { color: #004B93; }
.si-safari::before { content: "\f030"; }
.si-safari.si--color::before { color: #000000; }
.si-sahibinden::before { content: "\f031"; }
.si-sahibinden.si--color::before { color: #FFE800; }
.si-salesforce::before { content: "\f032"; }
.si-salesforce.si--color::before { color: #00A1E0; }
.si-saltstack::before { content: "\f033"; }
.si-saltstack.si--color::before { color: #00EACE; }
.si-samsung::before { content: "\f034"; }
.si-samsung.si--color::before { color: #1428A0; }
.si-samsungpay::before { content: "\f035"; }
.si-samsungpay.si--color::before { color: #1428A0; }
.si-sanfranciscomunicipalrailway::before { content: "\f036"; }
.si-sanfranciscomunicipalrailway.si--color::before { color: #BA0C2F; }
.si-saopaulometro::before { content: "\f037"; }
.si-saopaulometro.si--color::before { color: #004382; }
.si-sap::before { content: "\f038"; }
.si-sap.si--color::before { color: #0FAAFF; }
.si-sass::before { content: "\f039"; }
.si-sass.si--color::before { color: #CC6699; }
.si-sat1::before { content: "\f03a"; }
.si-sat1.si--color::before { color: #047DA3; }
.si-saucelabs::before { content: "\f03b"; }
.si-saucelabs.si--color::before { color: #E2231A; }
.si-scala::before { content: "\f03c"; }
.si-scala.si--color::before { color: #DC322F; }
.si-scaleway::before { content: "\f03d"; }
.si-scaleway.si--color::before { color: #4F0599; }
.si-scania::before { content: "\f03e"; }
.si-scania.si--color::before { color: #041E42; }
.si-schneiderelectric::before { content: "\f03f"; }
.si-schneiderelectric.si--color::before { color: #3DCD58; }
.si-scikitlearn::before { content: "\f040"; }
.si-scikitlearn.si--color::before { color: #F7931E; }
.si-scipy::before { content: "\f041"; }
.si-scipy.si--color::before { color: #8CAAE6; }
.si-scopus::before { content: "\f042"; }
.si-scopus.si--color::before { color: #E9711C; }
.si-scpfoundation::before { content: "\f043"; }
.si-scpfoundation.si--color::before { color: #FFFFFF; }
.si-scratch::before { content: "\f044"; }
.si-scratch.si--color::before { color: #4D97FF; }
.si-screencastify::before { content: "\f045"; }
.si-screencastify.si--color::before { color: #FF8282; }
.si-scribd::before { content: "\f046"; }
.si-scribd.si--color::before { color: #1E7B85; }
.si-scrimba::before { content: "\f047"; }
.si-scrimba.si--color::before { color: #2B283A; }
.si-scrollreveal::before { content: "\f048"; }
.si-scrollreveal.si--color::before { color: #FFCB36; }
.si-scrumalliance::before { content: "\f049"; }
.si-scrumalliance.si--color::before { color: #009FDA; }
.si-scrutinizerci::before { content: "\f04a"; }
.si-scrutinizerci.si--color::before { color: #8A9296; }
.si-seagate::before { content: "\f04b"; }
.si-seagate.si--color::before { color: #6EBE49; }
.si-seat::before { content: "\f04c"; }
.si-seat.si--color::before { color: #33302E; }
.si-sefaria::before { content: "\f04d"; }
.si-sefaria.si--color::before { color: #212E50; }
.si-sega::before { content: "\f04e"; }
.si-sega.si--color::before { color: #0089CF; }
.si-selenium::before { content: "\f04f"; }
.si-selenium.si--color::before { color: #43B02A; }
.si-sellfy::before { content: "\f050"; }
.si-sellfy.si--color::before { color: #21B352; }
.si-semanticuireact::before { content: "\f051"; }
.si-semanticuireact.si--color::before { color: #35BDB2; }
.si-semanticweb::before { content: "\f052"; }
.si-semanticweb.si--color::before { color: #005A9C; }
.si-semanticrelease::before { content: "\f053"; }
.si-semanticrelease.si--color::before { color: #494949; }
.si-semaphoreci::before { content: "\f054"; }
.si-semaphoreci.si--color::before { color: #19A974; }
.si-semver::before { content: "\f055"; }
.si-semver.si--color::before { color: #3F4551; }
.si-sencha::before { content: "\f056"; }
.si-sencha.si--color::before { color: #86BC40; }
.si-sennheiser::before { content: "\f057"; }
.si-sennheiser.si--color::before { color: #000000; }
.si-sensu::before { content: "\f058"; }
.si-sensu.si--color::before { color: #89C967; }
.si-sentry::before { content: "\f059"; }
.si-sentry.si--color::before { color: #362D59; }
.si-sepa::before { content: "\f05a"; }
.si-sepa.si--color::before { color: #2350A9; }
.si-sequelize::before { content: "\f05b"; }
.si-sequelize.si--color::before { color: #52B0E7; }
.si-serverfault::before { content: "\f05c"; }
.si-serverfault.si--color::before { color: #E7282D; }
.si-serverless::before { content: "\f05d"; }
.si-serverless.si--color::before { color: #FD5750; }
.si-sfml::before { content: "\f05e"; }
.si-sfml.si--color::before { color: #8CC445; }
.si-shadow::before { content: "\f05f"; }
.si-shadow.si--color::before { color: #0A0C0D; }
.si-shanghaimetro::before { content: "\f060"; }
.si-shanghaimetro.si--color::before { color: #EC1C24; }
.si-sharp::before { content: "\f061"; }
.si-sharp.si--color::before { color: #99CC00; }
.si-shazam::before { content: "\f062"; }
.si-shazam.si--color::before { color: #0088FF; }
.si-shell::before { content: "\f063"; }
.si-shell.si--color::before { color: #FFD500; }
.si-shelly::before { content: "\f064"; }
.si-shelly.si--color::before { color: #4495D1; }
.si-shenzhenmetro::before { content: "\f065"; }
.si-shenzhenmetro.si--color::before { color: #009943; }
.si-shieldsdotio::before { content: "\f066"; }
.si-shieldsdotio.si--color::before { color: #000000; }
.si-shikimori::before { content: "\f067"; }
.si-shikimori.si--color::before { color: #343434; }
.si-shopify::before { content: "\f068"; }
.si-shopify.si--color::before { color: #7AB55C; }
.si-shopware::before { content: "\f069"; }
.si-shopware.si--color::before { color: #189EFF; }
.si-shotcut::before { content: "\f06a"; }
.si-shotcut.si--color::before { color: #115C77; }
.si-showpad::before { content: "\f06b"; }
.si-showpad.si--color::before { color: #2D2E83; }
.si-showtime::before { content: "\f06c"; }
.si-showtime.si--color::before { color: #B10000; }
.si-shutterstock::before { content: "\f06d"; }
.si-shutterstock.si--color::before { color: #EE2B24; }
.si-siemens::before { content: "\f06e"; }
.si-siemens.si--color::before { color: #009999; }
.si-signal::before { content: "\f06f"; }
.si-signal.si--color::before { color: #3A76F0; }
.si-simkl::before { content: "\f070"; }
.si-simkl.si--color::before { color: #000000; }
.si-simpleanalytics::before { content: "\f071"; }
.si-simpleanalytics.si--color::before { color: #FF4F64; }
.si-simpleicons::before { content: "\f072"; }
.si-simpleicons.si--color::before { color: #111111; }
.si-sinaweibo::before { content: "\f073"; }
.si-sinaweibo.si--color::before { color: #E6162D; }
.si-singlestore::before { content: "\f074"; }
.si-singlestore.si--color::before { color: #AA00FF; }
.si-sitepoint::before { content: "\f075"; }
.si-sitepoint.si--color::before { color: #258AAF; }
.si-sketch::before { content: "\f076"; }
.si-sketch.si--color::before { color: #F7B500; }
.si-sketchfab::before { content: "\f077"; }
.si-sketchfab.si--color::before { color: #1CAAD9; }
.si-sketchup::before { content: "\f078"; }
.si-sketchup.si--color::before { color: #005F9E; }
.si-skillshare::before { content: "\f079"; }
.si-skillshare.si--color::before { color: #00FF84; }
.si-skoda::before { content: "\f07a"; }
.si-skoda.si--color::before { color: #4BA82E; }
.si-sky::before { content: "\f07b"; }
.si-sky.si--color::before { color: #0072C9; }
.si-skyliner::before { content: "\f07c"; }
.si-skyliner.si--color::before { color: #2FCEA0; }
.si-skynet::before { content: "\f07d"; }
.si-skynet.si--color::before { color: #00C65E; }
.si-skypack::before { content: "\f07e"; }
.si-skypack.si--color::before { color: #3167FF; }
.si-skype::before { content: "\f07f"; }
.si-skype.si--color::before { color: #00AFF0; }
.si-skypeforbusiness::before { content: "\f080"; }
.si-skypeforbusiness.si--color::before { color: #00AFF0; }
.si-slack::before { content: "\f081"; }
.si-slack.si--color::before { color: #4A154B; }
.si-slackware::before { content: "\f082"; }
.si-slackware.si--color::before { color: #000000; }
.si-slashdot::before { content: "\f083"; }
.si-slashdot.si--color::before { color: #026664; }
.si-slickpic::before { content: "\f084"; }
.si-slickpic.si--color::before { color: #FF880F; }
.si-slides::before { content: "\f085"; }
.si-slides.si--color::before { color: #E4637C; }
.si-slideshare::before { content: "\f086"; }
.si-slideshare.si--color::before { color: #008ED2; }
.si-smart::before { content: "\f087"; }
.si-smart.si--color::before { color: #FABC0C; }
.si-smartthings::before { content: "\f088"; }
.si-smartthings.si--color::before { color: #15BFFF; }
.si-smashdotgg::before { content: "\f089"; }
.si-smashdotgg.si--color::before { color: #CB333B; }
.si-smashingmagazine::before { content: "\f08a"; }
.si-smashingmagazine.si--color::before { color: #E85C33; }
.si-smrt::before { content: "\f08b"; }
.si-smrt.si--color::before { color: #EE2E24; }
.si-smugmug::before { content: "\f08c"; }
.si-smugmug.si--color::before { color: #6DB944; }
.si-snapchat::before { content: "\f08d"; }
.si-snapchat.si--color::before { color: #FFFC00; }
.si-snapcraft::before { content: "\f08e"; }
.si-snapcraft.si--color::before { color: #82BEA0; }
.si-snowflake::before { content: "\f08f"; }
.si-snowflake.si--color::before { color: #29B5E8; }
.si-snowpack::before { content: "\f090"; }
.si-snowpack.si--color::before { color: #2E5E82; }
.si-snyk::before { content: "\f091"; }
.si-snyk.si--color::before { color: #4C4A73; }
.si-society6::before { content: "\f092"; }
.si-society6.si--color::before { color: #000000; }
.si-socketdotio::before { content: "\f093"; }
.si-socketdotio.si--color::before { color: #010101; }
.si-sogou::before { content: "\f094"; }
.si-sogou.si--color::before { color: #FB6022; }
.si-solidity::before { content: "\f095"; }
.si-solidity.si--color::before { color: #363636; }
.si-sololearn::before { content: "\f096"; }
.si-sololearn.si--color::before { color: #149EF2; }
.si-solus::before { content: "\f097"; }
.si-solus.si--color::before { color: #5294E2; }
.si-sonarcloud::before { content: "\f098"; }
.si-sonarcloud.si--color::before { color: #F3702A; }
.si-sonarlint::before { content: "\f099"; }
.si-sonarlint.si--color::before { color: #CB2029; }
.si-sonarqube::before { content: "\f09a"; }
.si-sonarqube.si--color::before { color: #4E9BCD; }
.si-sonarsource::before { content: "\f09b"; }
.si-sonarsource.si--color::before { color: #CB3032; }
.si-songkick::before { content: "\f09c"; }
.si-songkick.si--color::before { color: #F80046; }
.si-songoda::before { content: "\f09d"; }
.si-songoda.si--color::before { color: #FC494A; }
.si-sonicwall::before { content: "\f09e"; }
.si-sonicwall.si--color::before { color: #FF791A; }
.si-sonos::before { content: "\f09f"; }
.si-sonos.si--color::before { color: #000000; }
.si-sony::before { content: "\f0a0"; }
.si-sony.si--color::before { color: #FFFFFF; }
.si-soundcloud::before { content: "\f0a1"; }
.si-soundcloud.si--color::before { color: #FF3300; }
.si-sourceengine::before { content: "\f0a2"; }
.si-sourceengine.si--color::before { color: #F79A10; }
.si-sourceforge::before { content: "\f0a3"; }
.si-sourceforge.si--color::before { color: #FF6600; }
.si-sourcegraph::before { content: "\f0a4"; }
.si-sourcegraph.si--color::before { color: #00CBEC; }
.si-sourcetree::before { content: "\f0a5"; }
.si-sourcetree.si--color::before { color: #0052CC; }
.si-southwestairlines::before { content: "\f0a6"; }
.si-southwestairlines.si--color::before { color: #304CB2; }
.si-spacemacs::before { content: "\f0a7"; }
.si-spacemacs.si--color::before { color: #9266CC; }
.si-spacex::before { content: "\f0a8"; }
.si-spacex.si--color::before { color: #000000; }
.si-sparkar::before { content: "\f0a9"; }
.si-sparkar.si--color::before { color: #FF5C83; }
.si-sparkasse::before { content: "\f0aa"; }
.si-sparkasse.si--color::before { color: #FF0000; }
.si-sparkfun::before { content: "\f0ab"; }
.si-sparkfun.si--color::before { color: #E53525; }
.si-sparkpost::before { content: "\f0ac"; }
.si-sparkpost.si--color::before { color: #FA6423; }
.si-spdx::before { content: "\f0ad"; }
.si-spdx.si--color::before { color: #4398CC; }
.si-speakerdeck::before { content: "\f0ae"; }
.si-speakerdeck.si--color::before { color: #009287; }
.si-spectrum::before { content: "\f0af"; }
.si-spectrum.si--color::before { color: #7B16FF; }
.si-speedtest::before { content: "\f0b0"; }
.si-speedtest.si--color::before { color: #141526; }
.si-spinnaker::before { content: "\f0b1"; }
.si-spinnaker.si--color::before { color: #139BB4; }
.si-spinrilla::before { content: "\f0b2"; }
.si-spinrilla.si--color::before { color: #460856; }
.si-splunk::before { content: "\f0b3"; }
.si-splunk.si--color::before { color: #000000; }
.si-spond::before { content: "\f0b4"; }
.si-spond.si--color::before { color: #EE4353; }
.si-spotify::before { content: "\f0b5"; }
.si-spotify.si--color::before { color: #1DB954; }
.si-spotlight::before { content: "\f0b6"; }
.si-spotlight.si--color::before { color: #352A71; }
.si-spreadshirt::before { content: "\f0b7"; }
.si-spreadshirt.si--color::before { color: #00B2A5; }
.si-spreaker::before { content: "\f0b8"; }
.si-spreaker.si--color::before { color: #F5C300; }
.si-spring::before { content: "\f0b9"; }
.si-spring.si--color::before { color: #6DB33F; }
.si-spring_creators::before { content: "\f0ba"; }
.si-spring_creators.si--color::before { color: #000000; }
.si-springboot::before { content: "\f0bb"; }
.si-springboot.si--color::before { color: #6DB33F; }
.si-springsecurity::before { content: "\f0bc"; }
.si-springsecurity.si--color::before { color: #6DB33F; }
.si-spyderide::before { content: "\f0bd"; }
.si-spyderide.si--color::before { color: #FF0000; }
.si-sqlite::before { content: "\f0be"; }
.si-sqlite.si--color::before { color: #003B57; }
.si-square::before { content: "\f0bf"; }
.si-square.si--color::before { color: #3E4348; }
.si-squareenix::before { content: "\f0c0"; }
.si-squareenix.si--color::before { color: #ED1C24; }
.si-squarespace::before { content: "\f0c1"; }
.si-squarespace.si--color::before { color: #000000; }
.si-ssrn::before { content: "\f0c2"; }
.si-ssrn.si--color::before { color: #154881; }
.si-stackexchange::before { content: "\f0c3"; }
.si-stackexchange.si--color::before { color: #1E5397; }
.si-stackoverflow::before { content: "\f0c4"; }
.si-stackoverflow.si--color::before { color: #F58025; }
.si-stackbit::before { content: "\f0c5"; }
.si-stackbit.si--color::before { color: #207BEA; }
.si-stackpath::before { content: "\f0c6"; }
.si-stackpath.si--color::before { color: #000000; }
.si-stackshare::before { content: "\f0c7"; }
.si-stackshare.si--color::before { color: #0690FA; }
.si-stadia::before { content: "\f0c8"; }
.si-stadia.si--color::before { color: #CD2640; }
.si-staffbase::before { content: "\f0c9"; }
.si-staffbase.si--color::before { color: #00A4FD; }
.si-startrek::before { content: "\f0ca"; }
.si-startrek.si--color::before { color: #FFE200; }
.si-starbucks::before { content: "\f0cb"; }
.si-starbucks.si--color::before { color: #006241; }
.si-starlingbank::before { content: "\f0cc"; }
.si-starlingbank.si--color::before { color: #6935D3; }
.si-starship::before { content: "\f0cd"; }
.si-starship.si--color::before { color: #DD0B78; }
.si-starz::before { content: "\f0ce"; }
.si-starz.si--color::before { color: #000000; }
.si-statamic::before { content: "\f0cf"; }
.si-statamic.si--color::before { color: #FF269E; }
.si-statuspage::before { content: "\f0d0"; }
.si-statuspage.si--color::before { color: #172B4D; }
.si-statuspal::before { content: "\f0d1"; }
.si-statuspal.si--color::before { color: #4934BF; }
.si-steam::before { content: "\f0d2"; }
.si-steam.si--color::before { color: #000000; }
.si-steamdb::before { content: "\f0d3"; }
.si-steamdb.si--color::before { color: #000000; }
.si-steamworks::before { content: "\f0d4"; }
.si-steamworks.si--color::before { color: #1E1E1E; }
.si-steelseries::before { content: "\f0d5"; }
.si-steelseries.si--color::before { color: #FF5200; }
.si-steem::before { content: "\f0d6"; }
.si-steem.si--color::before { color: #171FC9; }
.si-steemit::before { content: "\f0d7"; }
.si-steemit.si--color::before { color: #06D6A9; }
.si-steinberg::before { content: "\f0d8"; }
.si-steinberg.si--color::before { color: #C90827; }
.si-stellar::before { content: "\f0d9"; }
.si-stellar.si--color::before { color: #7D00FF; }
.si-stencyl::before { content: "\f0da"; }
.si-stencyl.si--color::before { color: #8E1C04; }
.si-stimulus::before { content: "\f0db"; }
.si-stimulus.si--color::before { color: #77E8B9; }
.si-stitcher::before { content: "\f0dc"; }
.si-stitcher.si--color::before { color: #000000; }
.si-stmicroelectronics::before { content: "\f0dd"; }
.si-stmicroelectronics.si--color::before { color: #03234B; }
.si-stopstalk::before { content: "\f0de"; }
.si-stopstalk.si--color::before { color: #536DFE; }
.si-storyblok::before { content: "\f0df"; }
.si-storyblok.si--color::before { color: #09B3AF; }
.si-storybook::before { content: "\f0e0"; }
.si-storybook.si--color::before { color: #FF4785; }
.si-strapi::before { content: "\f0e1"; }
.si-strapi.si--color::before { color: #2F2E8B; }
.si-strava::before { content: "\f0e2"; }
.si-strava.si--color::before { color: #FC4C02; }
.si-streamlit::before { content: "\f0e3"; }
.si-streamlit.si--color::before { color: #FF4B4B; }
.si-stripe::before { content: "\f0e4"; }
.si-stripe.si--color::before { color: #008CDD; }
.si-strongswan::before { content: "\f0e5"; }
.si-strongswan.si--color::before { color: #E00033; }
.si-stubhub::before { content: "\f0e6"; }
.si-stubhub.si--color::before { color: #003168; }
.si-styledcomponents::before { content: "\f0e7"; }
.si-styledcomponents.si--color::before { color: #DB7093; }
.si-stylelint::before { content: "\f0e8"; }
.si-stylelint.si--color::before { color: #263238; }
.si-styleshare::before { content: "\f0e9"; }
.si-styleshare.si--color::before { color: #212121; }
.si-stylus::before { content: "\f0ea"; }
.si-stylus.si--color::before { color: #333333; }
.si-subaru::before { content: "\f0eb"; }
.si-subaru.si--color::before { color: #013C74; }
.si-sublimetext::before { content: "\f0ec"; }
.si-sublimetext.si--color::before { color: #FF9800; }
.si-substack::before { content: "\f0ed"; }
.si-substack.si--color::before { color: #FF6719; }
.si-subversion::before { content: "\f0ee"; }
.si-subversion.si--color::before { color: #809CC9; }
.si-suckless::before { content: "\f0ef"; }
.si-suckless.si--color::before { color: #1177AA; }
.si-sumologic::before { content: "\f0f0"; }
.si-sumologic.si--color::before { color: #000099; }
.si-supabase::before { content: "\f0f1"; }
.si-supabase.si--color::before { color: #3ECF8E; }
.si-superuser::before { content: "\f0f2"; }
.si-superuser.si--color::before { color: #38A1CE; }
.si-surveymonkey::before { content: "\f0f3"; }
.si-surveymonkey.si--color::before { color: #00BF6F; }
.si-suse::before { content: "\f0f4"; }
.si-suse.si--color::before { color: #0C322C; }
.si-suzuki::before { content: "\f0f5"; }
.si-suzuki.si--color::before { color: #E30613; }
.si-svelte::before { content: "\f0f6"; }
.si-svelte.si--color::before { color: #FF3E00; }
.si-svg::before { content: "\f0f7"; }
.si-svg.si--color::before { color: #FFB13B; }
.si-svgo::before { content: "\f0f8"; }
.si-svgo.si--color::before { color: #3E7FC1; }
.si-swagger::before { content: "\f0f9"; }
.si-swagger.si--color::before { color: #85EA2D; }
.si-swarm::before { content: "\f0fa"; }
.si-swarm.si--color::before { color: #FFA633; }
.si-swift::before { content: "\f0fb"; }
.si-swift.si--color::before { color: #F05138; }
.si-swiggy::before { content: "\f0fc"; }
.si-swiggy.si--color::before { color: #FC8019; }
.si-swiper::before { content: "\f0fd"; }
.si-swiper.si--color::before { color: #6332F6; }
.si-symantec::before { content: "\f0fe"; }
.si-symantec.si--color::before { color: #FDB511; }
.si-symfony::before { content: "\f0ff"; }
.si-symfony.si--color::before { color: #000000; }
.si-symphony::before { content: "\f100"; }
.si-symphony.si--color::before { color: #0098FF; }
.si-sympy::before { content: "\f101"; }
.si-sympy.si--color::before { color: #3B5526; }
.si-synology::before { content: "\f102"; }
.si-synology.si--color::before { color: #B5B5B6; }
.si-tmobile::before { content: "\f103"; }
.si-tmobile.si--color::before { color: #E20074; }
.si-tableau::before { content: "\f104"; }
.si-tableau.si--color::before { color: #E97627; }
.si-tado::before { content: "\f105"; }
.si-tado.si--color::before { color: #FFA900; }
.si-tails::before { content: "\f106"; }
.si-tails.si--color::before { color: #56347C; }
.si-tailwindcss::before { content: "\f107"; }
.si-tailwindcss.si--color::before { color: #06B6D4; }
.si-talend::before { content: "\f108"; }
.si-talend.si--color::before { color: #FF6D70; }
.si-tampermonkey::before { content: "\f109"; }
.si-tampermonkey.si--color::before { color: #00485B; }
.si-taobao::before { content: "\f10a"; }
.si-taobao.si--color::before { color: #E94F20; }
.si-tapas::before { content: "\f10b"; }
.si-tapas.si--color::before { color: #FFCE00; }
.si-target::before { content: "\f10c"; }
.si-target.si--color::before { color: #CC0000; }
.si-tasmota::before { content: "\f10d"; }
.si-tasmota.si--color::before { color: #1FA3EC; }
.si-tata::before { content: "\f10e"; }
.si-tata.si--color::before { color: #486AAE; }
.si-taxbuzz::before { content: "\f10f"; }
.si-taxbuzz.si--color::before { color: #ED8B0B; }
.si-teamcity::before { content: "\f110"; }
.si-teamcity.si--color::before { color: #000000; }
.si-teamspeak::before { content: "\f111"; }
.si-teamspeak.si--color::before { color: #2580C3; }
.si-teamviewer::before { content: "\f112"; }
.si-teamviewer.si--color::before { color: #004680; }
.si-ted::before { content: "\f113"; }
.si-ted.si--color::before { color: #E62B1E; }
.si-teespring::before { content: "\f114"; }
.si-teespring.si--color::before { color: #39ACE6; }
.si-tekton::before { content: "\f115"; }
.si-tekton.si--color::before { color: #FD495C; }
.si-tele5::before { content: "\f116"; }
.si-tele5.si--color::before { color: #C2AD6F; }
.si-telegram::before { content: "\f117"; }
.si-telegram.si--color::before { color: #26A5E4; }
.si-telegraph::before { content: "\f118"; }
.si-telegraph.si--color::before { color: #FAFAFA; }
.si-tencentqq::before { content: "\f119"; }
.si-tencentqq.si--color::before { color: #EB1923; }
.si-tensorflow::before { content: "\f11a"; }
.si-tensorflow.si--color::before { color: #FF6F00; }
.si-teradata::before { content: "\f11b"; }
.si-teradata.si--color::before { color: #F37440; }
.si-teratail::before { content: "\f11c"; }
.si-teratail.si--color::before { color: #F4C51C; }
.si-terraform::before { content: "\f11d"; }
.si-terraform.si--color::before { color: #7B42BC; }
.si-tesco::before { content: "\f11e"; }
.si-tesco.si--color::before { color: #00539F; }
.si-tesla::before { content: "\f11f"; }
.si-tesla.si--color::before { color: #CC0000; }
.si-testcafe::before { content: "\f120"; }
.si-testcafe.si--color::before { color: #36B6E5; }
.si-testin::before { content: "\f121"; }
.si-testin.si--color::before { color: #007DD7; }
.si-testinglibrary::before { content: "\f122"; }
.si-testinglibrary.si--color::before { color: #E33332; }
.si-tether::before { content: "\f123"; }
.si-tether.si--color::before { color: #50AF95; }
.si-textpattern::before { content: "\f124"; }
.si-textpattern.si--color::before { color: #FFDA44; }
.si-theconversation::before { content: "\f125"; }
.si-theconversation.si--color::before { color: #D8352A; }
.si-theirishtimes::before { content: "\f126"; }
.si-theirishtimes.si--color::before { color: #000000; }
.si-themighty::before { content: "\f127"; }
.si-themighty.si--color::before { color: #D0072A; }
.si-themodelsresource::before { content: "\f128"; }
.si-themodelsresource.si--color::before { color: #3A75BD; }
.si-themoviedatabase::before { content: "\f129"; }
.si-themoviedatabase.si--color::before { color: #01B4E4; }
.si-thenorthface::before { content: "\f12a"; }
.si-thenorthface.si--color::before { color: #000000; }
.si-theregister::before { content: "\f12b"; }
.si-theregister.si--color::before { color: #FF0000; }
.si-thesoundsresource::before { content: "\f12c"; }
.si-thesoundsresource.si--color::before { color: #39BE6B; }
.si-thespritersresource::before { content: "\f12d"; }
.si-thespritersresource.si--color::before { color: #BE3939; }
.si-thewashingtonpost::before { content: "\f12e"; }
.si-thewashingtonpost.si--color::before { color: #231F20; }
.si-thingiverse::before { content: "\f12f"; }
.si-thingiverse.si--color::before { color: #248BFB; }
.si-thinkpad::before { content: "\f130"; }
.si-thinkpad.si--color::before { color: #EE2624; }
.si-threedotjs::before { content: "\f131"; }
.si-threedotjs.si--color::before { color: #000000; }
.si-threema::before { content: "\f132"; }
.si-threema.si--color::before { color: #3FE669; }
.si-thumbtack::before { content: "\f133"; }
.si-thumbtack.si--color::before { color: #009FD9; }
.si-thunderbird::before { content: "\f134"; }
.si-thunderbird.si--color::before { color: #0A84FF; }
.si-thymeleaf::before { content: "\f135"; }
.si-thymeleaf.si--color::before { color: #005F0F; }
.si-ticketmaster::before { content: "\f136"; }
.si-ticketmaster.si--color::before { color: #026CDF; }
.si-tidal::before { content: "\f137"; }
.si-tidal.si--color::before { color: #000000; }
.si-tide::before { content: "\f138"; }
.si-tide.si--color::before { color: #4050FB; }
.si-tietoevry::before { content: "\f139"; }
.si-tietoevry.si--color::before { color: #063752; }
.si-tiktok::before { content: "\f13a"; }
.si-tiktok.si--color::before { color: #000000; }
.si-tile::before { content: "\f13b"; }
.si-tile.si--color::before { color: #000000; }
.si-timescale::before { content: "\f13c"; }
.si-timescale.si--color::before { color: #FDB515; }
.si-tinder::before { content: "\f13d"; }
.si-tinder.si--color::before { color: #FF6B6B; }
.si-tinyletter::before { content: "\f13e"; }
.si-tinyletter.si--color::before { color: #ED1C24; }
.si-tmux::before { content: "\f13f"; }
.si-tmux.si--color::before { color: #1BB91F; }
.si-todoist::before { content: "\f140"; }
.si-todoist.si--color::before { color: #E44332; }
.si-toggl::before { content: "\f141"; }
.si-toggl.si--color::before { color: #E01B22; }
.si-tokyometro::before { content: "\f142"; }
.si-tokyometro.si--color::before { color: #149DD3; }
.si-tomorrowland::before { content: "\f143"; }
.si-tomorrowland.si--color::before { color: #000000; }
.si-topcoder::before { content: "\f144"; }
.si-topcoder.si--color::before { color: #29A7DF; }
.si-toptal::before { content: "\f145"; }
.si-toptal.si--color::before { color: #3863A0; }
.si-torbrowser::before { content: "\f146"; }
.si-torbrowser.si--color::before { color: #7D4698; }
.si-torproject::before { content: "\f147"; }
.si-torproject.si--color::before { color: #7E4798; }
.si-toshiba::before { content: "\f148"; }
.si-toshiba.si--color::before { color: #FF0000; }
.si-toyota::before { content: "\f149"; }
.si-toyota.si--color::before { color: #EB0A1E; }
.si-tplink::before { content: "\f14a"; }
.si-tplink.si--color::before { color: #4ACBD6; }
.si-tqdm::before { content: "\f14b"; }
.si-tqdm.si--color::before { color: #FFC107; }
.si-trainerroad::before { content: "\f14c"; }
.si-trainerroad.si--color::before { color: #DA291C; }
.si-trakt::before { content: "\f14d"; }
.si-trakt.si--color::before { color: #ED1C24; }
.si-transferwise::before { content: "\f14e"; }
.si-transferwise.si--color::before { color: #00B9FF; }
.si-transportforireland::before { content: "\f14f"; }
.si-transportforireland.si--color::before { color: #00B274; }
.si-transportforlondon::before { content: "\f150"; }
.si-transportforlondon.si--color::before { color: #113B92; }
.si-travisci::before { content: "\f151"; }
.si-travisci.si--color::before { color: #3EAAAF; }
.si-treehouse::before { content: "\f152"; }
.si-treehouse.si--color::before { color: #5FCF80; }
.si-trello::before { content: "\f153"; }
.si-trello.si--color::before { color: #0052CC; }
.si-trendmicro::before { content: "\f154"; }
.si-trendmicro.si--color::before { color: #D71921; }
.si-treyarch::before { content: "\f155"; }
.si-treyarch.si--color::before { color: #000000; }
.si-triller::before { content: "\f156"; }
.si-triller.si--color::before { color: #FF0089; }
.si-trino::before { content: "\f157"; }
.si-trino.si--color::before { color: #DD00A1; }
.si-tripdotcom::before { content: "\f158"; }
.si-tripdotcom.si--color::before { color: #287DFA; }
.si-tripadvisor::before { content: "\f159"; }
.si-tripadvisor.si--color::before { color: #34E0A1; }
.si-trove::before { content: "\f15a"; }
.si-trove.si--color::before { color: #2D004B; }
.si-truenas::before { content: "\f15b"; }
.si-truenas.si--color::before { color: #0095D5; }
.si-trulia::before { content: "\f15c"; }
.si-trulia.si--color::before { color: #0A0B09; }
.si-trustedshops::before { content: "\f15d"; }
.si-trustedshops.si--color::before { color: #FFDC0F; }
.si-trustpilot::before { content: "\f15e"; }
.si-trustpilot.si--color::before { color: #00B67A; }
.si-tryitonline::before { content: "\f15f"; }
.si-tryitonline.si--color::before { color: #303030; }
.si-tryhackme::before { content: "\f160"; }
.si-tryhackme.si--color::before { color: #212C42; }
.si-tsnode::before { content: "\f161"; }
.si-tsnode.si--color::before { color: #3178C6; }
.si-tubi::before { content: "\f162"; }
.si-tubi.si--color::before { color: #000000; }
.si-tui::before { content: "\f163"; }
.si-tui.si--color::before { color: #D40E14; }
.si-tumblr::before { content: "\f164"; }
.si-tumblr.si--color::before { color: #36465D; }
.si-tunein::before { content: "\f165"; }
.si-tunein.si--color::before { color: #14D8CC; }
.si-turbosquid::before { content: "\f166"; }
.si-turbosquid.si--color::before { color: #FF8135; }
.si-turkishairlines::before { content: "\f167"; }
.si-turkishairlines.si--color::before { color: #C70A0C; }
.si-tutanota::before { content: "\f168"; }
.si-tutanota.si--color::before { color: #840010; }
.si-tvtime::before { content: "\f169"; }
.si-tvtime.si--color::before { color: #FFD400; }
.si-twilio::before { content: "\f16a"; }
.si-twilio.si--color::before { color: #F22F46; }
.si-twitch::before { content: "\f16b"; }
.si-twitch.si--color::before { color: #9146FF; }
.si-twitter::before { content: "\f16c"; }
.si-twitter.si--color::before { color: #1DA1F2; }
.si-twoo::before { content: "\f16d"; }
.si-twoo.si--color::before { color: #FF7102; }
.si-typeform::before { content: "\f16e"; }
.si-typeform.si--color::before { color: #262627; }
.si-typescript::before { content: "\f16f"; }
.si-typescript.si--color::before { color: #3178C6; }
.si-typo3::before { content: "\f170"; }
.si-typo3.si--color::before { color: #FF8700; }
.si-uber::before { content: "\f171"; }
.si-uber.si--color::before { color: #000000; }
.si-ubereats::before { content: "\f172"; }
.si-ubereats.si--color::before { color: #06C167; }
.si-ubiquiti::before { content: "\f173"; }
.si-ubiquiti.si--color::before { color: #0559C9; }
.si-ubisoft::before { content: "\f174"; }
.si-ubisoft.si--color::before { color: #000000; }
.si-ublockorigin::before { content: "\f175"; }
.si-ublockorigin.si--color::before { color: #800000; }
.si-ubuntu::before { content: "\f176"; }
.si-ubuntu.si--color::before { color: #E95420; }
.si-udacity::before { content: "\f177"; }
.si-udacity.si--color::before { color: #02B3E4; }
.si-udemy::before { content: "\f178"; }
.si-udemy.si--color::before { color: #A435F0; }
.si-ufc::before { content: "\f179"; }
.si-ufc.si--color::before { color: #D20A0A; }
.si-uikit::before { content: "\f17a"; }
.si-uikit.si--color::before { color: #2396F3; }
.si-ulule::before { content: "\f17b"; }
.si-ulule.si--color::before { color: #18A5D6; }
.si-umbraco::before { content: "\f17c"; }
.si-umbraco.si--color::before { color: #3544B1; }
.si-unacademy::before { content: "\f17d"; }
.si-unacademy.si--color::before { color: #08BD80; }
.si-underarmour::before { content: "\f17e"; }
.si-underarmour.si--color::before { color: #1D1D1D; }
.si-underscoredotjs::before { content: "\f17f"; }
.si-underscoredotjs.si--color::before { color: #0371B5; }
.si-undertale::before { content: "\f180"; }
.si-undertale.si--color::before { color: #E71D29; }
.si-unicode::before { content: "\f181"; }
.si-unicode.si--color::before { color: #5455FE; }
.si-unilever::before { content: "\f182"; }
.si-unilever.si--color::before { color: #1F36C7; }
.si-unitedairlines::before { content: "\f183"; }
.si-unitedairlines.si--color::before { color: #002244; }
.si-unity::before { content: "\f184"; }
.si-unity.si--color::before { color: #000000; }
.si-unraid::before { content: "\f185"; }
.si-unraid.si--color::before { color: #F15A2C; }
.si-unrealengine::before { content: "\f186"; }
.si-unrealengine.si--color::before { color: #0E1128; }
.si-unsplash::before { content: "\f187"; }
.si-unsplash.si--color::before { color: #000000; }
.si-untangle::before { content: "\f188"; }
.si-untangle.si--color::before { color: #68BD49; }
.si-untappd::before { content: "\f189"; }
.si-untappd.si--color::before { color: #FFC000; }
.si-upcloud::before { content: "\f18a"; }
.si-upcloud.si--color::before { color: #7B00FF; }
.si-uplabs::before { content: "\f18b"; }
.si-uplabs.si--color::before { color: #3930D8; }
.si-uploaded::before { content: "\f18c"; }
.si-uploaded.si--color::before { color: #0E70CB; }
.si-ups::before { content: "\f18d"; }
.si-ups.si--color::before { color: #150400; }
.si-uptobox::before { content: "\f18e"; }
.si-uptobox.si--color::before { color: #5CE1E6; }
.si-upwork::before { content: "\f18f"; }
.si-upwork.si--color::before { color: #6FDA44; }
.si-usps::before { content: "\f190"; }
.si-usps.si--color::before { color: #333366; }
.si-v::before { content: "\f191"; }
.si-v.si--color::before { color: #5D87BF; }
.si-v8::before { content: "\f192"; }
.si-v8.si--color::before { color: #4B8BF5; }
.si-vaadin::before { content: "\f193"; }
.si-vaadin.si--color::before { color: #00B4F0; }
.si-vagrant::before { content: "\f194"; }
.si-vagrant.si--color::before { color: #1868F2; }
.si-valve::before { content: "\f195"; }
.si-valve.si--color::before { color: #F74843; }
.si-vapor::before { content: "\f196"; }
.si-vapor.si--color::before { color: #0D0D0D; }
.si-vault::before { content: "\f197"; }
.si-vault.si--color::before { color: #000000; }
.si-vauxhall::before { content: "\f198"; }
.si-vauxhall.si--color::before { color: #EB001E; }
.si-vbulletin::before { content: "\f199"; }
.si-vbulletin.si--color::before { color: #184D66; }
.si-vectorlogozone::before { content: "\f19a"; }
.si-vectorlogozone.si--color::before { color: #184D66; }
.si-vectorworks::before { content: "\f19b"; }
.si-vectorworks.si--color::before { color: #000000; }
.si-veeam::before { content: "\f19c"; }
.si-veeam.si--color::before { color: #00B336; }
.si-veepee::before { content: "\f19d"; }
.si-veepee.si--color::before { color: #EC008C; }
.si-venmo::before { content: "\f19e"; }
.si-venmo.si--color::before { color: #3D95CE; }
.si-vercel::before { content: "\f19f"; }
.si-vercel.si--color::before { color: #000000; }
.si-verdaccio::before { content: "\f1a0"; }
.si-verdaccio.si--color::before { color: #4B5E40; }
.si-veritas::before { content: "\f1a1"; }
.si-veritas.si--color::before { color: #B1181E; }
.si-verizon::before { content: "\f1a2"; }
.si-verizon.si--color::before { color: #CD040B; }
.si-vfairs::before { content: "\f1a3"; }
.si-vfairs.si--color::before { color: #EF4678; }
.si-viadeo::before { content: "\f1a4"; }
.si-viadeo.si--color::before { color: #F07355; }
.si-viber::before { content: "\f1a5"; }
.si-viber.si--color::before { color: #7360F2; }
.si-vim::before { content: "\f1a6"; }
.si-vim.si--color::before { color: #019733; }
.si-vimeo::before { content: "\f1a7"; }
.si-vimeo.si--color::before { color: #1AB7EA; }
.si-vimeolivestream::before { content: "\f1a8"; }
.si-vimeolivestream.si--color::before { color: #0A0A20; }
.si-virtualbox::before { content: "\f1a9"; }
.si-virtualbox.si--color::before { color: #183A61; }
.si-virustotal::before { content: "\f1aa"; }
.si-virustotal.si--color::before { color: #394EFF; }
.si-visa::before { content: "\f1ab"; }
.si-visa.si--color::before { color: #1A1F71; }
.si-visualstudio::before { content: "\f1ac"; }
.si-visualstudio.si--color::before { color: #5C2D91; }
.si-visualstudiocode::before { content: "\f1ad"; }
.si-visualstudiocode.si--color::before { color: #007ACC; }
.si-vite::before { content: "\f1ae"; }
.si-vite.si--color::before { color: #646CFF; }
.si-vivaldi::before { content: "\f1af"; }
.si-vivaldi.si--color::before { color: #EF3939; }
.si-vivino::before { content: "\f1b0"; }
.si-vivino.si--color::before { color: #AA1329; }
.si-vk::before { content: "\f1b1"; }
.si-vk.si--color::before { color: #0077FF; }
.si-vlcmediaplayer::before { content: "\f1b2"; }
.si-vlcmediaplayer.si--color::before { color: #FF8800; }
.si-vmware::before { content: "\f1b3"; }
.si-vmware.si--color::before { color: #607078; }
.si-vodafone::before { content: "\f1b4"; }
.si-vodafone.si--color::before { color: #E60000; }
.si-volkswagen::before { content: "\f1b5"; }
.si-volkswagen.si--color::before { color: #151F5D; }
.si-volvo::before { content: "\f1b6"; }
.si-volvo.si--color::before { color: #003057; }
.si-vonage::before { content: "\f1b7"; }
.si-vonage.si--color::before { color: #FFFFFF; }
.si-vox::before { content: "\f1b8"; }
.si-vox.si--color::before { color: #DA074A; }
.si-vsco::before { content: "\f1b9"; }
.si-vsco.si--color::before { color: #000000; }
.si-vuedotjs::before { content: "\f1ba"; }
.si-vuedotjs.si--color::before { color: #4FC08D; }
.si-vuetify::before { content: "\f1bb"; }
.si-vuetify.si--color::before { color: #1867C0; }
.si-vulkan::before { content: "\f1bc"; }
.si-vulkan.si--color::before { color: #AC162C; }
.si-vultr::before { content: "\f1bd"; }
.si-vultr.si--color::before { color: #007BFC; }
.si-w3c::before { content: "\f1be"; }
.si-w3c.si--color::before { color: #005A9C; }
.si-wagtail::before { content: "\f1bf"; }
.si-wagtail.si--color::before { color: #43B1B0; }
.si-wakatime::before { content: "\f1c0"; }
.si-wakatime.si--color::before { color: #000000; }
.si-walkman::before { content: "\f1c1"; }
.si-walkman.si--color::before { color: #000000; }
.si-wallabag::before { content: "\f1c2"; }
.si-wallabag.si--color::before { color: #3F6184; }
.si-walmart::before { content: "\f1c3"; }
.si-walmart.si--color::before { color: #0071CE; }
.si-wappalyzer::before { content: "\f1c4"; }
.si-wappalyzer.si--color::before { color: #32067C; }
.si-warnerbros::before { content: "\f1c5"; }
.si-warnerbros.si--color::before { color: #004DB4; }
.si-wattpad::before { content: "\f1c6"; }
.si-wattpad.si--color::before { color: #FF500A; }
.si-waze::before { content: "\f1c7"; }
.si-waze.si--color::before { color: #33CCFF; }
.si-wearos::before { content: "\f1c8"; }
.si-wearos.si--color::before { color: #4285F4; }
.si-weasyl::before { content: "\f1c9"; }
.si-weasyl.si--color::before { color: #990000; }
.si-web3dotjs::before { content: "\f1ca"; }
.si-web3dotjs.si--color::before { color: #F16822; }
.si-webassembly::before { content: "\f1cb"; }
.si-webassembly.si--color::before { color: #654FF0; }
.si-webauthn::before { content: "\f1cc"; }
.si-webauthn.si--color::before { color: #3423A6; }
.si-webcomponentsdotorg::before { content: "\f1cd"; }
.si-webcomponentsdotorg.si--color::before { color: #29ABE2; }
.si-webdriverio::before { content: "\f1ce"; }
.si-webdriverio.si--color::before { color: #EA5906; }
.si-webflow::before { content: "\f1cf"; }
.si-webflow.si--color::before { color: #4353FF; }
.si-webgl::before { content: "\f1d0"; }
.si-webgl.si--color::before { color: #990000; }
.si-webhint::before { content: "\f1d1"; }
.si-webhint.si--color::before { color: #4700A3; }
.si-weblate::before { content: "\f1d2"; }
.si-weblate.si--color::before { color: #2ECCAA; }
.si-webmin::before { content: "\f1d3"; }
.si-webmin.si--color::before { color: #7DA0D0; }
.si-webmoney::before { content: "\f1d4"; }
.si-webmoney.si--color::before { color: #036CB5; }
.si-webpack::before { content: "\f1d5"; }
.si-webpack.si--color::before { color: #8DD6F9; }
.si-webrtc::before { content: "\f1d6"; }
.si-webrtc.si--color::before { color: #333333; }
.si-webstorm::before { content: "\f1d7"; }
.si-webstorm.si--color::before { color: #000000; }
.si-webtoon::before { content: "\f1d8"; }
.si-webtoon.si--color::before { color: #00D564; }
.si-wechat::before { content: "\f1d9"; }
.si-wechat.si--color::before { color: #07C160; }
.si-weightsandbiases::before { content: "\f1da"; }
.si-weightsandbiases.si--color::before { color: #FFBE00; }
.si-welcometothejungle::before { content: "\f1db"; }
.si-welcometothejungle.si--color::before { color: #FFCD00; }
.si-wemo::before { content: "\f1dc"; }
.si-wemo.si--color::before { color: #72D44C; }
.si-wetransfer::before { content: "\f1dd"; }
.si-wetransfer.si--color::before { color: #409FFF; }
.si-whatsapp::before { content: "\f1de"; }
.si-whatsapp.si--color::before { color: #25D366; }
.si-wheniwork::before { content: "\f1df"; }
.si-wheniwork.si--color::before { color: #51A33D; }
.si-whitesource::before { content: "\f1e0"; }
.si-whitesource.si--color::before { color: #161D4E; }
.si-wii::before { content: "\f1e1"; }
.si-wii.si--color::before { color: #8B8B8B; }
.si-wiiu::before { content: "\f1e2"; }
.si-wiiu.si--color::before { color: #8B8B8B; }
.si-wikidotjs::before { content: "\f1e3"; }
.si-wikidotjs.si--color::before { color: #1976D2; }
.si-wikidata::before { content: "\f1e4"; }
.si-wikidata.si--color::before { color: #006699; }
.si-wikimediacommons::before { content: "\f1e5"; }
.si-wikimediacommons.si--color::before { color: #006699; }
.si-wikipedia::before { content: "\f1e6"; }
.si-wikipedia.si--color::before { color: #000000; }
.si-wikiquote::before { content: "\f1e7"; }
.si-wikiquote.si--color::before { color: #006699; }
.si-wikivoyage::before { content: "\f1e8"; }
.si-wikivoyage.si--color::before { color: #006699; }
.si-windicss::before { content: "\f1e9"; }
.si-windicss.si--color::before { color: #48B0F1; }
.si-windows::before { content: "\f1ea"; }
.si-windows.si--color::before { color: #0078D6; }
.si-windows95::before { content: "\f1eb"; }
.si-windows95.si--color::before { color: #008080; }
.si-windowsterminal::before { content: "\f1ec"; }
.si-windowsterminal.si--color::before { color: #4D4D4D; }
.si-windowsxp::before { content: "\f1ed"; }
.si-windowsxp.si--color::before { color: #003399; }
.si-winmate::before { content: "\f1ee"; }
.si-winmate.si--color::before { color: #C11920; }
.si-wipro::before { content: "\f1ef"; }
.si-wipro.si--color::before { color: #341C53; }
.si-wire::before { content: "\f1f0"; }
.si-wire.si--color::before { color: #000000; }
.si-wireguard::before { content: "\f1f1"; }
.si-wireguard.si--color::before { color: #88171A; }
.si-wireshark::before { content: "\f1f2"; }
.si-wireshark.si--color::before { color: #1679A7; }
.si-wish::before { content: "\f1f3"; }
.si-wish.si--color::before { color: #2FB7EC; }
.si-wistia::before { content: "\f1f4"; }
.si-wistia.si--color::before { color: #54BBFF; }
.si-wix::before { content: "\f1f5"; }
.si-wix.si--color::before { color: #0C6EFC; }
.si-wizzair::before { content: "\f1f6"; }
.si-wizzair.si--color::before { color: #C6007E; }
.si-wolfram::before { content: "\f1f7"; }
.si-wolfram.si--color::before { color: #DD1100; }
.si-wolframlanguage::before { content: "\f1f8"; }
.si-wolframlanguage.si--color::before { color: #DD1100; }
.si-wolframmathematica::before { content: "\f1f9"; }
.si-wolframmathematica.si--color::before { color: #DD1100; }
.si-woo::before { content: "\f1fa"; }
.si-woo.si--color::before { color: #96588A; }
.si-woocommerce::before { content: "\f1fb"; }
.si-woocommerce.si--color::before { color: #96588A; }
.si-wordpress::before { content: "\f1fc"; }
.si-wordpress.si--color::before { color: #21759B; }
.si-workplace::before { content: "\f1fd"; }
.si-workplace.si--color::before { color: #4326C4; }
.si-worldhealthorganization::before { content: "\f1fe"; }
.si-worldhealthorganization.si--color::before { color: #0093D5; }
.si-wpengine::before { content: "\f1ff"; }
.si-wpengine.si--color::before { color: #0ECAD4; }
.si-wprocket::before { content: "\f200"; }
.si-wprocket.si--color::before { color: #F56640; }
.si-writedotas::before { content: "\f201"; }
.si-writedotas.si--color::before { color: #5BC4EE; }
.si-wwe::before { content: "\f202"; }
.si-wwe.si--color::before { color: #000000; }
.si-wwise::before { content: "\f203"; }
.si-wwise.si--color::before { color: #00549F; }
.si-xdotorg::before { content: "\f204"; }
.si-xdotorg.si--color::before { color: #F28834; }
.si-xamarin::before { content: "\f205"; }
.si-xamarin.si--color::before { color: #3498DB; }
.si-xaml::before { content: "\f206"; }
.si-xaml.si--color::before { color: #0C54C2; }
.si-xampp::before { content: "\f207"; }
.si-xampp.si--color::before { color: #FB7A24; }
.si-xbox::before { content: "\f208"; }
.si-xbox.si--color::before { color: #107C10; }
.si-xcode::before { content: "\f209"; }
.si-xcode.si--color::before { color: #147EFB; }
.si-xdadevelopers::before { content: "\f20a"; }
.si-xdadevelopers.si--color::before { color: #EA7100; }
.si-xero::before { content: "\f20b"; }
.si-xero.si--color::before { color: #13B5EA; }
.si-xfce::before { content: "\f20c"; }
.si-xfce.si--color::before { color: #2284F2; }
.si-xiaomi::before { content: "\f20d"; }
.si-xiaomi.si--color::before { color: #FF6900; }
.si-xilinx::before { content: "\f20e"; }
.si-xilinx.si--color::before { color: #E01F27; }
.si-xing::before { content: "\f20f"; }
.si-xing.si--color::before { color: #006567; }
.si-xmpp::before { content: "\f210"; }
.si-xmpp.si--color::before { color: #002B5C; }
.si-xrp::before { content: "\f211"; }
.si-xrp.si--color::before { color: #25A768; }
.si-xsplit::before { content: "\f212"; }
.si-xsplit.si--color::before { color: #0095DE; }
.si-xstate::before { content: "\f213"; }
.si-xstate.si--color::before { color: #2C3E50; }
.si-ycombinator::before { content: "\f214"; }
.si-ycombinator.si--color::before { color: #F0652F; }
.si-yahoo::before { content: "\f215"; }
.si-yahoo.si--color::before { color: #6001D2; }
.si-yale::before { content: "\f216"; }
.si-yale.si--color::before { color: #FFD900; }
.si-yamahacorporation::before { content: "\f217"; }
.si-yamahacorporation.si--color::before { color: #4B1E78; }
.si-yamahamotorcorporation::before { content: "\f218"; }
.si-yamahamotorcorporation.si--color::before { color: #E60012; }
.si-yammer::before { content: "\f219"; }
.si-yammer.si--color::before { color: #106EBE; }
.si-yarn::before { content: "\f21a"; }
.si-yarn.si--color::before { color: #2C8EBB; }
.si-yelp::before { content: "\f21b"; }
.si-yelp.si--color::before { color: #D32323; }
.si-yoast::before { content: "\f21c"; }
.si-yoast.si--color::before { color: #A4286A; }
.si-yourtraveldottv::before { content: "\f21d"; }
.si-yourtraveldottv.si--color::before { color: #F79025; }
.si-youtube::before { content: "\f21e"; }
.si-youtube.si--color::before { color: #FF0000; }
.si-youtubegaming::before { content: "\f21f"; }
.si-youtubegaming.si--color::before { color: #FF0000; }
.si-youtubemusic::before { content: "\f220"; }
.si-youtubemusic.si--color::before { color: #FF0000; }
.si-youtubestudio::before { content: "\f221"; }
.si-youtubestudio.si--color::before { color: #FF0000; }
.si-youtubetv::before { content: "\f222"; }
.si-youtubetv.si--color::before { color: #FF0000; }
.si-zwave::before { content: "\f223"; }
.si-zwave.si--color::before { color: #1B365D; }
.si-zalando::before { content: "\f224"; }
.si-zalando.si--color::before { color: #FF6900; }
.si-zalo::before { content: "\f225"; }
.si-zalo.si--color::before { color: #0068FF; }
.si-zapier::before { content: "\f226"; }
.si-zapier.si--color::before { color: #FF4A00; }
.si-zcash::before { content: "\f227"; }
.si-zcash.si--color::before { color: #F4B728; }
.si-zdf::before { content: "\f228"; }
.si-zdf.si--color::before { color: #FA7D19; }
.si-zelle::before { content: "\f229"; }
.si-zelle.si--color::before { color: #6D1ED4; }
.si-zend::before { content: "\f22a"; }
.si-zend.si--color::before { color: #0679EA; }
.si-zendframework::before { content: "\f22b"; }
.si-zendframework.si--color::before { color: #68B604; }
.si-zendesk::before { content: "\f22c"; }
.si-zendesk.si--color::before { color: #03363D; }
.si-zenn::before { content: "\f22d"; }
.si-zenn.si--color::before { color: #3EA8FF; }
.si-zenodo::before { content: "\f22e"; }
.si-zenodo.si--color::before { color: #1682D4; }
.si-zerodha::before { content: "\f22f"; }
.si-zerodha.si--color::before { color: #387ED1; }
.si-zeromq::before { content: "\f230"; }
.si-zeromq.si--color::before { color: #DF0000; }
.si-zerply::before { content: "\f231"; }
.si-zerply.si--color::before { color: #7BBB6E; }
.si-zhihu::before { content: "\f232"; }
.si-zhihu.si--color::before { color: #0084FF; }
.si-zig::before { content: "\f233"; }
.si-zig.si--color::before { color: #F7A41D; }
.si-zigbee::before { content: "\f234"; }
.si-zigbee.si--color::before { color: #EB0443; }
.si-zillow::before { content: "\f235"; }
.si-zillow.si--color::before { color: #006AFF; }
.si-zingat::before { content: "\f236"; }
.si-zingat.si--color::before { color: #009CFB; }
.si-zoho::before { content: "\f237"; }
.si-zoho.si--color::before { color: #C8202B; }
.si-zoiper::before { content: "\f238"; }
.si-zoiper.si--color::before { color: #F47920; }
.si-zomato::before { content: "\f239"; }
.si-zomato.si--color::before { color: #E23744; }
.si-zoom::before { content: "\f23a"; }
.si-zoom.si--color::before { color: #2D8CFF; }
.si-zorin::before { content: "\f23b"; }
.si-zorin.si--color::before { color: #0CC1F3; }
.si-zotero::before { content: "\f23c"; }
.si-zotero.si--color::before { color: #CC2936; }
.si-zulip::before { content: "\f23d"; }
.si-zulip.si--color::before { color: #FFFFFF; }
.si-zyte::before { content: "\f23e"; }
.si-zyte.si--color::before { color: #B02CCE; }